import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FindATimePageHeader } from '@components/FindATime/FindATimePageHeader/FindATimePageHeader';
import MainPageLayoutContainer from '@components/MainPageLayout/presentationalComponents/MainPageLayoutContainer';
import MainPageLayoutHeader from '@components/MainPageLayout/presentationalComponents/MainPageLayoutHeader';
import MainPageLayoutBody from '@components/MainPageLayout/presentationalComponents/MainPageLayoutBody';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { SearchSection } from '@components/FindATime/SearchSection/SearchSection';
import { SelectATimeSection } from '@components/FindATime/SelectATimeSection/SelectATimeSection';
import usePageEffects from '@utilities/hooks/usePageEffects/usePageEffects';
import { FindATimeProvider } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { ProcedureNameSearchFilterProvider } from '@components/FindATime/inputs/FindATimeProcedureName/ProcedureNameSearchFilterProvider/ProcedureNameSearchFilterProvider';
import { FindATimeSearchDefaultValues } from '@components/FindATime/FindATimeSearchDefaultValues';
import { DailySurgeonCalendarSection } from '@components/FindATime/DailySurgeonCalendarSection/DailySurgeonCalendarSection';
import RequestFormPageState from '@components/RequestForm/RequestFormPage.types';
import { SchedulePageProvider } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import { AuthenticatedBanner } from '@components/AuthenticatedBanner/AuthenticatedBanner';

const useStyles = makeStyles((theme) => createStyles({
	body: {
		margin: theme.spacing(4.75, 5, 6, 5),
		gap: theme.spacing(3),
		display: 'grid',
		gridTemplateColumns: 'minmax(50%, 1fr) 1fr 250px',
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr 1fr 250px',
		},
	},
	container: {
		backgroundColor: theme.palette.common.white,
		width: '100vw'
	},
	bannerSpacing: {
		margin: theme.spacing(0, 5),
		width: '100%',
	},
	bodyClass: {
		'& > div:first-child': {
			'maxWidth': 'unset',
			'& [data-field="component-container"]': {
				display: 'flex',
				overflow: 'hidden',
				paddingBottom: 0
			},
		},
	}
}));

export const FindATimePage: React.FC = () => {
	const classes = useStyles();
	const history = useHistory<RequestFormPageState>();
	const previousFindATimeValues = history.location.state?.previousFindATimeValues;
	usePageEffects('Find A Time');

	const defaultValues = { ...FindATimeSearchDefaultValues, ...previousFindATimeValues };

	const formMethods = useForm<FindATimeForm>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		criteriaMode: 'all',
		defaultValues,
	});

	return (
		<MainPageLayoutContainer pageName="FindATimePage" bodyClass={classes.bodyClass}>
			<MainPageLayoutHeader>
				<FindATimePageHeader />
				<Box className={classes.bannerSpacing}>
					<AuthenticatedBanner />
				</Box>
			</MainPageLayoutHeader>
			<MainPageLayoutBody className={classes.body}>
				<FormProvider {...formMethods}>
					<ProcedureNameSearchFilterProvider>
						<FindATimeProvider>
							<SearchSection />
							<SchedulePageProvider>
								<SelectATimeSection />
								<DailySurgeonCalendarSection />
							</SchedulePageProvider>
						</FindATimeProvider>
					</ProcedureNameSearchFilterProvider>
				</FormProvider>
			</MainPageLayoutBody>
		</MainPageLayoutContainer>
	);
};
