import React from 'react';
import { Box } from '@material-ui/core';

export const ShowHistoryCheckbox: React.FC = () => {
	return (
		<Box>
			Show history checkbox
			{/* TODO Implement Show History checkbox here - DSSC-6289 */}
		</Box>
	);
};
