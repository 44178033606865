import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	NavLink,
} from 'react-router-dom';
import { Box, Flex, Text } from '@ascension/web';
import { useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import { HospitalRoles } from '@interfaces/UserProfile/UserRoleEnum';
import { HOSPITAL_OPTIONS } from './SettingsOptionDescriptors/HospitalOptions';
import { OFFICE_OPTIONS } from './SettingsOptionDescriptors/OfficeOptions';
import { RoleOptionDescriptor } from './SettingsOptionDescriptors/RoleOptionDescriptorInterface';
import { HeaderContextProvider } from '../HeaderContext/HeaderContext';
import ShrinkableHeader from '../ShrinkableHeader/ShrinkableHeader';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
	wrapperStyles: {
		display: 'flex',
		flexWrap: 'nowrap',
		flexDirection: 'column',
		alignItems: 'stretch',
		height: 'calc(100vh - 200px)'
	},
	routeStyles: {
		width: '70%',
		height: '100%'
	},
	navItems: {
		position: 'relative'
	}
}));


export default function AccountSettings() {
	const isHospitalRole = useSelector(UserProfileHasARoleSelector(HospitalRoles));
	const { wrapperStyles, routeStyles, navItems } = useStyles();

	let routeOptions: RoleOptionDescriptor[];
	if (isHospitalRole) {
		routeOptions = HOSPITAL_OPTIONS;
	}
	else {
		routeOptions = OFFICE_OPTIONS;
	}

	return (
		<Router>
			<Box className={wrapperStyles}>
				<HeaderContextProvider>
					<ShrinkableHeader>Account Settings</ShrinkableHeader>
					<Flex
						flexDirection="row"
						justifyContent="left"
						alignItems="start"
						pb={0}
						flexGrow="1"
						flexShrink="1"
						minHeight="0"
					>
						<Box className="card" width={0.3} py="12px" borderRadius="4px">
							{routeOptions.map((route, index) => (
								<NavLink
									to={route.path}
									key={index}
									className={classNames('side-nav-bar', navItems)}
									activeClassName="active"
									id={route.id}
								>
									<Text
										display="inline-block"
										px={8}
										py="12px"
										fontSize="12px"
										textTransform="uppercase"
										fontWeight="bold"
									>
										{route.linkText}
									</Text>
								</NavLink>
							))}
						</Box>
						<Box className={routeStyles}>
							{routeOptions.map((route) => (
								<Route
									key={route.path}
									path={route.path}
									exact={true}
									component={route.card}
								></Route>
							))}
						</Box>
					</Flex>
				</HeaderContextProvider>
			</Box>
		</Router>
	);
}
