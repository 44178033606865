import { FieldValues, RegisterOptions } from 'react-hook-form';
import { differenceInMinutes, parse } from 'date-fns';
import { END_BEFORE_START, MIN_TIME_RANGE } from '@utilities/Validation/ValidationMessages';

export const PartialReleaseStartTimeValidation: RegisterOptions = {
	validate: {
		minimumTimeSpan: (startTime: string, formValues: FieldValues) => {
			const formEndTime = formValues?.endTime as string;

			if (!startTime || !formEndTime) {
				return true;
			}

			const startTimeDate = parse(startTime, 'HH:mm', new Date());
			const endTimeDate = parse(formEndTime, 'HH:mm', new Date());
			const timeRangeMins = Math.abs(differenceInMinutes(startTimeDate, endTimeDate));

			if (timeRangeMins < 45) {
				return MIN_TIME_RANGE;
			} else {
				return true;
			}
		},
		startBeforeEnd: (startTime: string, formValues: FieldValues) => {
			const formEndTime = formValues?.endTime as string;

			if (!startTime || !formEndTime) {
				return true;
			}

			const startTimeDate = parse(startTime, 'HH:mm', new Date());
			const endTimeDate = parse(formEndTime, 'HH:mm', new Date());

			if (startTimeDate >= endTimeDate) {
				return END_BEFORE_START;
			} else {
				return true;
			}
		},
	},
};

export const PartialReleaseEndTimeValidation: RegisterOptions = {
	validate: {
		minimumTimeSpan: (endTime: string, formValues: FieldValues) => {
			const formStartTime = formValues?.startTime as string;

			if (!endTime || !formStartTime) {
				return true;
			}

			const startTimeDate = parse(endTime, 'HH:mm', new Date());
			const endTimeDate = parse(formStartTime, 'HH:mm', new Date());
			const timeRangeMins = Math.abs(differenceInMinutes(startTimeDate, endTimeDate));

			if (timeRangeMins < 45) {
				return MIN_TIME_RANGE;
			} else {
				return true;
			}
		},
	},
};
