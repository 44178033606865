import EventConfig from '@calendar/types/EventConfig';
import { BlockPageCaseType } from '@components/SchedulePage/types/BlockPageConfigType';
import EventStyleConfig from '@interfaces/OpenCalendar/EventStyleConfig';

export const BlockReleaseEventConfig: EventConfig<BlockPageCaseType> = {
	SCHEDULED: {
		legendLabel: 'Scheduled',
	},
	UNSCHEDULED: {
		legendLabel: 'Pending',
	},
};

export const BlockReleaseStyleConfig: EventStyleConfig<BlockPageCaseType> = {
	SCHEDULED: {
		legend: {
			background: '#E9FBE7',
			border: '2px solid #126E36',
			color: '#006F5F',
		},
	},
	UNSCHEDULED: {
		legend: {
			background: '#FFF7E6',
			border: '2px dashed #FF7F00',
			color: '#B74900',
		},
	},
};
