import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ThumbUpOutlined } from '@material-ui/icons';
import { LEGACY_MAX_WIDTH } from '@theme/themeConstants';
import { BackLink } from '@components/BackLink/BackLink';

const useStyles = makeStyles((theme) => ({
	root: {
		'display': 'grid',
		'gridTemplateColumns': '1fr 11fr 4fr',
		'borderRadius': '4px',
		'height': '72px',
		'alignItems': 'top',
		'padding': theme.spacing(3),
		'marginBottom': theme.spacing(2.5),
		'maxWidth': LEGACY_MAX_WIDTH,
		'boxShadow': theme.shadows[5],
		'& > *:last-child': {
			margin: 'auto',
		},
	},
	title: {
		fontSize: '1.5rem',
		lineHeight: '1.5rem ',
		fontWeight: 500,
		paddingBottom: theme.spacing(2),
	},
	subtitle: {
		fontSize: '1.125rem',
		lineHeight: '1.5rem ',
		fontWeight: 500,
	},
}));

export const BlockReleaseCaughtUpMessage = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<ThumbUpOutlined />
			<Box>
				<Typography className={classes.title}>You&apos;re all caught up</Typography>
				<Typography className={classes.subtitle}>
					No other requests at this time.
				</Typography>
			</Box>
			<BackLink navBackText="BACK TO CASE LIST" />
		</Box>
	);
};
