import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import {
	BlockReleaseReviewSectionProps
} from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewPage/BlockReleaseReviewSection/BlockReleaseReviewSectionProps';
import classNames from 'classnames';
import { useBlockReleaseContext } from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewContext/BlockReleaseReviewContext';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		marginBottom: theme.spacing(2.25),
	},
	sectionTitle: {
		...theme.typography.h2,
		fontSize: '1.5rem',
		lineHeight: '32px',
	},
	sectionSubtitle: {
		...theme.typography.subtitle1,
		lineHeight: '16px',
		marginTop: theme.spacing(1),
	}
}));

export const BlockReleaseReviewSection:React.FC<BlockReleaseReviewSectionProps> = ({ sectionTitle, sectionSubTitle, children, className, ...boxProps }) => {
	const classes = useStyles();
	const { blockReleaseList } = useBlockReleaseContext();

	React.useEffect(() => {
		const releaseId = window.location.hash ? window.location.hash.substring(1) : null;

		if (releaseId && blockReleaseList.length) {
			const scrollTo = document.getElementById(releaseId);
			if (scrollTo) {
				scrollTo.scrollIntoView({ block: 'center', behavior: 'smooth' });
			}
		}
	}, [blockReleaseList]);

	return (
		<Box className={classNames(classes.root, className)} {...boxProps}>
			<Box className={classes.title}>
				<Typography className={classes.sectionTitle}>{sectionTitle}</Typography>
				{sectionSubTitle && <Typography className={classes.sectionSubtitle}>{sectionSubTitle}</Typography>}
			</Box>
			{children}
		</Box>
	);
};
