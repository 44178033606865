import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SectionedBoxHeader } from '@components/SectionedBox/SectionedBoxHeader/SectionedBoxHeader';
import { SectionedBoxBody } from '@components/SectionedBox/SectionedBoxBody/SectionedBoxBody';
import { SectionedBoxFooter } from '@components/SectionedBox/SectionedBoxFooter/SectionedBoxFooter';
import { SectionedBoxWrapper } from '@components/SectionedBox/SectionedBoxWrapper';
import { Box } from '@material-ui/core';
import { SurgeonDropdown } from '@components/RequestForm/inputs/SurgeonDropdown/SurgeonDropdown';
import { makeStyles } from '@material-ui/core/styles';
import { SurgeryLocation } from '@components/RequestForm/inputs/SurgeryLocation/SurgeryLocation';
import { AnchorProvider } from '@components/AnchorProvider/AnchorProvider';
import { ProcedureNameSearchFilter } from '@components/FindATime/inputs/FindATimeProcedureName/ProcedureNameSearchFilter/ProcedureNameSearchFilter';
import { DateRangeSection } from '@components/FindATime/DateRangeSection/DateRangeSection';
import { DayOfWeekSection } from '@components/FindATime/DayOfWeekSection/DayOfWeekSection';
import { ProcedureDuration } from '@components/FindATime/inputs/FindATimeProcedureDuration/ProcedureDuration';
import { FindATimeTimeRange } from '@components/FindATime/inputs/FindATimeTimeRange/FindATimeTimeRange';
import { SearchActionButtons } from '@components/FindATime/SearchSection/SearchActionButtons/SearchActionButtons';
import RequestFormPageState from '@components/RequestForm/RequestFormPage.types';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';

const useBoxRowStyles = makeStyles((theme) => ({
	row: {
		'display': 'flex',
		'flexDirection': 'row',
		'justifyContent': 'space-evenly',
		'gap': theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
		'& > *': {
			flex: 1,
		},
	},
}));

const BodyRow: React.FC = ({ children }) => {
	const classes = useBoxRowStyles();
	return <Box className={classes.row}>{children}</Box>;
};

const useSearchSectionStyles = makeStyles((theme) => ({
	body: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(3),
	},
	durationInput: {
		'& .MuiInputBase-root': {
			width: '75px',
		},
	},
}));

export const SearchSection: React.FC = () => {
	const classes = useSearchSectionStyles();
	const { setSelectedProcedureName } = useProcedureNameContext();
	const history = useHistory<RequestFormPageState>();
	const previousFindATimeValues = history.location.state?.previousFindATimeValues;
	const { resetField } = useFormContext<RequestFormValidatedState>();

	useEffect(() => {
		if (previousFindATimeValues?.procedureName) {
			setSelectedProcedureName(previousFindATimeValues?.procedureName);
		}
	}, [previousFindATimeValues?.procedureName, setSelectedProcedureName]);

	const handleLocationChange = () => {
		resetField('duration');
		resetField('procedureName');
	};

	return (
		<SectionedBoxWrapper>
			<SectionedBoxHeader title="Search" />
			<SectionedBoxBody className={classes.body}>
				<BodyRow>
					<SurgeonDropdown
						dataField="findATimePage-searchSection-surgeonSelect"
					/>
					<SurgeryLocation
						label="Location"
						onlyUnitsWithOpenTime={true}
						controllerOptions={{ rules: { onChange: handleLocationChange } }}
						dataField="findATimePage-searchSection-surgeryLocationSelect"
					/>
				</BodyRow>
				<BodyRow>
					<DateRangeSection />
				</BodyRow>
				<DayOfWeekSection />
				<BodyRow>
					<FindATimeTimeRange />
				</BodyRow>
				<BodyRow>
					<AnchorProvider>
						<ProcedureNameSearchFilter />
					</AnchorProvider>
				</BodyRow>
				<BodyRow>
					<Box className={classes.durationInput}>
						<ProcedureDuration />
					</Box>
				</BodyRow>
			</SectionedBoxBody>
			<SectionedBoxFooter>
				<SearchActionButtons />
			</SectionedBoxFooter>
		</SectionedBoxWrapper>
	);
};
