import React from 'react';
import { track } from '@amplitude/analytics-browser';
import { IconButton, IconButtonProps } from '@material-ui/core';

export interface BaseIconButtonProps extends IconButtonProps {
	dataField?: string;
	shouldTrackAnalytics?: boolean;
}
export const BaseIconButton: React.FC<BaseIconButtonProps> = ({
	dataField,
	onClick,
	shouldTrackAnalytics = true,
	children,
	...rest
}) => {
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		shouldTrackAnalytics && track('icon-button-click', { fieldName: dataField, eventType: e.type });
		if (onClick) {
			onClick(e);
		}
	};

	return (
		<IconButton data-field={dataField} onClick={handleClick} {...rest}>
			{children}
		</IconButton>
	);
};
