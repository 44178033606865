import React from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import AppointmentTypeRadios from '@components/RequestForm/inputs/AppointmentTypeRadios';
import useDoesSelectedUnitSupportAppointmentTypes from '@components/RequestForm/utilities/useDoesSelectedUnitSupportAppointmentTypes';
import { ATTACHMENT_REQUEST_TYPES } from '@utilities/constants';
import ComparisonOption from '@utilities/arrays/ComparisonOption';
import RequestDetailsProps from '@components/RequestForm/formSections/RequestDetailsProps';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import {
	useEditBlockOverlapValidation
} from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useEditBlockOverlapValidation/useEditBlockOverlapValidation';
import { SurgeryLocation } from '@components/RequestForm/inputs/SurgeryLocation/SurgeryLocation';

const useRequestDetailsStyles = makeStyles((theme) => ({
	sectionLayout: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(4),
	},
	blockNameLabel: {
		fontSize: '12px',
		textTransform: 'uppercase',
		letterSpacing: '1px',
		fontWeight: 600,
		marginBottom: '5px',
	},
	dateSurgeonRow: {
		'display': 'flex',
		'flexDirection': 'row',
		'justifyContent': 'space-evenly',
		'flexWrap': 'wrap',
		'gap': theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
		'& > *': {
			flex: 1,
		},
	},
	procedureDurationRow: {
		'display': 'flex',
		'flexDirection': 'row',
		'gap': theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
		'& > *:first-child': {
			flex: '0 1 65%',
		},
		'& > *:not(:first-child)': {
			flex: '0 1 35%',
		},
	},
	instructionText: {
		color: theme.palette.grey[600],
	},
	requiredText: {
		color: theme.palette.error.main,
	},
	procedureInput: {
		background: theme.palette.common.white,
	},
}));

const RequestDetails: React.FC<RequestDetailsProps> = ({ canChangeAppointmentType }) => {
	const classes = useRequestDetailsStyles();

	const { setValue } = useFormContext<RequestFormValidatedState>();

	const shouldShowAppointmentTypeRadios =
		useDoesSelectedUnitSupportAppointmentTypes(ATTACHMENT_REQUEST_TYPES, ComparisonOption.EXACT) &&
		canChangeAppointmentType;

	const doesBlockOverlap = useEditBlockOverlapValidation();
	React.useEffect(() => {
		setValue('appointmentBlockTime', !!doesBlockOverlap);
	}, [doesBlockOverlap, setValue]);

	return <Box data-field="requestForm-requestDetailsSection" className={classes.sectionLayout} >
		<Typography className={classes.instructionText}>
			Requested date/time must be at least 48 hours from now. For sooner, call Scheduling Support.
		</Typography>
		<Typography className={classes.requiredText}>
			* Indicates required fields
		</Typography>

		<SurgeryLocation
			dataField="requestForm-requestDetails-surgeryLocationSelect"
		/>
		{shouldShowAppointmentTypeRadios && <AppointmentTypeRadios />}
	</Box>;
};

export default RequestDetails;
