import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_BLOCK_RELEASES, ENDPOINT_BLOCK_RELEASES_SLOTS } from '@utilities/apiConstants';
import ApiService from '@utilities/apiEndpoints/ApiService';
import {
	CREATE_PENDING_RELEASE_EVENT,
	GET_BLOCK_RELEASES,
	SUBMIT_BLOCK_RELEASE_CONFIRMATION,
} from '@store/actions/types';
import { CancelPolicy } from '@interfaces/CancelPolicy';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import ApiRestMethod from '@utilities/apiEndpoints/ApiRestMethod';
import { BlockReleaseRequest, BlockReleasesListResponse } from '@data/blockReleases/types/BlockReleasesListResponse';
import {
	ConfirmPendingReleaseData
} from '@components/CaseTrackerList/BlockReleaseReviewBanner/ConfirmPendingReleaseInterface';
import {
	shouldDisableErrorHandlerByCode
} from '@utilities/shouldDisableErrorHandlerByCode/shouldDisableErrorHandlerByCode';
import {
	PendingReleaseEventRequest,
	PendingReleaseEventResponse
} from '@data/blockReleases/types/PendingReleaseEvent.types';

export const getBlockReleasesByUnit = (unitArray: string[], requestOverride: Partial<ApiActionPayload<BlockReleasesListResponse[]>>) => {
	return apiAction({
		url: ENDPOINT_BLOCK_RELEASES,
		method: ApiRestMethod.POST,
		apiService: ApiService.BTS,
		includeAccessToken: true,
		label: GET_BLOCK_RELEASES,
		cancelPolicy: CancelPolicy.NO_DUPLICATE_PATH,
		asDataOrParams: 'data',
		data: {
			query: 'RELEASE_BY_UNIT',
			units: unitArray,
			types: ['PENDING_RELEASE', 'SSM_RELEASE']
		},
		...requestOverride,
	});
};

export const confirmBlockRelease = (data: ConfirmPendingReleaseData, slotId: string, requestOverride: Partial<ApiActionPayload<PendingReleaseEventResponse>> ) => {
	return apiAction({
		url: `${ENDPOINT_BLOCK_RELEASES_SLOTS}/${slotId}`,
		method: ApiRestMethod.POST,
		data: data,
		apiService: ApiService.BTS,
		includeAccessToken: true,
		asDataOrParams: 'data',
		shouldDisableErrorHandler: shouldDisableErrorHandlerByCode(['CS03']),
		label: SUBMIT_BLOCK_RELEASE_CONFIRMATION,
		...requestOverride,
	});
};

export const createPendingReleaseEvent = (
	body: PendingReleaseEventRequest,
	requestOverride: Partial<ApiActionPayload<PendingReleaseEventResponse>>,
) => {
	return apiAction({
		url: ENDPOINT_BLOCK_RELEASES_SLOTS,
		method: ApiRestMethod.POST,
		apiService: ApiService.BTS,
		includeAccessToken: true,
		label: CREATE_PENDING_RELEASE_EVENT,
		data: body,
		...requestOverride,
	});
};

export const getBlockReleasesByNpi = (
	payload: BlockReleaseRequest,
	requestOverride: Partial<ApiActionPayload<BlockReleasesListResponse[]>>,
) => {
	return apiAction({
		url: ENDPOINT_BLOCK_RELEASES,
		method: ApiRestMethod.POST,
		apiService: ApiService.BTS,
		includeAccessToken: true,
		label: GET_BLOCK_RELEASES,
		cancelPolicy: CancelPolicy.NO_DUPLICATE_PATH,
		data: payload,
		...requestOverride,
	});
};
