import { ReplacedNode } from '@components/CaseActivityDrawer/types/CaseActivityRowType';
import { InsuranceAuthStatusResponse } from '@interfaces/InsuranceAuthStatus/InsuranceAuthStatusState.types';

export interface ChangeDetails extends Partial<ReplacedNode> {
	changeTitle?: string;
}

export interface FormatActivityChangeDetailsReturn {
	changedField: string;
	changeDetails: ChangeDetails;
}

export const formatActivityChangeDetails = (change: ReplacedNode, authStatusConfig: InsuranceAuthStatusResponse | undefined): FormatActivityChangeDetailsReturn => {
	const [changedField, procedureName] = change.fieldFriendlyName.split('::');

	const changedFieldDetails: Record<string, ChangeDetails> = {
		Authorization: {
			currValue: authStatusConfig?.[change.currValue]?.label,
			prevValue: authStatusConfig?.[change.prevValue]?.label,
		},
		Equipment: {
			changeTitle: `Procedure ${procedureName} was edited.`,
			currValue: change.currValue || 'No equipment',
			prevValue: change.prevValue || 'No equipment',
		},
	};

	const changeDetails = changedFieldDetails[changedField] || {
		...change,
	};

	return { changedField, changeDetails };
};
