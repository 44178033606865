import { Link, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React, { MouseEventHandler } from 'react';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';
import { LinkMenuProps } from '@components/LinkMenu/LinkMenuProps';

const useStyles = makeStyles((theme) => ({
	link: {
		color: theme.palette.primary.main,
		marginTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	linkMenuOpen: {
		color: theme.palette.primary.dark,
	},
}));

type LinkComponentProps = Pick<LinkMenuProps, 'label' | 'icon' | 'onClick'> & { className?: string };

export const LinkComponent: React.FC<LinkComponentProps> = ({ label, icon, className, onClick }) => {
	const classes = useStyles();
	const { handleSetAnchorEl, anchorEl } = useAnchorContext();

	const handleOnClick: MouseEventHandler<HTMLElement> = React.useCallback((e) => {
		if (onClick) {
			onClick();
		}
		handleSetAnchorEl(e);
	}, [handleSetAnchorEl, onClick]);

	return (
		<Link
			className={classNames(classes.link, className, { [classes.linkMenuOpen]: anchorEl })}
			variant="body2"
			onClick={handleOnClick}
			component="button"
			aria-controls="LinkMenu-menu"
			aria-haspopup="true"
			aria-label={label}
		>
			{icon || (
				<>
					{label}
					{anchorEl ? <ArrowDropUp data-field="arrowUp" /> : <ArrowDropDown data-field="arrowDown" />}
				</>
			)}
		</Link>
	);
};
