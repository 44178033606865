import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { AllergyOptionsEnum } from '@interfaces/SurgeryRequest/AllergyOptionsEnum';
import { AppointmentType } from '@data/request/AppointmentType';

export const RequestFormDefaultValues: Partial<RequestFormValidatedState> = {
	appointmentType: AppointmentType.LEGACY_WEB,
	procedureDate: undefined,
	procedureTime: ['', ''],
	duration: '',
	appointmentLocation: '',
	appointmentRequestAuthStatus: { label: '', value: '' },
	appointmentToFollow: false,
	appointmentBlockTime: false,
	surgeryProcedures: [],
	groupNumberPrimary: '',
	healthPlanNamePrimary: '',
	insuredFirstNamePrimary: '',
	insuredLastNamePrimary: '',
	patTestingNeeded: '',
	patDate: undefined,
	patTime: ['', ''],
	policyNumberPrimary: '',
	preAuthPrimary: undefined,
	primaryDiagnosis: '',
	secondaryDiagnosis: '',
	otherSurgicalComments: '',
	otherPatientDetails: '',
	patBedNeeded: {
		label: '',
		value: '',
	},
	patLocation: { label: '', value: '' },
	preCertNumberPrimary: '',
	patientFirstName: '',
	patientLastName: '',
	patientDOB: '',
	patientEmail: '',
	patientGender: { value:'', label:'' },
	patientInterpreterNeeded: false,
	patientInterpretLanguage: '',
	patientMiddleName: '',
	patientPhoneNumber: '',
	patientSSN: '',
	patientSSN1: '',
	patientSSN2: '',
	patientSSN3: '',
	patientIodineAllergy: AllergyOptionsEnum.UNDEFINED,
	patientLatexAllergy: AllergyOptionsEnum.UNDEFINED,
	documents: undefined,
	findATimeUsed: false,
};
