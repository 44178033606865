import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import ColumnHeaderProps from '@components/SchedulePage/types/ColumnHeaderProps';
import ColumnHeaderCommon from '@components/SchedulePage/ColumnHeaderCommon/ColumnHeaderCommon';
import { BlockReleaseLink } from '@components/SchedulePage/BlockReleaseLink/BlockReleaseLink';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';
import { BlockReleaseState } from '@data/blockReleases/types/BlockReleaseState';
import { useSsmUiFlags } from '@utilities/hooks/useSsmUiFlags/useSsmUiFlags';

const useStyles = makeStyles( theme => createStyles({
	root: {
		padding: theme.spacing(0.5, 1, 0.5, 1),
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.common.white,
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '4px 4px 0 0',
	},
	availableTimeWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	availableTimeValue: {
		...theme.typography.subtitle1,
		fontWeight: 600,
	},
	blockText: {
		fontWeight: 600,
	},
	pendingReleaseText: {
		color: theme.palette.gold.main,
	},
}));

const BlockUnreleasedColumnHeader: React.FC<ColumnHeaderProps> = ({ blockInfo, children }) => {
	const { ssmShowReleaseBlock20241205 } = useSsmUiFlags();
	const classes = useStyles();
	const location = useLocation();
	const isOnExcludedPage = location.pathname === ROUTE_CONSTANTS.BLOCK_RELEASE_REQUEST || location.pathname === ROUTE_CONSTANTS.FIND_A_TIME;
	const shouldShowPendingReleaseText =
		ssmShowReleaseBlock20241205 &&
		blockInfo.blocks?.some(
			(block) => block.release?.state === BlockReleaseState.PENDING_RELEASE,
		) &&
		!isOnExcludedPage;
	const shouldShowBlockReleaseLink =
		ssmShowReleaseBlock20241205 &&
		blockInfo?.blocks?.length === 1 &&
		!isOnExcludedPage &&
		!shouldShowPendingReleaseText;

	return (
		<Box className={classes.root} data-field="block-unreleased-column-header" component="header">
			<Typography variant="body2" component="h3" className={classes.blockText}>Block Time</Typography>
			<ColumnHeaderCommon blockInfo={blockInfo} />
			<Typography variant="body2" component="span" className={classes.availableTimeWrapper}>
				<Typography className={classes.availableTimeValue}>{blockInfo.availableTimeDisplay}&nbsp;</Typography> approx. available
			</Typography>
			{ children }
			{shouldShowBlockReleaseLink && <BlockReleaseLink blockInfo={blockInfo} />}
			{shouldShowPendingReleaseText && (
				<Typography className={classes.pendingReleaseText} variant="body2">
					Pending Release
				</Typography>
			)}
		</Box>
	);
};

export default BlockUnreleasedColumnHeader;
