import React from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

import { getBlockReleasesByNpi } from '@data/blockReleases/actionCreators/BlockReleaseActionCreators';
import {
	BlockReleaseRequest,
	BlockReleasesListResponse,
} from '@data/blockReleases/types/BlockReleasesListResponse';
import { sortBlockReleases } from '@components/BlockRelease/utilities/sortBlockReleases/sortBlockReleases';

export const useGetBlockReleasesByNpi = () => {
	const [releases, setReleases] = React.useState<BlockReleasesListResponse[]>([]);
	const dispatch = useDispatch();

	const getReleases = React.useCallback(
		(body: BlockReleaseRequest) => {
			const onSuccess = (response: AxiosResponse<BlockReleasesListResponse[]>) => {
				const sortedBlockReleases = sortBlockReleases(response.data);
				setReleases(sortedBlockReleases);
			};
			dispatch(getBlockReleasesByNpi(body, { onSuccess, shouldDisableLoadIndicator: true }));
		},
		[dispatch],
	);

	return {
		releases,
		getReleases,
	};
};
