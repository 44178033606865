import React from 'react';
import {
	BlockReleaseReviewCard
} from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewPage/BlockReleaseReviewCard/BlockReleaseReviewCard';
import {
	BlockReleaseReviewSection
} from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewPage/BlockReleaseReviewSection/BlockReleaseReviewSection';
import { useBlockReleaseContext } from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewContext/BlockReleaseReviewContext';
import { BlockReleaseState } from '@data/blockReleases/types/BlockReleaseState';
import {
	BlockReleaseCaughtUpMessage
} from '@components/BlockRelease/BlockReleaseCaughtUpMessage/BlockReleaseCaughtUpMessage';
import useSurgeonsSelectionByNpis
	from '@components/SchedulePage/utilities/useSurgeonsSelectionByNpis/useSurgeonsSelectionByNpis';
import { BLOCK_RELEASE_REQUEST_CONFIRM_MODAL } from '@components/Modal/modalConstants';
import {
	BlockReleaseReviewConfirmModal
} from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewConfirmModal/BlockReleaseReviewConfirmModal';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { BlockReleasesListResponse } from '@data/blockReleases/types/BlockReleasesListResponse';

export const BlockReleaseReviewRequests = () => {
	const { prefSurgeons } = useSurgeonsSelectionByNpis();
	const { openModal } = useModalContext();
	const [blockReleaseDetails, setBlockReleaseDetails] = React.useState<BlockReleasesListResponse>();
	const [surgeonName, setSurgeonName] = React.useState<string>('');
	const { blockReleaseList } = useBlockReleaseContext();

	const pendingReleases = React.useMemo(() =>
		blockReleaseList.filter((item) => item.state === BlockReleaseState.PENDING_RELEASE),
	[blockReleaseList]);

	const handleBlockReleaseClick = React.useCallback((blockReleaseDetails: BlockReleasesListResponse, surgeonName: string) => {
		setBlockReleaseDetails(blockReleaseDetails);
		setSurgeonName(surgeonName);
		openModal(BLOCK_RELEASE_REQUEST_CONFIRM_MODAL.id);
	}, [openModal]);

	if (!pendingReleases.length) {
		return <BlockReleaseCaughtUpMessage />;
	}

	return (
		<BlockReleaseReviewSection
			sectionTitle="Requests by the Office to OR Schedulers to release block early"
			sectionSubTitle="Please release blocks in the EHR as requested by the office."
		>
			{pendingReleases.map((item) => (
				<BlockReleaseReviewCard key={item.id} blockReleaseDetails={item} prefSurgeons={prefSurgeons} onActionClick={handleBlockReleaseClick}/>
			))}
			{blockReleaseDetails && <BlockReleaseReviewConfirmModal
				id={BLOCK_RELEASE_REQUEST_CONFIRM_MODAL.id}
				blockReleaseDetails={blockReleaseDetails}
				surgeonName={surgeonName}
			/>
			}
		</BlockReleaseReviewSection>
	);
};
