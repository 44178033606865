import React from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import { track } from '@amplitude/analytics-browser';

export interface CheckBoxProps extends CheckboxProps {
	dataField?: string;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ dataField, onClick, ...props }) => {
	const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		if (dataField) {
			track('checkbox-click', { fieldName: dataField, eventType: event.type });
		}
		onClick?.(event);
	}, [dataField, onClick]);

	return <Checkbox onClick={handleClick} {...props} />;
};
