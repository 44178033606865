import { SurgeryProcedureInterface } from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { ProcedureApi } from '@interfaces/Procedure/ProcedureApi';
import { transformDropdownOptionsToEquipmentApi } from '@components/ProcedureForm/inputs/EquipmentMultiSelect/EquipmentMultiSelectTypeConverters';

const surgeryProcedureToProcedureApiConverter = (
	procedure: SurgeryProcedureInterface,
): ProcedureApi => {
	const procedureApi: ProcedureApi = {
		cptCodes: procedure.cptCodes.entriesList || [],
		modifierSide: procedure.modifierSide ? procedure.modifierSide.value : '',
		modifierApproach: procedure.modifierApproach ? procedure.modifierApproach.value : '',
		anesthesiaType: procedure.anesthesiaType
			? procedure.anesthesiaType.map((option) => option.value)
			: [],
		implantsNeeded: !!procedure.implantsNeeded,
		surgeonId: procedure.surgeon.value,
		name: procedure.procedureName,
		primary: !!procedure.isPrimaryProcedure,
		comments: procedure.additionalComments,
		otherEquipments: procedure.otherEquipment,
		vendor: procedure.vendorName,
		robotic: !!procedure.robotic,
		vendorContacted: !!('vendorContacted' in procedure && procedure.vendorContacted),
		equipment: procedure.equipment
			? transformDropdownOptionsToEquipmentApi(procedure.equipment)
			: [],
		firstAssistant: procedure.firstAssistant,
	};
	//handle optional values
	if ('templateId' in procedure && procedure.templateId) {
		procedureApi.templateId = procedure.templateId;
	}
	return procedureApi;
};

export default surgeryProcedureToProcedureApiConverter;
