import React from 'react';
import { LinkMenu } from '@components/LinkMenu/LinkMenu';
import { LinkMenuOptionProps } from '@components/LinkMenu/LinkMenuOption/LinkMenuOption';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { EDIT_COMMENT_MODAL } from '@components/Modal/modalConstants';
import { DELETE_COMMENT_MODAL } from '@components/Modal/modalConstants';

const useStyles = makeStyles((theme) => ({
	link: {
		'color': '#000',
		'marginTop': 0,
		'& .MuiSvgIcon-root': {
			width: '0.9em',
			height: '0.9em',
		},
	},
	menu: {
		'& li': {
			fontSize: '1.125rem',
			fontWeight: 500,
		},
		'& li:nth-of-type(1) .MuiSvgIcon-root': {
			fill: '#1E69D2',
		},
		'& li:nth-of-type(2) .MuiSvgIcon-root': {
			fill: theme.palette.error.main,
		},
	}
}));

interface CommentMenuProps {
	onClick?: () => void;
}

export const CommentMenu: React.FC<CommentMenuProps> = ({ onClick }) => {
	const classes = useStyles();
	const { openModal } = useModalContext();

	const handleEditClick = React.useCallback(() => {
		openModal(EDIT_COMMENT_MODAL);
	}, [openModal]);

	const handleDeleteClick = React.useCallback(() => {
		openModal(DELETE_COMMENT_MODAL.id);
	}, [openModal]);

	const options: LinkMenuOptionProps[] = React.useMemo(() => {
		return [
			{
				id: 'caseDetails-commentsSection-commentMenuEditButton',
				label: 'Edit',
				icon: EditIcon,
				onClick: handleEditClick,
				order: 1,
			},
			{
				id: 'caseDetails-commentsSection-commentMenuDeleteButton',
				label: 'Delete',
				icon: DeleteIcon,
				onClick: handleDeleteClick,
				order: 2,
			},
		];
	}, [handleDeleteClick, handleEditClick]);

	return (
		<LinkMenu
			label="Comment Menu"
			icon={<MoreVertIcon />}
			options={options}
			linkClassName={classes.link}
			menuClassName={classes.menu}
			onClick={onClick}
		/>
	);
};
