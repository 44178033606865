import { AscensionLogoLoader } from '@components/SVGs/AscensionLogo/AcensionLogoLoader';
import React from 'react';
import { BoxProps, Box } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
	loader: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: alpha(theme.palette.common.white, 0.7),
		zIndex: 9999,
	},
}));

const Loader: React.FC<BoxProps> = ({ className, ...props }) => {
	const classes = useStyles();
	return (
		<Box className={classNames(classes.loader, className)} {...props}>
			<AscensionLogoLoader/>
		</Box>
	);
};

export default Loader;
