import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorStatusSelector } from '@store/selectors/ErrorStatusSelector';
import { Box, Grid, Modal, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { clearErrorStatus } from '@store/actions/ErrorStatusActionCreators';
import { Text } from '@ascension/web';

const useStyles = makeStyles((theme: Theme) => ({
	modal: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(10)
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: 0,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
		outline: 'none',
		maxWidth: '50vw',
		height: 'fit-content',
		width: theme.spacing(80)
	},
	titleText: {
		fontSize: 32,
		fontWeight: 500,
		textAlign: 'left'
	},
	cancelIcon: {
		'width': 20,
		'color': theme.palette.grey['500'],
		'&:hover': {
			cursor: 'pointer'
		}
	},
	errorContentSection: {
		fontSize: 18,
		justifyContent: 'center',
		fontWeight: 600,
		textAlign: 'left',
		marginTop: theme.spacing(2),
		lineHeight: `${theme.spacing(3)}px`
	},
	disclaimerContainer: {
		fontSize: 15,
		textAlign: 'left',
		marginTop: theme.spacing(2),
		letterSpacing: '0.25px',
		lineHeight: `${theme.spacing(3)}px`
	},
	disclaimerTextBold: {
		display: 'block',
		fontWeight: 600
	},
	disclaimerText: {
		display: 'block'
	}
}));

const ErrorStatusModal = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { status, code: errorCode, timestamp, message } = useSelector(ErrorStatusSelector);
	const cancelClickHandler = () => dispatch(clearErrorStatus());
	const formattedTimestamp = timestamp && new Date(timestamp).toISOString().replace('T', ' ').slice(0, -5)+'Z';
	const displayError = errorCode ? errorCode : `${status}`;

	return (
		<Box>
			<Modal open={!!errorCode} className={classes.modal}>
				<Box className={classes.paper}>
					<Grid container direction="row" justifyContent="space-between">
						<Grid className={classes.titleText}>Error</Grid>
						<Grid><CancelIcon className={classes.cancelIcon} onClick={cancelClickHandler} /></Grid>
					</Grid>
					<Box>
						<Box className={classes.errorContentSection}>
							<Box id="error">
								Error { displayError }
							</Box>
							<Box>
								Timestamp: { formattedTimestamp }
							</Box>
						</Box>
						<Box className={classes.disclaimerContainer}>
							<Text className={classes.disclaimerTextBold}>{message}</Text>
							<Text className={classes.disclaimerText}>Please call your service help desk number and reference this error code and timestamp.</Text>
						</Box>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default ErrorStatusModal;
