import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DrawerPane, TextField } from '@ascension/ui-library';
import { Box, Grid, IconButton, InputLabel, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DrawerPaneToggleEvent } from '@ascension/ui-library/dist/components/Drawer';
import { useAppPageBodyClassSetter } from '@components/AppPage/AppPageContext/AppPageContext';
import { useUnitCalendarPageContext } from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import EventTimeSpanInput from '@components/UnitCalendarPage/EventTimeSpanInput/EventTimeSpanInput';
import GoldButton from '@components/GoldButton/GoldButton';
import useSubmitUnavailableEvent from '@data/openCalendar/useSubmitUnavailableEvent';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';
import { EventTimeValidationSchema } from '@components/UnitCalendarPage/EventTimeSpanInput/EventTimeValidationSchema';
import useShouldDisableSubmit from '@components/UnitCalendarPage/utilities/useShouldDisableSubmit';
import defaultUnavailableEventFormValues
	from '@components/UnitCalendarPage/utilities/defaultUnavailableEventFormValues';
import UnavailableEventFormType from '@components/UnitCalendarPage/utilities/UnavailableEventFormType';
import { enUS } from 'date-fns/locale';
import CustomDateFnsUtils from '@calendar/utilities/CustomDateFnsUtil';

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiAscensionDrawerPane-root > .MuiAscensionDrawerPane-drawerPaper': {
			boxSizing: 'border-box',
			padding: theme.spacing(3),
		},
	},
	title: {
		paddingTop: theme.spacing(2),
	},
	closeIcon: {
		height: '48px',
		width: '48px',
		top: '-12px',
		right: '-18px',
	},
	unavailableForm: {
		height: '100%',
	},
	displayField: {
		marginTop: theme.spacing(4),
	},
	label: {
		fontWeight: 600,
		marginBottom: theme.spacing(1),
	},
	datePicker: {
		'marginTop': theme.spacing(4),
		'&:focus-within .MuiInputLabel-root:not(.Mui-error)': {
			color: theme.palette.primary.main,
		},
		'& .MuiTextField-root > .MuiFormHelperText-root': {
			display: 'none',
		},
	},
	dateLabel: {
		fontWeight: 600,
		marginBottom: theme.spacing(1),
	},
	description: {
		width: '100%',
	},
	submitBtn: {
		marginRight: theme.spacing(2),
		height: '48px',
	},
	cancelBtn: {
		height: '48px',
	},
}));

const UnavailableTimeDrawer: React.FC = () => {
	const classes = useStyles();
	const { formState: { errors }, handleSubmit } = useFormContext<UnavailableEventFormType>();
	const { submitUnavailableEvent, hasAPIConflictError, setTimeSpanAPIError } = useSubmitUnavailableEvent();

	const {
		selectedRoom,
		selectedUnit: { name: unitName, hospitalName },
		setSelectedRoom,
	} = useUnitCalendarPageContext();
	const { setBodyClass } = useAppPageBodyClassSetter('UnavailableTimeDrawer');

	const {
		currentValue: dateVal,
		setCurrentValue: setFormDate,
		error: dateError,
		triggerSelfValidation: triggerDateValidation,
	} = useFieldFormContext<Date | null>('date', {
		validationRules: EventTimeValidationSchema.date,
	});

	const setDateVal = React.useCallback((val: Date | null) => {
		setFormDate(val ? new Date(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0, 0) : val);
	}, [setFormDate]);

	const {
		currentValue: descriptionVal,
		setCurrentValue: setDescriptionVal,
	} = useFieldFormContext<string>('description');

	useFieldFormContext('hospitalId');
	useFieldFormContext('roomId');
	useFieldFormContext('unitId');
	const { currentValue: idVal } = useFieldFormContext<string>('id');
	const titleText = `${!idVal ? 'Create' : 'Edit'} unavailable time`;
	const buttonText = !idVal ? 'Create unavailable time' : 'Save Changes';
	const { shouldDisableSubmit, resetInitialValues } = useShouldDisableSubmit<Required<UnavailableEventFormType>>(defaultUnavailableEventFormValues);
	const isInitialized = !!idVal;
	const isError = idVal ? Object.keys(errors).length > 0 : false;

	React.useEffect(() => {
		if (!isInitialized) return;

		resetInitialValues();
	}, [isInitialized, resetInitialValues]);

	const handleTogglePane = React.useCallback(({ classNames: { marginSize } }: DrawerPaneToggleEvent) => {
		setBodyClass(marginSize);
	}, [setBodyClass]);

	const handleClose = () => {
		setSelectedRoom(undefined);
	};

	const onSubmit = (unavailableEventFormValues: UnavailableEventFormType) => {
		submitUnavailableEvent(unavailableEventFormValues);
	};

	return (
		<div className={classes.root}>
			<DrawerPane
				translate="yes"
				open={!!selectedRoom}
				variant="persistent"
				anchor="right"
				onTogglePane={handleTogglePane}
			>
				<form onSubmit={handleSubmit(onSubmit)} className={classes.unavailableForm} noValidate>
					<Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
						<Box>
							<Box display="flex" justifyContent="space-between">
								<Typography
									variant="h4"
									component="h1"
									className={classes.title}
								>
									{titleText}
								</Typography>
								<IconButton onClick={handleClose} data-field="close-icon-btn" className={classes.closeIcon}>
									<CloseIcon />
								</IconButton>
							</Box>
							<Box display="flex" flexDirection="column" className={classes.displayField}>
								<InputLabel
									className={classes.label}
									id="locationLabel"
									htmlFor="locationVal"
								>
									Location
								</InputLabel>
								<Typography
									variant="body1"
									component="span"
									aria-labelledby="locationLabel"
									id="locationVal"
								>
									{`${hospitalName} ${unitName}`}
								</Typography>
							</Box>
							<Box display="flex" flexDirection="column" className={classes.displayField}>
								<InputLabel
									className={classes.label}
									id="roomLabel"
									htmlFor="roomVal"
								>
									OR room
								</InputLabel>
								<Typography
									variant="body1"
									component="span"
									aria-labelledby="roomLabel"
									id="roomVal"
								>
									{selectedRoom}
								</Typography>
							</Box>
							<Grid container direction="column">
								<Grid item xs={12} lg={6} className={classes.datePicker}>
									<MuiPickersUtilsProvider utils={CustomDateFnsUtils} locale={enUS}>
										<InputLabel required className={classes.dateLabel} error={!!dateError}>Date</InputLabel>
										<KeyboardDatePicker
											autoOk
											variant="inline"
											inputVariant="outlined"
											format="yyyy-MM-dd"
											InputAdornmentProps={{ position: 'start' }}
											value={dateVal}
											onChange={(d: Date) => setDateVal(d)}
											onBlur={triggerDateValidation}
											onAccept={triggerDateValidation}
											error={!!dateError}
											keyboardIcon={<CalendarTodayIcon/>}
											data-field="select-date-input"
											required
										/>
										{!!dateError &&
											<ErrorMessageMui errorMessage={dateError} />
										}
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item xs={12} className={classes.displayField}>
									<EventTimeSpanInput
										label="Time"
										hasAPIConflictError={hasAPIConflictError}
										setTimeSpanAPIError={setTimeSpanAPIError}
									/>
								</Grid>
								<Grid item  xs={12} className={classes.displayField}>
									<TextField
										translate="yes"
										variant="static"
										label="Description"
										value={descriptionVal}
										onChange={e => setDescriptionVal(e.target.value)}
										data-field="description-input"
										className={classes.description}
									/>
								</Grid>
							</Grid>
						</Box>
						<Box>
							<GoldButton
								className={classes.submitBtn}
								data-field="unitCalendar-unavailableDrawer-submitBtn"
								disabled={shouldDisableSubmit || isError}
								type="submit"
							>
								{buttonText}
							</GoldButton>
							<BlueOutlineButton
								onClick={handleClose}
								className={classes.cancelBtn}
								data-field="unitCalendar-unavailableDrawer-cancelBtn"
							>
								cancel
							</BlueOutlineButton>
						</Box>
					</Box>
				</form>
			</DrawerPane>
		</div>
	);
};

export default UnavailableTimeDrawer;
