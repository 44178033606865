import React from 'react';

export const HalfMoonIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
		>
			<path
				d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
				fill="black"
			/>
			<path
				d="M12 12V22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2V12Z"
				fill="black"
			/>
		</svg>
	);
};
