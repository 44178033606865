import React, { useEffect, useMemo } from 'react';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { useGetBlockReleasesByNpi } from '@data/blockReleases/hooks/useGetBlockReleasesByNpi';
import { BlockReleaseRequest } from '@data/blockReleases/types/BlockReleasesListResponse';
import { BlockReleaseState } from '@data/blockReleases/types/BlockReleaseState';
import { Box, createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Unit } from '@data/unit/Unit';
import { PendingReleaseCards } from './PendingReleaseCards';
import { useSurgeons } from '@utilities/hooks/useSurgeons/useSurgeons';

const useStyles = makeStyles((theme) =>
	createStyles({
		releasesesHeader: {
			color: theme.palette.secondary.main,
			fontSize: '1.125rem',
		},
		releasesSection: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
		},
	}),
);

export const PendingReleases = () => {
	const classes = useStyles();
	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);
	const { npis, surgeonByNpi } = useSurgeons();

	const timeZoneByHospital = useMemo(() => {
		return units.reduce<{ [key: string]: string }>((acc, unit) => {
			acc[unit.hospitalName] = unit.hospitalTimeZone;
			return acc;
		}, {});
	}, [units]);

	const { getReleases, releases } = useGetBlockReleasesByNpi();

	useEffect(() => {
		const requestBody: BlockReleaseRequest = {
			query: 'RELEASE_BY_NPI',
			npis,
			types: [BlockReleaseState.PENDING_RELEASE],
		};
		if (npis.length) {
			getReleases(requestBody);
		}
	}, [getReleases, npis]);

	if (!releases.length) {
		return null;
	}

	return (
		<>
			<Typography variant="h2" className={classes.releasesesHeader}>
				Pending Releases
			</Typography>
			<Box className={classes.releasesSection}>
				<PendingReleaseCards
					releases={releases}
					surgeonByNpi={surgeonByNpi}
					timeZoneByHospital={timeZoneByHospital}
				/>
			</Box>
		</>
	);
};
