export enum CaseCommentTypes {
	APPOINTMENT_DETAILS = 'APPOINTMENT_DETAILS',
	AUTHORIZATION = 'AUTHORIZATION',
	GENERAL = 'GENERAL',
	INSURANCE_INFO = 'INSURANCE_INFO',
	PATIENT_INFO = 'PATIENT_INFO',
	PRE_ADMIT_TESTING = 'PRE_ADMIT_TESTING',
	SURGERY_INFO = 'SURGERY_INFO',
}

export const CommentTypes: CaseCommentTypes[] = [
	CaseCommentTypes.APPOINTMENT_DETAILS,
	CaseCommentTypes.AUTHORIZATION,
	CaseCommentTypes.GENERAL,
	CaseCommentTypes.INSURANCE_INFO,
	CaseCommentTypes.PATIENT_INFO,
	CaseCommentTypes.PRE_ADMIT_TESTING,
	CaseCommentTypes.SURGERY_INFO,
];

export interface CaseCommentBody {
	comment: string;
	types: CaseCommentTypes[];
}

export interface AddCaseCommentRequest extends CaseCommentBody {
	resourceId: string;
}

export interface EditCaseCommentRequest extends CaseCommentBody {
	commentId: string;
}

export interface CaseCommentMetaData {
	commenterFirstName: string;
	commenterLastName: string;
}

export type CaseCommentStatus = 'CREATED' | 'DELETED' | 'EDITED';

export interface CaseCommentHistory {
	comment: string;
	modifiedDateTime: string;
	status: CaseCommentStatus;
	types: CaseCommentTypes[];
}

export interface CaseComment extends CaseCommentBody{
	createdDateTime: string,
	history: CaseCommentHistory[],
	id: string,
	metaData: CaseCommentMetaData,
	status: CaseCommentStatus,
	isOwner: boolean,
}

export interface CaseCommentDetails extends CaseComment {
	typesDisplay: string;
	commenterDisplay: string;
}

export type SortableCaseCommentFields = keyof Pick<CaseCommentDetails, 'createdDateTime' | 'typesDisplay' | 'commenterDisplay'>;

export interface CaseCommentsApiResponse {
	comments: CaseComment[];
}

export interface CaseCommentsRequest {
	resourceIds: string[];
}

export interface DeleteCaseCommentRequest {
	commentId: string;
}
