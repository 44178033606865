import React from 'react';
import { Box, createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatInTimeZone } from 'date-fns-tz';

import DisplayTimeFrame from '@components/DisplayTimeFrame/DisplayTimeFrame';
import classNames from 'classnames';
import { PartialInterval } from '@interfaces/Interval';
import { getDateFromApiDateTime } from '@calendar/utilities';

const useStyles = makeStyles(theme => createStyles({
	root: {
		'padding': theme.spacing(2),
		'border': `1px solid ${theme.palette.primary.main}`,
		'borderRadius': '8px',
		'fontSize': '1.125rem',
		'& > p:nth-child(2)': {
			marginBottom: theme.spacing(1.25),
		},
	},
	selected: {
		backgroundColor: theme.palette.primary[50],
		boxShadow: theme.shadows[9],
	},
	surgeon: {
		fontWeight: 600,
	}
}));

export interface ReleaseCardProps {
	surgeonName?: string;
	unit?: string;
	date?: string;
	timeFrame?: PartialInterval;
	timeZone?: string;
	selected?: boolean;
}

export const ReleaseCard: React.FC<ReleaseCardProps> = ({
	surgeonName,
	unit,
	date,
	timeFrame,
	timeZone,
	selected = false,
}) => {
	const classes = useStyles();

	if (!surgeonName || !unit || !date || !timeFrame || !timeZone) return null;

	const formattedDate = formatInTimeZone(
		getDateFromApiDateTime(date),
		timeZone,
		'eeee, MMM d, yyyy',
	);

	return (
		<Box className={classNames(classes.root, { [classes.selected]: selected })}>
			<Typography component="h3" className={classes.surgeon}>
				{surgeonName}
			</Typography>
			<Typography>{unit}</Typography>
			<Typography>{formattedDate}</Typography>
			<DisplayTimeFrame timeFrame={timeFrame} timeZone={timeZone} />
		</Box>
	);
};
