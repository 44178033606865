import React, { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { isSameDay } from 'date-fns';
import { IconButton, Typography } from '@material-ui/core';

const TODAY = new Date();

const useStyles = makeStyles(theme => ({
	day: {
		'color': theme.palette.grey[800],
		'width': 32,
		'height': 32,
		'fontSize': theme.typography.caption.fontSize,
		'margin': theme.spacing(.125, .5),
		'&:disabled': {
			cursor: 'not-allowed',
			pointerEvents: 'all'
		}
	},
	highlightNonCurrentMonthDay: {
		color: theme.palette.primary.main,
	},
	highlight: {
		'background': theme.palette.primary.main,
		'color': theme.palette.common.white,
		'borderRadius': '50%',
		'width': 32,
		'height': 32,
		'fontSize': theme.typography.caption.fontSize,
		'margin': theme.spacing(.125, .5),
		'&:hover': {
			background: theme.palette.primary.main,
		}
	},
}));

export interface CalendarPopoverDayProps {
	date: Date | null;
	selectedDate: Date | null;
	dayInCurrentMonth: boolean;
	onSelectedDateChange: Dispatch<SetStateAction<Date>>;
	disabled?: boolean;
}

const CalendarPopoverDay = ({ date, selectedDate, dayInCurrentMonth, onSelectedDateChange, disabled }: CalendarPopoverDayProps) => {
	date = date || TODAY;
	selectedDate = selectedDate || TODAY;

	const day = date.getDate();
	const sameDay = isSameDay(date, selectedDate);

	const handleDateClick = React.useCallback(() => {
		if (dayInCurrentMonth || disabled) { return; }
		onSelectedDateChange(date);
	}, [date, dayInCurrentMonth, onSelectedDateChange, disabled]);

	const classes = useStyles();
	const dayClassName = clsx(sameDay ? classes.highlight : classes.day, {
		[classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && sameDay,
	});

	return (
		<IconButton
			className={dayClassName}
			onClick={handleDateClick}
			disabled={disabled}
		>
			<Typography variant="body2">{day}</Typography>
		</IconButton>
	);
};

export default CalendarPopoverDay;
