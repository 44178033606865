import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	formatDateTo24HrTime,
	StatefulTimeSelect
} from '@components/StatefulInputs/StatefulTimeSelect/StatefulTimeSelect';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import {
	PartialReleaseEndTimeValidation,
	PartialReleaseStartTimeValidation
} from '@components/BlockReleaseRequestPage/BlockDetailsSection/PartialReleaseSection/PartialReleaseValidation';
import { useFormContext } from 'react-hook-form';
import {
	useBlockReleaseRequestContext
} from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestContext/BlockReleaseRequestContext';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(3),
	},
	itemText: {
		margin: 0,
		marginBottom: theme.spacing(3),
		fontWeight: 600,
	},
	inputContainer: {
		'display': 'flex',
		'justifyContent': 'space-between',
		'gap': theme.spacing(3),
		'& > div': {
			flex: 1,
		}
	},
}));

export const PartialReleaseSection = () => {
	const classes = useStyles();

	const { releaseRequestInfo } = useBlockReleaseRequestContext();
	const { trigger } = useFormContext();

	const hospitalTimeZone = releaseRequestInfo?.facility?.hospitalTimeZone ?? '';
	const unitName = releaseRequestInfo?.blockInfo?.blocks?.[0]?.unit || '';
	const { partialReleaseEnabled } = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unitName, 'name');

	const blockStart = formatDateTo24HrTime(hospitalTimeZone, releaseRequestInfo?.blockInfo?.blocks?.[0]?.start);
	const blockEnd = formatDateTo24HrTime(hospitalTimeZone, releaseRequestInfo?.blockInfo?.blocks?.[0]?.end);

	const optionsConfig = React.useMemo(() => {
		return {
			start: blockStart || '07:00',
			end: blockEnd || '16:00',
			increment: 15,
		};
	}, [blockStart, blockEnd]);

	if (!partialReleaseEnabled) {
		return null;
	}

	const triggerValidation = () => {
		void trigger();
	};

	return (
		<Box className={classes.root}>
			<Typography component="h3" className={classes.itemText}>
				Release all or part of unused block time?
			</Typography>
			<Box className={classes.inputContainer}>
				<StatefulTimeSelect
					label="Start time"
					dataField="blockReleaseRequest-partialRelease-startTime"
					controllerOptions={{
						defaultValue: formatDateTo24HrTime(hospitalTimeZone, releaseRequestInfo?.blockInfo?.blocks?.[0]?.start),
						rules: {
							...PartialReleaseStartTimeValidation,
							onChange: triggerValidation,
						},
					}}
					name="startTime"
					optionsConfig={optionsConfig}
				/>
				<StatefulTimeSelect
					label="End time"
					dataField="blockReleaseRequest-partialRelease-endTime"
					controllerOptions={{
						defaultValue: formatDateTo24HrTime(hospitalTimeZone, releaseRequestInfo?.blockInfo?.blocks?.[0]?.end),
						rules: {
							...PartialReleaseEndTimeValidation,
							onChange: triggerValidation,
						},
					}}
					name="endTime"
					optionsConfig={optionsConfig}
				/>
			</Box>
		</Box>
	);
};
