import React from 'react';
import {
	makeStyles,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { useCommentsContext } from '@components/CaseDetails/CommentsSection/CommentsContext/CommentsContext';
import { SortableCaseCommentFields } from '@data/caseComments/types/CaseComments.types';
import { TableSortableHeadCell } from '@components/TableSortableHeadCell/TableSortableHeadCell';

const useStyles = makeStyles({
	headerCell: {
		whiteSpace: 'nowrap',
	},
	sortLabel: {
		'&.MuiTableSortLabel-root': {
			'&.MuiTableSortLabel-active': {
				'color': 'white',
				'&.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
					color: 'white',
				},
			},
			'&:hover': {
				color: 'inherit'
			},
		},
	},
});

interface HeaderCell {
	sortableFieldName?: SortableCaseCommentFields;
	label: string;
}

const headCells: HeaderCell[] = [
	{
		sortableFieldName: 'createdDateTime',
		label: 'Date',
	},
	{
		sortableFieldName: 'typesDisplay',
		label: 'Comment Type',
	},
	{
		sortableFieldName: 'commenterDisplay',
		label: 'Commenter',
	},
	{
		label: 'Comments',
	},
	{
		label: '',
	}
];

export const CommentsTableHeader = () => {
	const classes = useStyles();
	const { sortOrder, orderBy, handleSortComments } = useCommentsContext();

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableSortableHeadCell<SortableCaseCommentFields>
						key={headCell.label}
						headCell={headCell}
						sortOrder={sortOrder}
						orderBy={orderBy}
						handleSortComments={handleSortComments}
						sortLabelClassName={classes.sortLabel}
						className={classes.headerCell}
					/>
				))}
			</TableRow>
		</TableHead>
	);
};
