import { ModalValues } from '@interfaces/ModalValues';

export const ALERT_MODAL = 'ALERT_MODAL';
export const CANCEL_MODAL = 'CANCEL_MODAL';
export const SCHEDULING_CANCEL_MODAL = 'SCHEDULING_CANCEL_MODAL';
export const CASE_LOCK_MODAL = 'CASE_LOCK_MODAL';
export const DELETE_TEMPLATE_MODAL = 'DELETE_TEMPLATE_MODAL';
export const MY_TEMPLATES_MODAL = 'MY_TEMPLATES_MODAL';
export const REQUEST_REVISION_MODAL = 'REQUEST_REVISION_MODAL';
export const SURGERY_PROCEDURE_MODAL = 'SURGERY_PROCEDURE_MODAL';
export const SURGERY_PROCEDURE_MODAL_MY_TEMPLATES = 'SURGERY_PROCEDURE_MODAL_MY_TEMPLATES';
export const SURGERY_PROCEDURE_TILE_MODAL = 'SURGERY_PROCEDURE_TILE_MODAL';
export const TERMS_OF_USE_MODAL = 'TERMS_OF_USE_MODAL';
export const SELECT_PRACTICE_CONFIRM_MODAL = 'SELECT_PRACTICE_CONFIRM_MODAL';
export const USER_LOCATION_CONFIRMATION_MODAL = 'USER_LOCATION_CONFIRMATION_MODAL';
export const ACCOUNT_LOCK_MODAL = 'ACCOUNT_LOCK_MODAL';
export const COPY_CASE_MODAL = 'COPY_CASE_MODAL';
export const NAVIGATION_WARN_MODAL = 'NAVIGATION_WARN_MODAL';

export const BLOCK_RELEASE_REQUEST_CONFIRM_MODAL: ModalValues = {
	id: 'BLOCK_RELEASE_REQUEST_CONFIRM_MODAL',
	title: 'Release Block Time',
	message: `Are you sure you want to release the remainder of your surgeon's block time?`,
	warning: 'This action cannot be undone.',
};
export const ADD_COMMENT_MODAL = 'ADD_COMMENT_MODAL';
export const EDIT_COMMENT_MODAL = 'EDIT_COMMENT_MODAL';
export const DELETE_COMMENT_MODAL: ModalValues = {
	id: 'DELETE_COMMENT_MODAL',
	title: 'Delete Comment',
	message: 'Are you sure you want to delete this comment?',
}
