import { AxiosError } from 'axios';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';
import { PendingReleaseEventResponse } from '@data/blockReleases/types/PendingReleaseEvent.types';

const ERR_CODES = ['CS01', 'CS02', 'CS05', 'CS99'];

export const errorStatusConfig = (error: AxiosError<PendingReleaseEventResponse | ErrorStatusState>): ErrorStatusState | Error => {
	if (
		error.response &&
		typeof error.response.data.code === 'string' &&
		ERR_CODES.includes(error.response.data.code)
	) {
		return {
			status: error.response.status,
			code: error.response.data.code,
			message: 'It’s not you, it’s us!',
			timestamp: new Date().getTime(),
		};
	}
	return error;
};
