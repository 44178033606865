import React from 'react';
import { isValid, parse } from 'date-fns';
import { IconButton, InputAdornment } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { DateInputPickerProps } from '@components/DateInputPicker/DateInputPickerProps';
import TextFieldInput from '@components/TextFieldInput/TextFieldInput';
import { useMask } from '@utilities/hooks/useMask/useMask';
import { dateToString } from '@utilities/dateUtilities';
import { enUS } from 'date-fns/locale';
import CustomDateFnsUtils from '@calendar/utilities/CustomDateFnsUtil';

function isValidDateFormat(dateString: string) {
	const regex = /^([0-1]?[0-9])\s*\/\s*([0-3]?[0-9])\s*\/\s*([0-9]{4})$/;
	return regex.test(dateString);
}

const DateInputPicker = ({
	name,
	label,
	subLabel,
	currentDate,
	onValidDateChange,
	onBlur,
	errorMessage,
	required,
	disabled,
}: DateInputPickerProps) => {
	const {
		value: maskedDate,
		updateValue: updateMaskedDate,
		mask,
	} = useMask('date', dateToString(currentDate));
	const dateFieldRef = React.useRef(null);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);
	const closeDatePicker = React.useCallback(() => setAnchorEl(null), []);

	React.useEffect(() => {
		closeDatePicker();
	}, [closeDatePicker, currentDate]);

	React.useEffect(() => {
		const formattedDate = dateToString(currentDate);
		if (formattedDate) {
			updateMaskedDate(formattedDate);
		}
	}, [updateMaskedDate, currentDate]);

	const handleOpenDatePickerClick = () => {
		setAnchorEl(dateFieldRef.current);
	};

	const handleDateChange = React.useCallback(
		(date: Date) => {
			onValidDateChange(date || undefined);
		},
		[onValidDateChange],
	);

	React.useEffect(() => {
		if (maskedDate && isValidDateFormat(maskedDate)) {
			const parsedDate = parse(
				maskedDate.replace(/\s+/g, ''),
				'MM/dd/yyyy',
				new Date(),
			);
			if (isValid(parsedDate)) {
				onValidDateChange(parsedDate);
			}
		}
	}, [maskedDate, onValidDateChange]);

	const handleOnPaste = React.useCallback(
		(event: React.ClipboardEvent<HTMLDivElement>) => {
			const value = event.clipboardData
				? event.clipboardData.getData('text')
				: '';
			event.preventDefault();
			updateMaskedDate(value);
		},
		[updateMaskedDate],
	);

	return (
		<>
			<TextFieldInput
				label={label}
				required={!!required}
				subLabel={subLabel}
				inputRef={dateFieldRef}
				fieldName={`${name}_picker`}
				value={maskedDate}
				onChange={updateMaskedDate}
				onBlur={onBlur}
				onPaste={handleOnPaste}
				placeholder={mask.placeholder}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								onClick={handleOpenDatePickerClick}
								disabled={disabled}
							>
								<CalendarTodayIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
				errorMessage={errorMessage}
				disabled={disabled}
			/>
			<MuiPickersUtilsProvider utils={CustomDateFnsUtils} locale={enUS}>
				<DatePicker
					open={!!anchorEl}
					onClose={closeDatePicker}
					PopoverProps={{
						anchorEl: anchorEl,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
					}}
					variant="inline"
					disableToolbar
					value={currentDate}
					onChange={handleDateChange}
					TextFieldComponent={() => null}
					disabled={disabled}
					format="yyyy-MM-dd"
				/>
			</MuiPickersUtilsProvider>
		</>
	);
};

export default DateInputPicker;
