import React from 'react';
import { track } from '@amplitude/analytics-browser';
import { makeStyles, MenuItem } from '@material-ui/core';
import DropdownInput from '@components/DropdownInput/DropdownInput';
import { TrackableEventType } from '@components/BaseEventTrackingWrapper/BaseTrackableEvents.types';
import { BaseEventTrackingWrapper } from '@components/BaseEventTrackingWrapper/BaseEventTrackingWrapper';
import { DEFAULT_SELECTION_ALL } from '@utilities/constants';
import {
	mapCaseCommentTypeEnumToDisplay
} from '@components/CaseDetails/CommentsSection/utilities/mapCaseCommentTypeEnumToDisplay';
import { useCommentsContext } from '@components/CaseDetails/CommentsSection/CommentsContext/CommentsContext';
import { sortObjectsByField } from '@utilities/sortObjectsByField/sortObjectsByField';

const useStyles = makeStyles(theme => ({
	root: {
		'width': '100%',

		'& .MuiInputLabel-root': {
			textTransform: 'unset',
			fontSize: '1rem',
			color: theme.palette.grey[700],
		},
		'& .MuiOutlinedInput-root': {
			'height': '48px',

			'& .MuiOutlinedInput-notchedOutline > legend': {
				width: '0px',
				display: 'block',
			},
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.grey[700],
		},
		'& .MuiSelect-outlined.MuiSelect-outlined.MuiOutlinedInput-input': {
			paddingTop: theme.spacing(1.875),
			paddingBottom: theme.spacing(1.875),
			borderRadius: '4px',
		},
		'& .MuiAscensionDropdown-formControlDefault': {
			marginTop: theme.spacing(.5),
		},
	},
}));

const DATA_FIELD_DROPDOWN = 'caseDetails-comments-commentTypeFilterDropdown';
const DATA_FIELD_OPTION = 'caseDetails-comments-commentTypeFilterDropdown-commentTypeFilterOption';

const handleTrackingEvent = (dataField: string, name: string) => {
	const eventType = 'click';
	const eventName = `${name}-${eventType}`;
	track(eventName, { fieldName: dataField, eventType: eventType });
};

export const CommentTypeFilter: React.FC = () => {
	const classes = useStyles();
	const { setTypeFilter, typeFilter } = useCommentsContext();

	const commentOptionsWithAll = React.useMemo(() => {
		const allOption = {
			label: 'All',
			id: DEFAULT_SELECTION_ALL,
		};

		const commentsWithLabels = Object.entries(mapCaseCommentTypeEnumToDisplay)
			.map(([id, label]) => ({
				label,
				id,
			}));

		const sortedCommentTypes = commentsWithLabels.sort(sortObjectsByField('label'));
		return [allOption, ...sortedCommentTypes];
	}, []);

	const handleOnChange = (val: string) => {
		handleTrackingEvent(`${DATA_FIELD_OPTION}-${val}`, 'option');
		return setTypeFilter(val);
	}

	return (
		<BaseEventTrackingWrapper
			handleEvent={() => handleTrackingEvent(DATA_FIELD_DROPDOWN, 'dropdown')}
			dataField={DATA_FIELD_DROPDOWN}
			eventsToTrack={[TrackableEventType.OnClick]}
		>
			<DropdownInput
				value={typeFilter}
				onChange={handleOnChange}
				fieldName="commentType"
				label="Comment type"
				className={classes.root}
			>
				{commentOptionsWithAll.map(option =>
					<MenuItem
						key={option.id}
						value={option.id}
					>
						{option.label}
					</MenuItem>
				)}
			</DropdownInput>
		</BaseEventTrackingWrapper>
	);
};
