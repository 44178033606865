import React from 'react';
import ModalViewer from '@components/Modal/ModalViewer';
import { makeStyles, Typography } from '@material-ui/core';
import { ScheduleColumnData } from '@data/schedules/types/ScheduleColumnData';
import { ModalValues } from '@interfaces/ModalValues';
import { BlockReleaseRequestConfirmModalInfo } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestConfirmModal/BlockReleaseRequestConfirmModalInfo/BlockReleaseRequestConfirmModalInfo';
import { BlockReleaseRequestConfirmModalButtons } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestConfirmModal/BlockReleaseRequestConfirmModalButtons/BlockReleaseRequestConfirmModalButtons';
import { Facility } from '@interfaces/Facility';

export interface BlockReleaseConfirmModalProps {
	modalValues: ModalValues;
	blockReleaseInfo: ScheduleColumnData;
	facilityInfo: Facility;
}

const useStyles = makeStyles((theme) => ({
	title: {
		marginBottom: theme.spacing(3),
	},
}));

export const BlockReleaseRequestConfirmModal: React.FC<BlockReleaseConfirmModalProps> = ({
	modalValues,
	blockReleaseInfo,
	facilityInfo,
}) => {
	const classes = useStyles();

	return (
		<ModalViewer
			dataField="blockRelease-blockReleaseRequestModal"
			id={modalValues.id+blockReleaseInfo.id}
		>
			<Typography component="h2" variant="h6" className={classes.title}>
				{modalValues.title}
			</Typography>
			<BlockReleaseRequestConfirmModalInfo
				modalValues={modalValues}
				blockReleaseInfo={blockReleaseInfo}
				facilityInfo={facilityInfo}
			/>
			<BlockReleaseRequestConfirmModalButtons blockReleaseInfo={blockReleaseInfo} facilityInfo={facilityInfo}/>
		</ModalViewer>
	);
};
