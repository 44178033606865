import React from 'react';
import { BaseButton } from '@components/BaseButton/BaseButton';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { ADD_COMMENT_MODAL } from '@components/Modal/modalConstants';

const useStyles = makeStyles(theme => ({
	root: {
		'borderRadius': '24px',
		'height': '48px',
		'& .MuiSvgIcon-root': {
			marginRight: theme.spacing(1),
		},
	}
}));

export const AddCommentButton: React.FC = () => {
	const classes = useStyles();
	const { openModal } = useModalContext();

	const handleAddComment = React.useCallback(() => {
		openModal(ADD_COMMENT_MODAL);
	}, [openModal]);

	return (
		<BaseButton
			dataField="caseDetails-commentsSection-addCommentButton"
			variant="contained"
			color="primary"
			className={classes.root}
			onClick={handleAddComment}
		>
			<Add />
			Add Comment
		</BaseButton>
	);
};
