import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import RequestFormPageState from '@components/RequestForm/RequestFormPage.types';
import { Surgeon } from '@data/surgeon/Surgeon';
import { getFormattedName } from '@utilities/commonFunction';
import { useHistory } from 'react-router-dom';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { ENDPOINT_SURGEONS } from '@utilities/apiConstants';
import { SurgeryProcedureInterface } from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';

/***
 * useSetPrimaryProcedureNameAndSurgeon(): void
 * populates the surgeon when coming from Scheduling page
 * populates the surgeon and procedure name when coming from Find a Time page
 * @param formMethods {UseFormReturn<SurgeryProcedureInterface>} - formMethods from useForm setup
 *
 * @returns (value: number) => {void}
 */
export const useSetPrimaryProcedureNameAndSurgeon = (formMethods: UseFormReturn<SurgeryProcedureInterface>) => {
	const { location: { state } } = useHistory<RequestFormPageState>();
	const surgeons = useCollection<Surgeon>(ENDPOINT_SURGEONS);

	const getSurgeonValue = React.useCallback((surgeonId: string) => {
		const surgeonLabel = getFormattedName(surgeons.find((surgeon) => (surgeon.id === surgeonId), ''));
		return {
			value: surgeonId,
			label: surgeonLabel
		};
	}, [surgeons]);

	return React.useCallback((value: number) => {
		// only set surgeon and procedure name for primary procedure (equals 1)
		if (value !== 1) { return; }

		const surgeonValue = formMethods.getValues().surgeon?.value;
		if (state?.blockInfo?.surgeon?.id && !surgeonValue) {
			const surgeonValue = getSurgeonValue(state.blockInfo.surgeon.id);
			formMethods.setValue('surgeon', surgeonValue, { shouldValidate: false });
		}
		if (state?.findATimeRequestFormValues?.primarySurgeon && !surgeonValue) {
			const surgeonValue = getSurgeonValue(state.findATimeRequestFormValues.primarySurgeon);
			formMethods.setValue('surgeon', surgeonValue, { shouldValidate: false });
		}
		const procedureNameValue = formMethods.getValues().procedureName;
		if (state?.findATimeRequestFormValues?.procedureName && !procedureNameValue) {
			formMethods.setValue('procedureName', state.findATimeRequestFormValues.procedureName);
		}
	}, [formMethods, getSurgeonValue, state?.blockInfo?.surgeon?.id, state?.findATimeRequestFormValues?.primarySurgeon, state?.findATimeRequestFormValues?.procedureName]);
};
