import React from 'react';
import {
	BlockReleaseRequestContextType
} from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestContext/BlockReleaseRequestContext.types';
import { useLocation } from 'react-router-dom';
import {
	useSchedulePageContext
} from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';

const BlockReleaseRequestContext = React.createContext<BlockReleaseRequestContextType | undefined>(undefined);

export const useBlockReleaseRequestContext = () => {
	const ctx = React.useContext(BlockReleaseRequestContext);

	if (!ctx) {
		throw new Error(
			'useBlockReleaseRequestContext must be used in a child of BlockReleaseRequestProvider.',
		);
	}

	return ctx;
};

export const BlockReleaseRequestProvider: React.FC = ({ children }) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const surgeonNpi = queryParams.get('surgeon');

	const { selectedFacility, selectedDate, schedules, getSchedules } = useSchedulePageContext();

	React.useEffect(() => {
		if (surgeonNpi) {
			getSchedules(selectedDate, [parseInt(surgeonNpi)], selectedFacility?.id);
		}
	}, [surgeonNpi, selectedFacility, selectedDate, getSchedules]);

	// This will also include releaseInfo when the api is implemented
	const releaseRequestInfo = { blockInfo: schedules[0], facility: selectedFacility };

	return <BlockReleaseRequestContext.Provider value={{ releaseRequestInfo }}>
		{children}
	</BlockReleaseRequestContext.Provider>;
};
