import React from 'react';
import { Box, BoxProps, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReplacedNode } from '@components/CaseActivityDrawer/types/CaseActivityRowType';
import {
	formatActivityChangeDetails,
	FormatActivityChangeDetailsReturn
} from '@components/CaseActivityDrawer/utilities/formatActivityChangeDetails';
import { useSelector } from 'react-redux';
import { InsuranceAuthStatusTypesSelector } from '@store/selectors/InsuranceAuthStatusTypesSelector';

interface CaseActivityChangesProps extends BoxProps {
	changes: ReplacedNode[];
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0.5, 1),
		width: 'full',
		fontSize: 12,
		fontFamily: 'HCo Whitney SSm',
		backgroundColor: theme.palette.primary[50],
		borderRadius: 4,
		marginBottom: theme.spacing(2),
	},
	horizontalRule: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	changesDiv: {
		display: 'flex',
		gap: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	changedItemsDiv: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		flex: 3
	},
	oldItemText: {
		textDecoration: 'line-through',
		textDecorationColor: theme.palette.grey[600],
		color: theme.palette.grey[600],
	},
	changeTitle: {
		flex: 1
	}
}));

const changedFieldNamesToDisplay = ['Procedure Date', 'Procedure Time', 'Equipment', 'Authorization'];

const getEquipmentFieldName = (change: ReplacedNode) => {
	return change.fieldFriendlyName.split('::')[0];
};

const FieldChangeDisplay = ({ changedField, changeDetails }: FormatActivityChangeDetailsReturn) => {
	const classes = useStyles();

	return (
		<>
			<Box>
				<strong>{changedField}:</strong>
			</Box>
			<Box className={classes.changedItemsDiv}>
				<Box>
					{changeDetails.currValue}
				</Box>
				<Box className={classes.oldItemText}>
					{changeDetails.prevValue}
				</Box>
			</Box>
		</>
	);
};

export function CaseActivityChanges({ changes, ...props }: CaseActivityChangesProps) {
	const classes = useStyles();
	const authStatusConfigTypes = useSelector(InsuranceAuthStatusTypesSelector);

	const changesToDisplay = changes?.filter((change) =>
		changedFieldNamesToDisplay.includes(getEquipmentFieldName(change)),
	);

	const equipmentChanges = changesToDisplay?.filter((change) => {
		return getEquipmentFieldName(change) === 'Equipment';
	});

	const allOtherChanges = changesToDisplay?.filter((change) => {
		return getEquipmentFieldName(change) !== 'Equipment';
	});

	const authorizationStatusChanges = allOtherChanges?.[0]?.fieldFriendlyName === 'Authorization';

	return (
		<>
			{/* Show all changes except equipment in same box */}
			{/* Authorization changes show as separate case activity entry from all other changes */}
			{allOtherChanges?.length > 0 && (
				<>
					<Box {...props} className={classes.root}>
						<Box>
							{authorizationStatusChanges ?
								'Authorization status update.' :
								'This request was edited.'
							}
						</Box>
						<Divider className={classes.horizontalRule} />
						<Box pb={3}>
							{allOtherChanges.map((change) => {
								const { changedField, changeDetails } = formatActivityChangeDetails(change, authStatusConfigTypes);
								return (
									<Box className={classes.changesDiv} key={changedField}>
										<FieldChangeDisplay changedField={changedField} changeDetails={changeDetails} />
									</Box>
								);
							})}
						</Box>
					</Box>
				</>
			)}
			{/* Show separate blue box for each procedure's equipment changes */}
			{equipmentChanges?.length > 0 && (
				<>
					{equipmentChanges.map((change) => {
						const { changedField, changeDetails } = formatActivityChangeDetails(change, authStatusConfigTypes);
						return (
							<Box {...props} className={classes.root} key={changeDetails.changeTitle}>
								<>
									<Box>{changeDetails.changeTitle}</Box>
									<Divider className={classes.horizontalRule} />
									<Box className={classes.changesDiv}>
										<FieldChangeDisplay changedField={changedField} changeDetails={changeDetails} />
									</Box>
								</>
							</Box>
						)
					})}
				</>
			)}
		</>
	);
}
