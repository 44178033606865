import React from 'react';
import { Box } from '@ascension/web';
import { SHRINKABLE_HEADER, SHRINKABLE_HEADER_TEXT } from '@utilities/constants';
import { useHeaderIsShrunk } from '../HeaderContext/HeaderContext';
import { makeStyles } from '@material-ui/core';

const transitionIn = 'all 0.25s ease-in';
const transitionOut = 'all 0.25s ease-out';
const useStyles = makeStyles((theme) => ({
	headerWrapper: { fontWeight: 'bold' },
	shrunkWrapper: { transition: transitionIn },
	expandedWrapper: { transition: transitionOut },
	shrunkText: {
		transition: transitionIn,
		fontSize: '14px',
		transform: 'translate(45px, -32px)',
		height: 0,
		marginLeft: `-${theme.spacing(5)}px`,
		marginTop: theme.spacing(6),
	},
	expandedText: { transition: transitionOut, fontSize: '32px', marginBottom: '22px', marginTop: '21px' },
}));

const ShrinkableHeader: React.FC = ({ children }) => {
	const { headerWrapper, expandedText, shrunkText, shrunkWrapper, expandedWrapper } = useStyles();
	const headerShrunk = useHeaderIsShrunk();
	const headerStyles = React.useMemo( () =>
		`${headerWrapper} ${headerShrunk ? shrunkWrapper : expandedWrapper}`,
	[headerWrapper, headerShrunk, shrunkWrapper, expandedWrapper]);
	const textStyles = React.useMemo(() =>
		headerShrunk ? shrunkText : expandedText, [headerShrunk, shrunkText, expandedText]);

	return (
		<Box data-field={SHRINKABLE_HEADER} className={headerStyles}>
			<Box>
				<Box data-field={SHRINKABLE_HEADER_TEXT}
					className={textStyles}>
					{children}
				</Box>
			</Box>
		</Box>
	);
};

export default ShrinkableHeader;
