import { CaseCommentTypes } from '@data/caseComments/types/CaseComments.types';

export interface CaseCommentForm {
	comment: string;
	commentTypes: CaseCommentTypes[];
}

export const commentFormDefaultValues: CaseCommentForm = {
	comment: '',
	commentTypes: [],
};
