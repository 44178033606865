import React, { useContext } from 'react';
import { ProcedureNameContextType } from '@components/RequestForm/ProcedureName/ProcedureNameContextType';
import { useGetProcedureNames } from '@components/RequestForm/utilities/hooks/useGetProcedureNames/useGetProcedureNames';
import { ProcedureNameInfo } from '@components/RequestForm/ProcedureName/ProcedureNameInfo';
import { useFormContext } from 'react-hook-form';
import { ProcedureNameProviderProps } from '@components/RequestForm/ProcedureName/ProcedureNameProviderProps';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';

const ProcedureNameContext = React.createContext<ProcedureNameContextType | undefined>(undefined);

export const useProcedureNameContext = () => {
	const ctx = useContext(ProcedureNameContext);

	if (!ctx) {
		throw new Error(
			'useProcedureNameContext must be used in a child of ProcedureNameProvider.',
		);
	}

	return ctx;
};

function searchAllTermsPresent(text: string, searchTerms: string[]) {
	const lowerCaseText = text.toLowerCase();
	return searchTerms.every((term) => lowerCaseText.includes(term.toLowerCase()));
}

export const ProcedureNameProvider: React.FC<ProcedureNameProviderProps> = ({ children, unitId }) => {
	const { setValue, trigger, watch } = useFormContext<RequestFormValidatedState>();
	const [procedureFilter, setProcedureFilter] = React.useState<string>('');

	const { procedures, getProceduresFromApi } = useGetProcedureNames();
	const procedureNameRhf = watch('procedureName');

	const filteredProcedures = React.useMemo(() => {
		return procedureFilter
			? procedures.filter((procedure) =>
				searchAllTermsPresent(procedure.procedureName, procedureFilter.split(' ')),
			)
			: procedures;
	}, [procedures, procedureFilter]);

	const getProcedures = React.useCallback(() => {
		unitId && void getProceduresFromApi(unitId);
	}, [unitId, getProceduresFromApi]);

	const selectedProcedure = React.useMemo(() => {
		return procedures.find(
			(procedure: ProcedureNameInfo) => procedure?.procedureName === procedureNameRhf,
		);
	}, [procedures, procedureNameRhf]);

	const triggerProcedureNameValidation = React.useCallback(() => {
		void trigger('procedureName');
	}, [trigger]);

	const handleSetSelectedProcedureName = React.useCallback((procedureName: string, disableTriggerValidation?: boolean) => {
		setValue('procedureName', procedureName, { shouldValidate: !disableTriggerValidation, shouldDirty: true });
	}, [setValue]);

	React.useEffect(() => {
		getProcedures();
	}, [getProcedures]);

	return (
		<ProcedureNameContext.Provider
			value={{
				procedures: filteredProcedures,
				selectedProcedure,
				setSelectedProcedureName: handleSetSelectedProcedureName,
				triggerProcedureNameValidation,
				setProcedureFilter,
			}}
		>
			{children}
		</ProcedureNameContext.Provider>
	);
};
