import React from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import CardSectionTitle from '@components/CardSection/CardSectionTitle/CardSectionTitle';
import AppointmentDetails from '@components/RequestForm/AppointmentDetails/AppointmentDetails';
import CardSection from '@components/CardSection/CardSection';
import DocumentRequest from '@components/RequestForm/DocumentRequest/DocumentRequest';
import FullPatientNameInput from '@components/RequestForm/PatientDetails/PatientName/FullPatientNameInput';
import useIsDocRequired from '@components/RequestForm/utilities/useIsDocRequired';
import DiagnosisInput from '@components/RequestForm/inputs/DiagnosisInput';
import PreCertNumberInput from '@components/RequestForm/inputs/PreCertNumberInput';
import CodeChipInput from '@components/ProcedureForm/ProcedureFormFields/CodeChipInput';
import { ProcedureNameInput } from '@components/RequestForm/inputs/ProcedureName/ProcedureNameInput/ProcedureNameInput';
import { CustomProcedureNameValidation } from '@components/RequestForm/RequestFormValidation';
import { SurgeonDropdown } from '@components/RequestForm/inputs/SurgeonDropdown/SurgeonDropdown';

const useStyles = makeStyles(theme => ({
	documentTitle: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(1)
	},
	procedureName: {
		'display': 'flex',
		'marginBottom': theme.spacing(4),
		'width': '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		'& .MuiInputLabel-outlined': {
			color: theme.palette.common.black,
			fontSize: theme.typography.caption.fontSize,
		},
		'& > *': {
			flex: 1,
		},
		'& .MuiFormHelperText-root.Mui-error': {
			'fontWeight': theme.typography.fontWeightBold,
			'fontSize': theme.typography.caption.fontSize,
			'& > .MuiSvgIcon-root': {
				height: '18px',
				width: '18px',
			}
		}
	},
	surgeonDropdown: {
		'display': 'flex',
		'marginBottom': theme.spacing(4),
		'marginTop': theme.spacing(4),
		'width': '40%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		'& > *': {
			flex: 1,
		}
	}
}));

const DocumentsRequiredTitle: React.FC = () => {
	const classes = useStyles();
	const isDocRequired = useIsDocRequired();

	return <Box className={classes.documentTitle}>
		<Typography variant="h5" component="h2">Documents</Typography>
		{isDocRequired && <Typography variant="h5" color="error" component="span"> *</Typography>}
	</Box>;
};

const ComposedDocumentSections = () => {
	const classes = useStyles();

	return <>
		<CardSection sectionTitle={<CardSectionTitle title="Appointment Details" />} >
			<AppointmentDetails />
			<Box className={classes.surgeonDropdown}>
				<SurgeonDropdown dataField="requestForm-appointmentDetailsDocument-surgeonSelect" placeholder="Select an option" />
			</Box>
			<DiagnosisInput type="primary" />
			<CodeChipInput />
			<PreCertNumberInput type="Primary" />
			<Box className={classes.procedureName}>
				<ProcedureNameInput
					label="PROCEDURE"
					name="procedureName"
					controllerOptions={{
						rules: CustomProcedureNameValidation,
					}}
					dataField="requestForm-appointmentDetailsDocument-procedureNameTextInput"
				/>
			</Box>
			<FullPatientNameInput />
		</CardSection>
		<CardSection sectionTitle={<CardSectionTitle title={<DocumentsRequiredTitle />} />}>
			<DocumentRequest />
		</CardSection>
	</>;
};

export default ComposedDocumentSections;
