import React from 'react';
import { CommentModal } from '@components/CaseDetails/CommentsSection/CommentModal/CommentModal';
import { ADD_COMMENT_MODAL } from '@components/Modal/modalConstants';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { AddCaseCommentRequest } from '@data/caseComments/types/CaseComments.types';
import { useCommentsContext } from '@components/CaseDetails/CommentsSection/CommentsContext/CommentsContext';
import { useSelector } from 'react-redux';
import { RootState } from '@interfaces/rootState';
import { CaseDetailsState } from '@interfaces/CaseDetails/CaseDetailsState';
import { CaseDetailsSelector } from '@store/selectors/CaseDetailsSelector';
import {
	commentFormDefaultValues,
	CaseCommentForm
} from '@components/CaseDetails/CommentsSection/CommentModal/CaseCommentForm';
import { useDispatch } from 'react-redux';
import { addCaseComment } from '@data/caseComments/actionCreators/CaseCommentsActionCreators';

export const AddCommentModal = () => {
	const dispatch = useDispatch();
	const { closeModal } = useModalContext();
	const { getComments } = useCommentsContext();
	const { details } = useSelector<RootState, CaseDetailsState>(CaseDetailsSelector);

	const handleCancel = React.useCallback(() => {
		closeModal(ADD_COMMENT_MODAL);
	}, [closeModal]);

	const handleSaveComment = React.useCallback(({ comment, commentTypes }: CaseCommentForm) => {
		const payload: AddCaseCommentRequest = {
			comment,
			resourceId: details?.id || '',
			types: commentTypes,
		};

		dispatch(addCaseComment(payload, {
			onSuccess: () => {
				getComments({ resourceIds: [details?.id || ''] });
				closeModal(ADD_COMMENT_MODAL);
			},
		}));

	}, [closeModal, details?.id, dispatch, getComments]);
	return (
		<CommentModal
			id={ADD_COMMENT_MODAL}
			name="addCommentModal"
			title="Add Comment"
			onCancel={handleCancel}
			onSaveComment={handleSaveComment}
			defaultValues={commentFormDefaultValues}
		/>
	);
};
