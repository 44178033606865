import React from 'react';
import { StatefulTextInput } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInput';
import { OutlinedInputProps } from '@material-ui/core';
import { DurationValidation } from '@components/RequestForm/RequestFormValidation';

interface DurationProps  {
	label: string;
	inputProps?: OutlinedInputProps;
	dataField: string;
}

export const Duration = ({ label, inputProps, dataField }:  DurationProps) => {
	return (
		<StatefulTextInput
			controllerOptions={{
				rules: DurationValidation
			}}
			label={label}
			name="duration"
			textInputProps={{
				...inputProps,
			}}
			dataField={dataField}
		/>
	);
};
