import React, { ReactElement } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { makeStyles, TextareaAutosize } from '@material-ui/core';
import { StandardInputWrapper } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper';
import { StatefulTextAreaProps } from './StatefulTextArea.types';
import { TrackableEventType } from '@components/BaseEventTrackingWrapper/BaseTrackableEvents.types';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
	createStyles({
		errorBorder: {
			'border': `2px solid ${theme.palette.error.main}`,
			'height': '90px',
			'overflow': 'auto',
			'&:focus': {
				borderColor: theme.palette.error.main,
				outline: 'none'
			}
		}
	})
);

export function StatefulTextArea<FormType extends FieldValues>({
	controllerOptions = {},
	textAreaProps,
	...statefulProviderProps
}: StatefulTextAreaProps<FormType>): ReactElement {
	const texAreaRef = React.useRef<HTMLTextAreaElement>(null);
	const { field, formState: { errors } } = useController<FormType>({
		name: statefulProviderProps.name,
		...controllerOptions,
	});
	const classes = useStyles();
	const hasError = errors[statefulProviderProps.name];

	React.useEffect(() => {
		if (controllerOptions.defaultValue && !field.value) {
			field.onChange(controllerOptions.defaultValue);
		}
	}, [controllerOptions.defaultValue, field]);

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {
			field.onChange(event.target.value);
			textAreaProps?.onChange && textAreaProps?.onChange(event);
		},
		[field, textAreaProps],
	);

	return (
		<StandardInputWrapper<FormType>
			{...statefulProviderProps}
			labelProps={{
				id: `textArea-label-${statefulProviderProps.name}`,
				htmlFor: `textArea-field-${statefulProviderProps.name}`,
			}}
			analyticsInputName="textArea"
			eventsToTrack={[TrackableEventType.OnFocus, TrackableEventType.OnDrag]}
		>
			<TextareaAutosize
				{...field}
				{...textAreaProps}
				ref={texAreaRef}
				name={statefulProviderProps.name}
				className={`${textAreaProps?.className} ${hasError ? classes.errorBorder : ''}`}
				id={`textArea-field-${statefulProviderProps.name}`}
				value={textAreaProps?.value || field.value || controllerOptions.defaultValue || ''}
				onChange={handleChange}
				minRows={textAreaProps?.minRows || 6}
				maxRows={10}
				style={{ overflow: 'auto', padding: '16px', resize: 'vertical' }}
				required={statefulProviderProps.styleAsRequired}
			/>
		</StandardInputWrapper>
	);
}
