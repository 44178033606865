import { CaseComment, CaseCommentDetails, CaseCommentTypes } from '@data/caseComments/types/CaseComments.types';
import {
	mapCaseCommentTypeEnumToDisplay
} from '@components/CaseDetails/CommentsSection/utilities/mapCaseCommentTypeEnumToDisplay';
import { getFormattedName } from '@utilities/commonFunction';

export const formatCommentTypeString = (types: CaseCommentTypes[]): string => {
	return types
		.map((type) => mapCaseCommentTypeEnumToDisplay[type])
		.sort((a, b) => (a < b) ? -1 : 1)
		.join(', ');
};


export const decorateCommentDisplayDetails = (comments: CaseComment[]): CaseCommentDetails[] => {
	return comments.map((comment) => ({
		...comment,
		typesDisplay: formatCommentTypeString(comment.types),
		commenterDisplay: getFormattedName({ name: comment.metaData.commenterFirstName, lastName: comment.metaData.commenterLastName }),
	}));
};
