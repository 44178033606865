import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ColumnHeaderProps from '@components/SchedulePage/types/ColumnHeaderProps';
import BlockReleasedColumnHeader from '@components/SchedulePage/BlockReleasedColumnHeader/BlockReleasedColumnHeader';
import BlockUnreleasedColumnHeader
	from '@components/SchedulePage/BlockUnreleasedColumnHeader/BlockUnreleasedColumnHeader';
import { BlockReleaseState } from '@data/blockReleases/types/BlockReleaseState';

const useStyles = makeStyles( createStyles({
	blockText: {
		fontWeight: 600,
	},
}));

const BlockColumnHeader: React.FC<ColumnHeaderProps> = ({ blockInfo }) => {
	const classes = useStyles();

	const allBlocksManualReleased = React.useMemo(() => {
		return blockInfo?.blocks?.every(block => block.release?.state === BlockReleaseState.MANUAL_RELEASE);
	}, [blockInfo?.blocks]);

	//Can't simply test for truthy since 0 is valid
	const showUtilization = Number.isFinite(blockInfo.utilization)
		&& blockInfo.utilization >= 0
		&& blockInfo.utilization <= 100
		&& !allBlocksManualReleased; // hide utilization when all blocks have been manually released

	const BlockColumnHeaderComponent = blockInfo.isReleased ? BlockReleasedColumnHeader : BlockUnreleasedColumnHeader;

	return (
		<BlockColumnHeaderComponent blockInfo={blockInfo}>
			{showUtilization &&
				<Typography variant="body2" component="h3" className={classes.blockText}>
					{blockInfo.utilization.toFixed() + '% utilization'}
				</Typography>
			}
		</BlockColumnHeaderComponent>
	);
};

export default BlockColumnHeader;
