import { TableCell, TableCellProps, TableSortLabel } from '@material-ui/core';
import { SortOrderType } from '@components/ManageUsersList/SortOrderType';
import React, { MouseEvent } from 'react';
import { track } from '@amplitude/analytics-browser';

interface HeadCell<T> {
	sortableFieldName?: T;
	label: string;
}

interface TableSortableHeadCellProps<T> extends TableCellProps {
	headCell: HeadCell<T>;
	sortOrder: SortOrderType;
	orderBy: string;
	handleSortComments: (property: T) => void;
	sortLabelClassName?: string;
}

const handleTrackClick = (event: MouseEvent, fieldName: string, sortOder: string) => {
	const eventType = event.type;
	const eventName = `sortLabel-${eventType}`;

	track(eventName, { fieldName: `${fieldName}-${sortOder}`, eventType: eventType });
}

export function TableSortableHeadCell<T>({ headCell, sortOrder, orderBy, handleSortComments, sortLabelClassName, ...rest }: TableSortableHeadCellProps<T>) {
	const handleClick = React.useCallback((event: MouseEvent) => {
		const fieldName = headCell.sortableFieldName?.toString();
		headCell.sortableFieldName && handleSortComments(headCell.sortableFieldName);
		fieldName && handleTrackClick(event, fieldName, sortOrder);
	}, [handleSortComments, headCell.sortableFieldName, sortOrder]);

	return (
		<TableCell
			sortDirection={orderBy === headCell.sortableFieldName ? sortOrder : false}
			{...rest}
		>
			<TableSortLabel
				className={sortLabelClassName}
				active={orderBy === headCell.sortableFieldName}
				direction={orderBy === headCell.sortableFieldName ? sortOrder : SortOrderType.DESC}
				disabled={!headCell.sortableFieldName}
				onClick={headCell.sortableFieldName ? e => handleClick(e) : undefined}
			>
				{headCell.label}
			</TableSortLabel>
		</TableCell>
	);
}
