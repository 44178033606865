import React, { ReactNode } from 'react';
import { Box, Text, Button, Flex, Label } from '@ascension/web';
import CodeChipInput from './ProcedureFormFields/CodeChipInput';
import { ProcedureFormProps } from './ProcedureFormProps';
import ImplantsField from './ProcedureFormFields/ImplantsField';
import { useProcedureFormInfo } from './ProcedureFormContext/ProcedureFormContext';
import FormTextArea from '../FormComponents/FormTextArea';
import FormDropdown from '../FormComponents/FormDropdown';
import FormTextField from '../FormComponents/FormTextField';
import { FormMetaProvider } from '@store/context/FormMetaContext';
import { useFormContext } from 'react-hook-form';
import { SurgeryProcedureInterface } from '../RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import FormMultiDropdown from '../FormComponents/FormMultiDropdown';
import { Checkbox } from '@ascension/web';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { EquipmentMultiSelect } from '@components/ProcedureForm/inputs/EquipmentMultiSelect/EquipmentMultiSelect';

const useStyles = makeStyles(() =>
	createStyles({
		roboCheckbox: {
			maxWidth: 'fit-content',
		},
		modifiers: {
			'marginTop': '0',
			'gap': '16px',
			'& > div': {
				'flex': '1 1',
				'& > div:first-child > div:first-child > div:first-child > div:first-child': {
					'marginTop': '26px',
					'& ~ label': {
						marginTop: 0,
					},
				},
			},
		},
		equipmentMultiSelect: {
			'& > div:first-child > div:first-child': {
				width: '100%',
			},
		},
	}),
);

const ProcedureFormInner = ({
	children,
}: {
	children: ReactNode;
}) => {
	const { onSubmit, onCancel, shouldDisableSubmit, submitButtonText, headerText } =
		useProcedureFormInfo();
	const classes = useStyles();

	const {
		handleSubmit,
		watch,
		formState: { errors, submitCount },
	} = useFormContext<SurgeryProcedureInterface>();
	const isPrimaryProcedure = watch('isPrimaryProcedure');

	const currentValues = watch();
	const initialValues = React.useRef(currentValues);

	const disableSubmit = React.useMemo(
		() =>
			shouldDisableSubmit &&
			shouldDisableSubmit(errors, currentValues, initialValues.current),
		[errors, shouldDisableSubmit, currentValues],
	);

	const formError =
		submitCount > 0 && errors && Object.keys(errors).length > 0
			? 'There are errors in this page. Please review.'
			: undefined;
	const { currentValue: roboticCurrentValue, setCurrentValue: roboticSetCurrentValue } =
		useFieldFormContext<boolean>('robotic');
	const RoboticCheckboxLabel = (
		<p style={{ margin: 0 }}>
			<strong>Yes</strong>, this is a <strong>robotic procedure</strong>
		</p>
	);

	return (
		<>
			<Box mb={8}>
				<Text className="section-title" fontSize={24}>
					{headerText}
				</Text>
			</Box>
			{children}
			<FormDropdown
				name="surgeon"
				label="surgeon"
				placeholder="Select a Surgeon"
			/>
			{Boolean(isPrimaryProcedure) && (
				<FormTextField name="firstAssistant" label="First Assistant" />
			)}
			<CodeChipInput />
			<FormTextField
				name="procedureName"
				label="Procedure name"
				subLabel="Based on CPT code(s) entered"
				placeholder="Type procedure name"
			/>
			<Flex flexDirection="row" mt={8} flexWrap="wrap" className={classes.modifiers}>
				<FormDropdown label="Modifier" name="modifierSide" placeholder="Modifier" />
				<FormDropdown name="modifierApproach" placeholder="Approach" />
			</Flex>
			<Box mt={8} data-field="robotic" className={classes.roboCheckbox}>
				<Label>Is this a robotic procedure?</Label>
				<Checkbox
					name="robotic"
					label={RoboticCheckboxLabel}
					checked={roboticCurrentValue}
					onChange={roboticSetCurrentValue}
				/>
			</Box>
			<FormMultiDropdown name="anesthesiaType" label="Anesthesia Type" />
			<ImplantsField />
			<Box className={classes.equipmentMultiSelect}>
				<EquipmentMultiSelect />
			</Box>
			<FormTextArea name="otherEquipment" label="Other equipment and supplies" />
			<FormTextArea
				name="additionalComments"
				label="Additional procedure comments"
				mt="-12px"
			/>
			<hr />
			<Flex mt={8} justifyContent="flex-end" alignItems="center">
				<ErrorMessage errorField={formError} />
				<Button secondary onClick={onCancel} className="margin-right-10" id="cancel">
					Cancel
				</Button>
				<Button onClick={handleSubmit(onSubmit)} id="addSurgery" disabled={disableSubmit}>
					{submitButtonText}
				</Button>
			</Flex>
		</>
	);
};

const ProcedureForm = ({ children, ...rest }: ProcedureFormProps) => (
	<FormMetaProvider {...rest}>
		<ProcedureFormInner>{children}</ProcedureFormInner>
	</FormMetaProvider>
);

export default ProcedureForm;
