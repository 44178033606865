import React from 'react';
import { Box } from '@material-ui/core';
import RequestAuthStatusDropdown from '@components/RequestForm/inputs/RequestAuthStatusDropdown';

const RequestAuthStatus = () => {
	return (
		<Box
			width={[1, 1/2]}
			data-field="Appointment_RequestAuthStatus"
		>
			<RequestAuthStatusDropdown />
		</Box>
	);
};

export default RequestAuthStatus;
