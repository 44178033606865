import React from 'react';
import { Box, createStyles, Typography } from '@material-ui/core';
import { useGetBlockReleasesByUnit } from '@data/blockReleases/hooks/useGetBlockReleasesByUnit';
import { BlockReleaseState } from '@data/blockReleases/types/BlockReleaseState';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import TextLink from '@components/TextLink/TextLink';
import { useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import { HospitalInteractiveRoles } from '@interfaces/UserProfile/UserRoleEnum';
import { useHistory } from 'react-router-dom';
import { BaseEventTrackingWrapper } from '@components/BaseEventTrackingWrapper/BaseEventTrackingWrapper';
import { track } from '@amplitude/analytics-browser';
import { TrackableEventType } from '@components/BaseEventTrackingWrapper/BaseTrackableEvents.types';
import { useSsmUiFlags } from '@utilities/hooks/useSsmUiFlags/useSsmUiFlags';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		backgroundColor: theme.palette.primary[50],
		borderRadius: '12px',
		padding: theme.spacing(1.5, 2),
		marginTop: theme.spacing(4),
	},
	title: {
		fontWeight: 600,
		paddingLeft: theme.spacing(1),
	},
	subtext: {
		fontWeight: 400,
		paddingLeft: theme.spacing(4),
	},
	viewLink: {
		display: 'inline',
	},
}));

const dataField = 'caseList-blockReleaseReviewBanner-viewLink';

const eventsToTrack = [ TrackableEventType.OnClick, TrackableEventType.OnKeyDown ];

export const BlockReleaseReviewBanner = () => {
	const classes = useStyles();
	const { ssmShowReleaseBlock20241205 } = useSsmUiFlags();
	const hasHospitalRole = useSelector(UserProfileHasARoleSelector(HospitalInteractiveRoles));
	const history = useHistory();
	const { blockReleaseList } = useGetBlockReleasesByUnit(!hasHospitalRole || !ssmShowReleaseBlock20241205);

	const pendingReleaseCount = blockReleaseList.filter((blockRelease) => blockRelease.state === BlockReleaseState.PENDING_RELEASE).length;

	const handleTrackingEvent = (event: React.SyntheticEvent<HTMLElement>) => {
		const eventType = event.type;
		const eventName = `link-${eventType}`;

		track(eventName, { fieldName: dataField, eventType: eventType });
	};

	const handleClickView = () => {
		history.push('/blockrelease/review');
	};

	if (!pendingReleaseCount || !hasHospitalRole || !ssmShowReleaseBlock20241205) return null;

	return <Box className={classes.root}>
		<Box display="flex" justifyContent="space-between">
			<Box display="flex">
				<InfoIcon color="primary"/>
				<Typography className={classes.title}>
					{pendingReleaseCount} {pendingReleaseCount === 1 ? 'Block needs' : 'Blocks need'} to be released. Office Schedulers have requested early release of block.
				</Typography>
			</Box>
			<BaseEventTrackingWrapper
				dataField={dataField}
				handleEvent={handleTrackingEvent}
				eventsToTrack={eventsToTrack}
			>
				<TextLink onClick={handleClickView} className={classes.viewLink}>View</TextLink>
			</BaseEventTrackingWrapper>
		</Box>
		<Typography variant="body2" className={classes.subtext}>Review and release block in the EHR</Typography>
	</Box>;
};
