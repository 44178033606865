import { CaseCommentTypes } from '@data/caseComments/types/CaseComments.types';

export const mapCaseCommentTypeEnumToDisplay: Record<string, string> = {
	[CaseCommentTypes.APPOINTMENT_DETAILS]: 'Appointment Details',
	[CaseCommentTypes.AUTHORIZATION]: 'Authorization',
	[CaseCommentTypes.GENERAL]: 'General',
	[CaseCommentTypes.INSURANCE_INFO]: 'Insurance Information',
	[CaseCommentTypes.PATIENT_INFO]: 'Patient Information',
	[CaseCommentTypes.PRE_ADMIT_TESTING]: 'Pre-Admit Testing',
	[CaseCommentTypes.SURGERY_INFO]: 'Surgery Information',
};
