import { useHistory, Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { AscensionFullLogo } from '@components/SVGs/AscensionLogo/AscensionFullLogo';
import { HeaderSectionProps } from './HeaderSectionProps';
import { makeStyles } from '@material-ui/core/styles';
import useHasPermission from '../../utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';
import { APP_NAME } from '@utilities/constants';
import MultiPracticeDropdown from '@components/MultiPracticeDropdown/MultiPracticeDropdown';
import { AppBar, Box, Divider, Link } from '@material-ui/core';
import { useHomeNavigationContext } from './NavigationOptions/HomeNavigationContext/HomeNavigationContext';
import NavigationOptions from './NavigationOptions/NavigationOptions';
import GoldButton from '@components/GoldButton/GoldButton';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

const useStyles = makeStyles((theme) => ({
	appBar: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		alignItems: 'center',
		paddingLeft: theme.spacing(4.5),
		paddingRight: theme.spacing(5),
		height: '68px',
		background: 'linear-gradient(216.98deg,#1E69D2 0%,#1B4297 100%)',
	},
	logoContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	appName: {
		'fontSize': '18px',
		'lineHeight': theme.typography.subtext1?.lineHeight,
		'color': theme.palette.common.white,
		'&:hover': {
			textDecoration: 'none',
		},
		'cursor': 'pointer',
	},
	verticalDivider: {
		height: '2rem',
		background: theme.palette.common.white,
		display: 'block',
		marginLeft: theme.spacing(1.5),
		marginRight: theme.spacing(2),
	},
	dropDownButtonContainer: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto',
	},
	requestSurgeryButton: {
		height: '46px',
	}
}));

export default function HeaderSection({
	hideRequestButton,
	hideDropDown,
}: HeaderSectionProps) {
	const history = useHistory();
	const classes = useStyles();

	const hasCreateCasePermission = useHasPermission(
		PermittedAction.CREATE_CASE,
	);

	const displayRequestButton = hasCreateCasePermission && !hideRequestButton;

	const { homeRoute } = useHomeNavigationContext();

	return (
		<AppBar position="static" className={classes.appBar}>
			<Box id="app-logo-container" className={classes.logoContainer}>
				<AscensionFullLogo
					svgElmProps={{
						height: '49px',
						width: '164px',
					}}
				/>
				<Divider
					orientation="vertical"
					className={classes.verticalDivider}
				/>
				<Link
					data-section="HeaderSectionAppTitle"
					component={RouterLink}
					to={homeRoute}
					className={classes.appName}
				>
					{APP_NAME}
				</Link>
			</Box>

			<NavigationOptions />

			<Box className={classes.dropDownButtonContainer}>
				{!hideDropDown && <MultiPracticeDropdown />}
				{displayRequestButton && (
					<GoldButton
						onMouseDown={() => history.push(ROUTE_CONSTANTS.REQUEST_FORM)}
						id="requestSurgery"
						className={classes.requestSurgeryButton}
					>
						Request a Surgery
					</GoldButton>
				)}
			</Box>
		</AppBar>
	);
}
