import React from 'react';
import { StatefulTextArea } from '@components/StatefulInputs/StatefulTextArea/StatefulTextArea';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CaseCommentForm } from '../CaseCommentForm';
import { useFormContext } from 'react-hook-form';
import theme from '@theme/theme';
import { CaseCommentSchema } from '@utilities/Validation/validationSchema';

const useStyles = makeStyles(() =>
	createStyles({
		textArea: {
			width: '100%',
			borderRadius: theme.spacing(1),
			padding: theme.spacing(2),
			maxHeight: '240px',
			minHeight: '50px',
			boxSizing: 'border-box',
			minWidth: '100%',
			overflowY: 'auto',
			height: '90px',
			resize: 'vertical',
		},
		textAreaContainer: {
			'& .MuiBox-root': {
				display: 'flex',
			}
		}
	})
);

export const CommentTextArea = () => {
	const classes = useStyles();
	const { watch } = useFormContext<CaseCommentForm>();
	const comment = watch('comment');

	return (
		<StatefulTextArea
			label="Comment on this case"
			name="comment"
			styleAsRequired
			wrapperClassName={classes.textAreaContainer}
			dataField="caseDetailsPage-commentModal-comment"
			controllerOptions={{
				rules: CaseCommentSchema.comment,
				defaultValue: comment,
			}}
			textAreaProps={{
				className: classes.textArea,
				minRows: 6
			}}
		/>
	);
};
