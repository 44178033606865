import React from 'react';
import {
	BlockReleaseReviewCard
} from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewPage/BlockReleaseReviewCard/BlockReleaseReviewCard';
import {
	BlockReleaseReviewSection
} from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewPage/BlockReleaseReviewSection/BlockReleaseReviewSection';
import { makeStyles } from '@material-ui/core';
import { useBlockReleaseContext } from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewContext/BlockReleaseReviewContext';
import { BlockReleaseState } from '@data/blockReleases/types/BlockReleaseState';
import useSurgeonsSelectionByNpis
	from '@components/SchedulePage/utilities/useSurgeonsSelectionByNpis/useSurgeonsSelectionByNpis';

const useStyles = makeStyles((theme) => ({
	releasedSection: {
		'& > :first-child': {
			color: theme.palette.success.main,
			marginTop: theme.spacing(2.25),
		},
	}
}));

export const BlockReleaseReviewReleased = () => {
	const classes = useStyles();
	const { prefSurgeons } = useSurgeonsSelectionByNpis();

	const { blockReleaseList } = useBlockReleaseContext();

	const releasedReleases = React.useMemo(() =>
		blockReleaseList.filter((item) => item.state === BlockReleaseState.SSM_RELEASE),
	[blockReleaseList]);

	if (!releasedReleases.length) {
		return null;
	}

	return (
		<BlockReleaseReviewSection
			className={classes.releasedSection}
			sectionTitle="Released"
		>
			{releasedReleases.map((item) => (
				<BlockReleaseReviewCard key={item.id} blockReleaseDetails={item} prefSurgeons={prefSurgeons} />
			))}
		</BlockReleaseReviewSection>
	);
};
