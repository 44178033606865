import useGetSlots from '@data/schedules/hooks/useGetSlots';
import React from 'react';
import SlotResponse from '@data/schedules/types/SlotResponse';
import { AxiosResponse } from 'axios';
import useSlotRequestParams
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useSlotRequestParams/useSlotRequestParams';
import { format } from 'date-fns';
import { getDateFromApiDateTime } from '@calendar';
import { ScheduleColumnData } from '@data/schedules/types/ScheduleColumnData';

export const convertBlockColumnDataToSlotResponse = (scheduleColumnData: ScheduleColumnData) => {
	return scheduleColumnData.individualBlocks.map((schedule): Omit<SlotResponse, 'unit'> => ({
		id: schedule.id,
		start: schedule.start,
		end: schedule.end,
		type: schedule.type,
		name: schedule.name,
		owners: [scheduleColumnData.surgeon.id],
		releaseDate: schedule.releaseDate,
	}));
};

export interface UseRequestFormSlotsReturnType {
	slots: Omit<SlotResponse, 'unit'>[] | undefined;
	availableSlotsStr: string | undefined;
}

const useRequestFormSlots = (scheduleColumnData?: ScheduleColumnData): UseRequestFormSlotsReturnType => {
	const [slots, setSlots] = React.useState<Omit<SlotResponse, 'unit'>[]>();

	const slotRequestParams = useSlotRequestParams(true);
	const getSlots = useGetSlots();

	const filterAndSetSlots = React.useCallback((newSlots: Omit<SlotResponse, 'unit'>[]) => {
		const filteredSlots = newSlots.filter(slot => !slot.releaseDate || getDateFromApiDateTime(slot.releaseDate) > new Date());
		setSlots(filteredSlots);
	}, []);

	React.useEffect(() => {
		if (scheduleColumnData) {
			filterAndSetSlots(convertBlockColumnDataToSlotResponse(scheduleColumnData));
			return; // do not call API when we already have data from selected block column
		}

		void slotRequestParams.then((params) => {
			if (!params) { return; } // do not get slot data for validation

			const onSuccess = (response: AxiosResponse<SlotResponse[]>) => {
				filterAndSetSlots(response.data);
			};

			const onFailure = () => {
				// No need to show any error if API call fails because API call is not obvious to user
				// This is a convenience feature and the only consequence of failure is they could schedule into non-block time
				// If that happens, then the OR scheduler can help resolve through revision process
				filterAndSetSlots([]);
			};

			getSlots({
				data: params,
				onSuccess,
				onFailure,
			});
		});
	}, [filterAndSetSlots, getSlots, scheduleColumnData, slotRequestParams]);

	const availableSlotsStr = React.useMemo(() => {
		if (!slots) { return; }
		return slots.map(s =>
			`${format(getDateFromApiDateTime(s.start), 'h:mm a')} - ${format(getDateFromApiDateTime(s.end), 'h:mm a')}`
		).join(', ');
	}, [slots]);

	return {
		slots,
		availableSlotsStr,
	};
};

export default useRequestFormSlots;
