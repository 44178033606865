import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ModalViewer from '@components/Modal/ModalViewer';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DELETE_COMMENT_MODAL } from '@components/Modal/modalConstants';
import { useCommentsContext } from '@components/CaseDetails/CommentsSection/CommentsContext/CommentsContext';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { useDispatch } from 'react-redux';
import { deleteCaseComment } from '@data/caseComments/actionCreators/CaseCommentsActionCreators';
import { BaseButton } from '@components/BaseButton/BaseButton';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'display': 'flex',
			'flexDirection': 'column',
			'width': '600px',
			'padding': theme.spacing(2, 3, 3, 3),
			'& > *:not(:first-child):not(:nth-child(2))': {
				marginTop: theme.spacing(3)
			},
		},
		buttonContainer: {
			display: 'flex',
			gap: theme.spacing(2.5),
			marginLeft: 'auto',
			height: '48px',
		},
		message: {
			fontSize: '1.125rem',
			fontWeight: 600,
		},
		body: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(1),
		},
		bodyText: {
			fontSize: '1.125',
			fontWeight: 400,
		}
	}),
);

export const DeleteCommentModal = () => {
	const classes = useStyles();
	const { id, title, message } = DELETE_COMMENT_MODAL;
	const { selectedComment, getComments } = useCommentsContext();
	const { closeModal } = useModalContext();
	const dispatch = useDispatch();

	const handleCancelClick = () => {
		closeModal(id);
	};

	const handleDeleteCommentClick = React.useCallback(() => {
		if (!selectedComment) return;

		const OnSuccessDeleteComment = () => {
			getComments();
		};

		dispatch(deleteCaseComment({ commentId: selectedComment?.id }, { onSuccess: OnSuccessDeleteComment }));
		closeModal(id);
	}, [closeModal, dispatch, getComments, id, selectedComment]);

	return (
		<ModalViewer id={id} className={classes.root} dataField="caseDetailsPage-caseComments-deleteCommentModal">
			<Typography component="h2" variant="h6">
				{title}
			</Typography>
			<Typography className={classes.message}>
				{message}
			</Typography>
			<Box className={classes.body}>
				<Typography className={classes.bodyText}>
					Comment Type: {selectedComment?.typesDisplay}
				</Typography>
				<Typography className={classes.bodyText}>
					{selectedComment?.comment}
				</Typography>
			</Box>
			<Box className={classes.buttonContainer}>
				<BaseButton
					dataField="caseDetailsPage-deleteCommentModal-cancelButton"
					color="primary"
					variant="outlined"
					onClick={handleCancelClick}
				>
					Cancel
				</BaseButton>
				<BaseButton
					dataField="caseDetailsPage-deleteCommentModal-deleteCommentButton"
					color="destructive"
					variant="contained"
					onClick={handleDeleteCommentClick}
				>
					Delete Comment
				</BaseButton>
			</Box>
		</ModalViewer>
	);
};
