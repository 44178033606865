import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Surgeon } from '@data/surgeon/Surgeon';
import { ENDPOINT_SURGEONS } from '@utilities/apiConstants';
import { useMemo } from 'react';

export const useSurgeons = () => {
	const surgeons = useCollection<Surgeon>(ENDPOINT_SURGEONS);

	const [npis, surgeonByNpi] = useMemo(() => {
		const surgeonByNpiObj = surgeons.reduce<{ [key: string]: Surgeon }>((acc, surgeon) => {
			acc[surgeon.npi] = surgeon;
			return acc;
		}, {});

		const npiList = Object.keys(surgeonByNpiObj);

		return [npiList, surgeonByNpiObj];
	}, [surgeons]);

	return { surgeons, npis, surgeonByNpi };
};
