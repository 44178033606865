import React from 'react';
import { useDispatch } from 'react-redux';
import {
	PendingReleaseEventResponse
} from '@data/blockReleases/types/PendingReleaseEvent.types';
import {
	confirmBlockRelease
} from '@data/blockReleases/actionCreators/BlockReleaseActionCreators';
import { AxiosError } from 'axios';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '@utilities/toastConstants';
import {
	ConfirmPendingReleaseData
} from '@components/CaseTrackerList/BlockReleaseReviewBanner/ConfirmPendingReleaseInterface';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { useBlockReleaseContext } from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewContext/BlockReleaseReviewContext';

const errorCodeToMessage: Record<string, string> = {
	CS03: 'Block already released',
};
interface UseSubmitBlockReleaseApproval {
	id: string;
}

interface InlineError {
	message: string;
	code: string;
}

export const useSubmitBlockReleaseApproval = ({ id }: UseSubmitBlockReleaseApproval) => {
	const [inlineError, setInlineError] = React.useState<InlineError>({
		message: '',
		code: ''
	});
	const dispatch = useDispatch();
	const displayToast = useToast();
	const { closeModal } = useModalContext();
	const { getBlockReleases } = useBlockReleaseContext();

	const createBlockRelease = React.useCallback(
		(slotId: string, body: ConfirmPendingReleaseData) => {
			const onSuccess = () => {
				closeModal(id);
				getBlockReleases();
				displayToast(ToastType.SUCCESS, ToastConstants.BLOCK_RELEASE_CONFIRMED_SUCCESS);
			};
			const onFailure = (error: AxiosError<PendingReleaseEventResponse>) => {
				const errorCode = error.response?.data.code || '';
				if (error.response?.status === 422 && errorCodeToMessage[errorCode]) {
					setInlineError({
						message: error?.response?.data?.message,
						code: error?.response?.data?.code
					});
				}
			};

			dispatch(confirmBlockRelease(body, slotId, { onSuccess, onFailure }));
		},
		[dispatch, displayToast, closeModal, id, getBlockReleases],
	);

	return {
		createBlockRelease,
		inlineError,
		setInlineError
	};
};
