import React from 'react';
import {
	createStyles,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { formatInTimeZone } from 'date-fns-tz';
import { CaseCommentDetails } from '@data/caseComments/types/CaseComments.types';
import { CommentsTableHeader } from '@components/CaseDetails/CommentsSection/CommentsTable/CommentsTableHeader/CommentsTableHeader';
import { getDateFromApiDateTime } from '@calendar';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { CommentMenu } from '@components/CaseDetails/CommentsSection/CommentsTable/CommentMenu/CommentMenu';
import { useCommentsContext } from '@components/CaseDetails/CommentsSection/CommentsContext/CommentsContext';
import { CASE_COMMENT_EDITED_LABEL } from '@utilities/constants';

const useStyles = makeStyles((theme) =>
	createStyles({
		tableContainer: {
			'padding': theme.spacing(0),
			'width': 'unset',
			'& > table > tbody > tr:last-child > .MuiTableCell-root': {
				borderBottom: 'none',
			},
			'& > table > thead > tr > th': {
				'backgroundColor': theme.palette.primary[800],
				'borderBottomColor': 'rgba(0, 0, 0, 0.12)',
				'color': theme.palette.common.white,
				'fontWeight': 600,
				'letterSpacing': '0.15px',
				'lineHeight': '1.5rem',
				'paddingLeft': theme.spacing(2),
				'& > svg': {
					color: theme.palette.common.white,
				}
			},
			'& > table > tbody > tr > td': {
				borderBottomColor: 'rgba(33, 33, 33, 0.08)',
				fontWeight: 400,
				letterSpacing: '0.25px',
				lineHeight: '1.5rem',
				padding: theme.spacing(2),
			},
			'& > table > tbody > tr > td:first-child': {
				whiteSpace: 'nowrap',
			},
		},
		editText: {
			color: theme.palette.grey[600],
			fontSize: '0.8rem',
		},
	})
);

const formatDateAndTime = (dateTime: string, timeZone: string): string | null => {
	return formatInTimeZone(getDateFromApiDateTime(dateTime), timeZone, 'MM/dd/yyyy h:mm aa');
};

export const CommentsTable: React.FC = () => {
	const classes = useStyles();
	const { comments, setSelectedComment } = useCommentsContext();
	const caseInfo = useSelector(CaseDetailsInfoSelector);
	const { unit } = caseInfo;
	const { hospitalTimeZone } = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unit, 'id');

	const handleMenuClick = React.useCallback((comment: CaseCommentDetails) => {
		setSelectedComment(comment);
	}, [setSelectedComment]);

	return (
		<TableContainer className={classes.tableContainer}>
			<Table>
				<CommentsTableHeader />
				<TableBody>
					{comments?.length > 0 && comments
						.map((comment) => (
							<TableRow key={comment.id}>
								<TableCell>
									{hospitalTimeZone && formatDateAndTime(comment.createdDateTime, hospitalTimeZone)}
									{comment.status === 'EDITED' && (
										<Typography className={classes.editText}>{CASE_COMMENT_EDITED_LABEL}
										</Typography>)
									}
								</TableCell>
								<TableCell>
									{comment.typesDisplay}
								</TableCell>
								<TableCell>
									{comment.commenterDisplay}
								</TableCell>
								<TableCell>
									{comment.comment}
								</TableCell>
								<TableCell>
									{comment.isOwner && <CommentMenu onClick={()=> handleMenuClick(comment)}/>}
								</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
