import React from 'react';
import { Box, createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import classNames from 'classnames';

import { useBlockReleaseRequestContext } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestContext/BlockReleaseRequestContext';
import DisplayTimeFrame from '@components/DisplayTimeFrame/DisplayTimeFrame';
import { BaseButton } from '@components/BaseButton/BaseButton';
import { useHistory } from 'react-router-dom';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '@utilities/toastConstants';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';
import { BLOCK_RELEASE_REQUEST_CONFIRM_MODAL } from '@components/Modal/modalConstants';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { BlockReleaseRequestConfirmModal } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestConfirmModal/BlockReleaseRequestConfirmModal';
import {
	PartialReleaseSection
} from '@components/BlockReleaseRequestPage/BlockDetailsSection/PartialReleaseSection/PartialReleaseSection';
import { FormProvider, useForm } from 'react-hook-form';
import {
	PartialBlockReleaseRequestForm,
	PartialReleaseRequestDefaultValues
} from '@components/BlockReleaseRequestPage/BlockDetailsSection/PartialReleaseSection/PartialReleaseRequest.types';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			gridArea: 'blockDetails',
			padding: theme.spacing(3),
			borderRadius: theme.spacing(0.5),
			boxShadow: theme.shadows[9],
			maxHeight: 'fit-content',
			overflowY: 'auto'
		},
		sectionTitle: {
			fontSize: '1.5rem',
			marginBottom: theme.spacing(3),
		},
		itemTitle: {
			color: 'rgba(0, 0, 0, 0.6)',
			fontWeight: 325,
		},
		itemText: {
			margin: 0,
			marginBottom: theme.spacing(3),
			fontWeight: 600,
		},
		nameText: {
			fontSize: '1.25rem',
		},
		itemRow: {
			display: 'flex',
		},
		rowItem: {
			flex: '1 1 0',
		},
		error: {
			color: theme.palette.error.main,
		},
		warning: {
			color: theme.palette.warning.main,
		},
		success: {
			color: theme.palette.success.main,
		},
		keepBlockBtn: {
			padding: theme.spacing(0.875, 1),
		},
		releaseTimeBtn: {
			'&.Mui-disabled': {
				backgroundColor: theme.palette.grey[500],
				color: theme.palette.common.white,
			},
		},
	}),
);

export const BlockDetailsSection = () => {
	const classes = useStyles();
	const { releaseRequestInfo } = useBlockReleaseRequestContext();
	const history = useHistory();
	const displayToast = useToast();
	const { openModal } = useModalContext();

	const formMethods = useForm<PartialBlockReleaseRequestForm>({
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'all',
		defaultValues: PartialReleaseRequestDefaultValues,
	});

	const { formState: { errors } } = formMethods;

	if (!releaseRequestInfo?.blockInfo || !releaseRequestInfo.facility) return null;

	const { facility, blockInfo } = releaseRequestInfo;

	const name = blockInfo?.displayName;

	const formattedDate =
		blockInfo?.blocks?.length &&
		format(
			new Date(blockInfo.blocks[0].start.split(' ')[0] + ' 00:00:00'),
			'eeee, MMMM d, yyyy',
		);

	const timeZone = facility?.hospitalTimeZone ?? '';

	const timeFrame = blockInfo?.timeframes[0];

	const facilityName = facility?.name ?? '';
	const locationUnitString = blockInfo?.blocks?.length ? ` / ${blockInfo.blocks[0].unit}` : '';
	const location = `${facilityName}${locationUnitString}`;

	const utilization = blockInfo?.utilization ?? 0;

	const handleKeepBlockClick = () => {
		displayToast(ToastType.SUCCESS, ToastConstants.BLOCK_KEPT);
		history.push(ROUTE_CONSTANTS.SCHEDULING, { disableClearToast: true });
	};

	const handleReleaseTimeClick = () => {
		openModal(`${BLOCK_RELEASE_REQUEST_CONFIRM_MODAL.id}${blockInfo?.id}`);
	};

	const hasValidationErrors = !!errors['startTime'] || !!errors['endTime'];

	return (
		<>
			<FormProvider {...formMethods}>
				<BlockReleaseRequestConfirmModal
					modalValues={BLOCK_RELEASE_REQUEST_CONFIRM_MODAL}
					blockReleaseInfo={blockInfo}
					facilityInfo={facility}
				/>
				<BoxFancyScroll className={classes.root}>
					<Typography component="h2" variant="h2" className={classes.sectionTitle}>
						Block Details
					</Typography>

					<Typography component="h3" className={classes.itemTitle}>
						Surgeon Name
					</Typography>
					<Typography className={`${classes.itemText} ${classes.nameText}`}>
						{name}
					</Typography>

					<Typography component="h3" className={classes.itemTitle}>
						Date
					</Typography>
					<Typography className={classes.itemText}>{formattedDate}</Typography>

					<Typography component="h3" className={classes.itemTitle}>
						Block time to release
					</Typography>
					{timeFrame && (
						<DisplayTimeFrame
							className={classes.itemText}
							timeZone={timeZone}
							timeFrame={timeFrame}
						/>
					)}

					<Typography component="h3" className={classes.itemTitle}>
						Location
					</Typography>
					<Typography className={classes.itemText}>{location}</Typography>

					<Box className={classes.itemRow}>
						<Box className={classes.rowItem}>
							<Typography component="h3" className={classes.itemTitle}>
								Available (approx.)
							</Typography>
							<Typography className={classes.itemText}>
								{blockInfo?.availableTimeDisplay}
							</Typography>
						</Box>

						<Box className={classes.rowItem}>
							<Typography component="h3" className={classes.itemTitle}>
								Utilization
							</Typography>
							<Typography
								className={classNames(classes.itemText, {
									[classes.success]: utilization >= 75,
									[classes.warning]: utilization >= 50 && utilization < 75,
									[classes.error]: utilization < 50,
								})}
							>
								{utilization}%
							</Typography>
						</Box>
					</Box>
					<PartialReleaseSection />
					<Box className={classes.itemRow} justifyContent="space-between">
						<BaseButton
							dataField="blockReleaseRequestPage-blockDetails-keepBlockBtn"
							variant="outlined"
							color="primary"
							className={classes.keepBlockBtn}
							onClick={handleKeepBlockClick}
						>
							Keep Block
						</BaseButton>
						<BaseButton
							className={classes.releaseTimeBtn}
							dataField="blockReleaseRequestPage-blockDetails-releaseTimeBtn"
							variant="contained"
							color="primary"
							onClick={handleReleaseTimeClick}
							disabled={hasValidationErrors}
						>
							Release Time
						</BaseButton>
					</Box>
				</BoxFancyScroll>
			</FormProvider>
		</>
	);
};
