import React from 'react';
import { useDispatch } from 'react-redux';

import {
	PendingReleaseEventRequest,
	PendingReleaseEventResponse
} from '@data/blockReleases/types/PendingReleaseEvent.types';
import {
	createPendingReleaseEvent as createPendingReleaseEventAction
} from '@data/blockReleases/actionCreators/BlockReleaseActionCreators';
import { AxiosError, AxiosResponse } from 'axios';
import { ENDPOINT_BLOCK_RELEASES_SLOTS } from '@utilities/apiConstants';
import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '@utilities/toastConstants';
import {
	shouldDisableErrorHandlerByCode
} from '@utilities/shouldDisableErrorHandlerByCode/shouldDisableErrorHandlerByCode';

const errorCodeToMessage: Record<string, string> = {
	CS03: 'Block already released',
	CS04: 'Release already requested',
};

export const useCreatePendingReleaseEvent = () => {
	const [pendingReleaseEvent, setPendingReleaseEvent] =
		React.useState<PendingReleaseEventResponse>();
	const [inlineError, setInlineError] = React.useState<string>();
	const dispatch = useDispatch();
	const history = useHistory();
	const displayToast = useToast();

	const createPendingReleaseEvent = React.useCallback(
		(slotId: string, body: PendingReleaseEventRequest) => {
			const url = `${ENDPOINT_BLOCK_RELEASES_SLOTS}/${slotId}`;

			const onSuccess = (response: AxiosResponse<PendingReleaseEventResponse>) => {
				setPendingReleaseEvent(response.data);
				displayToast(ToastType.SUCCESS, ToastConstants.BLOCK_RELEASE_REQUESTED);
				history.push(ROUTE_CONSTANTS.SCHEDULING, { disableClearToast: true });
			};

			const onFailure = (error: AxiosError<PendingReleaseEventResponse>) => {
				if (error.response?.status !== 422 || !['CS03', 'CS04'].includes(error.response.data.code)) return;
				setInlineError(errorCodeToMessage[error.response?.data.code]);
			};

			dispatch(createPendingReleaseEventAction(body, {
				onSuccess,
				onFailure,
				url,
				shouldDisableErrorHandler: shouldDisableErrorHandlerByCode(['CS03', 'CS04'])
			}));
		},
		[dispatch, displayToast, history],
	);

	return {
		pendingReleaseEvent,
		createPendingReleaseEvent,
		inlineError
	};
};
