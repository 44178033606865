import { RegisterOptions } from 'react-hook-form';
import {
	CUSTOM_PROCEDURE_NAME_REQUIRED, INVALID_PROCEDURE_DURATION,
	PRIMARY_PROCEDURE_NAME_NOT_SELECTED,
	PROCEDURE_DURATION_MINIMUM,
} from '@utilities/Validation/ValidationMessages';
import { INVALID_DATE, INVALID_FUTURE_DATE } from '@utilities/Validation/ValidationMessages';
import { validators } from '@utilities/Validation/validators';
import { dateParse } from '@utilities/dateUtilities';

const dateValidator = (testDate: Date) => {
	return testDate && validators.dateValidator(dateParse(testDate)[0]) === null || INVALID_DATE;
};

export const UniversalRequestedDateValidation: RegisterOptions = {
	required: INVALID_DATE,
	validate: {
		validDate: dateValidator,
	}
};

export const NewRequestedDateValidation: RegisterOptions = {
	...UniversalRequestedDateValidation,
	validate: {
		...UniversalRequestedDateValidation.validate,
		futureDate: (testDate: Date) => {
			return testDate && new Date(testDate).getTime() > new Date().getTime() || INVALID_FUTURE_DATE;
		}
	}
};

export const ProcedureNameValidation: Partial<RegisterOptions> = {
	required: PRIMARY_PROCEDURE_NAME_NOT_SELECTED,
};

export const CustomProcedureNameValidation: Partial<RegisterOptions> = {
	required: CUSTOM_PROCEDURE_NAME_REQUIRED,
};

export const DurationValidation: Partial<RegisterOptions> = {
	min: {
		value: 30,
		message: PROCEDURE_DURATION_MINIMUM,
	},
	validate:{
		pattern: (val: string) => {
			if (!val) {
				return true;
			}
			if (!/^\d*$/.test(val)) {
				return  INVALID_PROCEDURE_DURATION;
			} else return true;
		}
	},
};

