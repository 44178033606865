import React from 'react';
import ModalViewer from '@components/Modal/ModalViewer';
import { Box, Typography } from '@material-ui/core';
import { Button } from '@ascension/ui-library';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommentTypeSection } from '@components/CaseDetails/CommentsSection/CommentModal/CommentTypeSection/CommentTypeSection';
import { FormProvider, useForm } from 'react-hook-form';
import { CaseCommentForm } from '@components/CaseDetails/CommentsSection/CommentModal/CaseCommentForm';
import { CommentTextArea } from '@components/CaseDetails/CommentsSection/CommentModal/CommentTextArea/CommentTextArea';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'display': 'flex',
			'flexDirection': 'column',
			'width': '600px',
			'padding': theme.spacing(2, 3, 3, 3),
			'& > *:not(:first-child):not(:nth-child(2))': {
				marginTop: theme.spacing(3)
			},
		},
		buttonContainer: {
			display: 'flex',
			gap: theme.spacing(2.5),
			marginLeft: 'auto',
			height: '48px',
		},
		requiredText: {
			color: theme.palette.error.main,
		},
	}),
);

interface CommentModalProps {
	id: string;
	name: string;
	title: string;
	onCancel: () => void;
	onSaveComment: (formData: CaseCommentForm) => void;
	defaultValues: CaseCommentForm;
}

export const CommentModal = ({ id, name, title, onCancel, onSaveComment, defaultValues }: CommentModalProps) => {
	const classes = useStyles();
	const formMethods = useForm<CaseCommentForm>({
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
		criteriaMode: 'all',
		defaultValues,
		shouldUnregister: true,
	});

	const handleCancel = React.useCallback(() => {
		formMethods.reset();
		onCancel();
	}, [formMethods, onCancel]);

	return (
		<ModalViewer id={id} className={classes.root}>
			<FormProvider {...formMethods}>
				<Typography component="h2" variant="h6">
					{title}
				</Typography>
				<Typography className={classes.requiredText}>
					* Indicates required fields
				</Typography>
				<CommentTypeSection />
				<CommentTextArea />
				<Box className={classes.buttonContainer}>
					<Button
						data-field={`caseDetailsPage-${name}-cancelButton`}
						color="primary"
						variant="outlined"
						onClick={handleCancel}
					>
						Cancel
					</Button>
					<Button
						data-field={`caseDetailsPage-${name}-saveCommentButton`}
						onClick={formMethods.handleSubmit(onSaveComment)}
						color="primary"
						variant="contained"
					>
						Save Comment
					</Button>
				</Box>
			</FormProvider>
		</ModalViewer>
	);
};
