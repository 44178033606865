import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
	SurgeryProcedureInterface,
	SurgeryProcedureProps
} from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import ProcedureForm from '@components/ProcedureForm/ProcedureForm';
import { getSurgeonListData } from '@store/actions/SurgeonListActionCreators';
import { PreferredSurgeonsSelector } from '@store/selectors/SurgeonListSelector';
import { ProcedureFormProvider } from '@components/ProcedureForm/ProcedureFormContext/ProcedureFormContext';
import useProcedureFormOptions from '@components/ProcedureForm/useProcedureFormOptions/useProcedureFormOptions';
import { ENDPOINT_SURGEONS } from '@utilities/apiConstants';
import { getFormattedName } from '@utilities/commonFunction';
import { RequestProcedureSchema } from '@utilities/Validation/validationSchema';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Surgeon } from '@data/surgeon/Surgeon';
import FormYesNoRadio from '@components/FormComponents/FormYesNoRadio';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DropdownOption } from '@definitions/DropdownOption';
import {
	useSetPrimaryProcedureNameAndSurgeon
} from '@components/RequestForm/utilities/hooks/useSetPrimaryProcedureNameAndSurgeon/useSetPrimaryProcedureNameAndSurgeon';

const useStyles = makeStyles((theme) => createStyles({
	procedureTitle: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: theme.spacing(2),
	},
	templateName: {
		fontWeight: 600,
	}
}));

const defaultRequestProcedureInfo: SurgeryProcedureInterface = {
	surgeon: { label: '', value: '' },
	cptCodes: { currentEntry: '', entriesList: [] },
	procedureName: '',
	modifierSide: {
		label: '',
		value: '',
	},
	modifierApproach: {
		label: '',
		value: '',
	},
	isPrimaryProcedure: null,
	anesthesiaType: [],
	implantsNeeded: null,
	otherEquipment: '',
	additionalComments: '',
	vendorContacted: null,
	vendorName: '',
	templateName: '',
	robotic: null,
	firstAssistant: '',
};

// Necessary for edit requests which are missing the label for the surgeon
const useUpdateSurgeonLabel = ({ getValues, setValue }: UseFormReturn<SurgeryProcedureInterface>) => {
	const surgeons = useCollection<Surgeon>(ENDPOINT_SURGEONS);
	const surgeonRef = React.useRef<DropdownOption>(getValues()['surgeon'] || {});

	React.useEffect(() => {
		const { label, value } = surgeonRef.current;
		if (label || !value) { return; }
		setValue('surgeon', { value: value, label: getFormattedName(surgeons.find((surgeon) => (surgeon.id === value), '')) }, { shouldValidate: false });
	}, [setValue, surgeons]);
};

const RequestSurgeryProcedureForm = ({ surgeryProcedure = defaultRequestProcedureInfo, setSurgeryProcedure, surgeryProcedureIndex, close, validate }: SurgeryProcedureProps) => {
	const classes = useStyles();
	const availableOptions = useProcedureFormOptions(PreferredSurgeonsSelector);

	const formMethods = useForm<SurgeryProcedureInterface>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		defaultValues: surgeryProcedure,
	});

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(getSurgeonListData());
	}, [dispatch]);

	useUpdateSurgeonLabel(formMethods);

	const handleSetPrimaryProcedureNameAndSurgeon = useSetPrimaryProcedureNameAndSurgeon(formMethods);

	const templateName = 'templateName' in surgeryProcedure ? surgeryProcedure.templateName : undefined;

	const handleSubmitValidated = React.useCallback((submittedVals: SurgeryProcedureInterface & { templateId?: string }) => {
		const procedureToAdd = { ...submittedVals };
		if ('templateId' in surgeryProcedure) {
			procedureToAdd.templateId = surgeryProcedure.templateId;
		}
		setSurgeryProcedure && setSurgeryProcedure((value: Array<SurgeryProcedureInterface> = []) => {
			const currentProcedureValue: number = surgeryProcedureIndex === undefined ? value.length : surgeryProcedureIndex;
			return [
				...value.slice(0, currentProcedureValue),
				procedureToAdd,
				...value.slice(currentProcedureValue + 1)
			];
		});
		close();
		validate && validate();
	}, [close, setSurgeryProcedure, surgeryProcedure, surgeryProcedureIndex, validate]);

	return (
		<ProcedureFormProvider
			onCancel={close}
			onSubmit={handleSubmitValidated}
			submitButtonText="Add"
			headerText="Add a Procedure"
		>
			<ProcedureForm schema={RequestProcedureSchema} options={availableOptions} {...formMethods} >
				<>
					{templateName ?
						<Box data-field="procedureForm-formHeader-templateName" className={classes.procedureTitle}>
							<Typography className={classes.templateName} variant="caption">TEMPLATE NAME</Typography>
							<Typography variant="h6">{templateName}</Typography>
						</Box> : undefined
					}
					<FormYesNoRadio
						name="isPrimaryProcedure"
						label="Primary Procedure?"
						onChangeExternal={handleSetPrimaryProcedureNameAndSurgeon}
					/>
				</>
			</ProcedureForm>
		</ProcedureFormProvider>
	);
};

export default RequestSurgeryProcedureForm;
