import { utcToTimezone } from '@utilities/dateUtilities';

const checkPartialRelease = (
	startDateTime: string,
	endDateTime: string,
	hospitalTimeZone: string,
	blockStartTime: string,
	blockEndTime: string,
): boolean => {
	const utcStartTime = utcToTimezone(startDateTime, hospitalTimeZone, 'HH:mm');
	const utcEndTime = utcToTimezone(endDateTime, hospitalTimeZone, 'HH:mm');

	if (utcStartTime === blockStartTime && utcEndTime === blockEndTime) {
		return false;
	}

	return utcStartTime >= blockStartTime || utcEndTime <= blockEndTime;
};

export default checkPartialRelease;
