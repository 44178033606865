import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import {
	CaseCommentsApiResponse,
	CaseComment,
	CaseCommentsRequest
} from '@data/caseComments/types/CaseComments.types';
import { getCaseComments } from '@data/caseComments/actionCreators/CaseCommentsActionCreators';
import { RootState } from '@interfaces/rootState';
import { CaseDetailsState } from '@interfaces/CaseDetails/CaseDetailsState';
import { CaseDetailsSelector } from '@store/selectors/CaseDetailsSelector';

export const useGetCaseComments = () => {
	const [comments, setComments] = useState<CaseComment[]>([]);
	const { details } = useSelector<RootState, CaseDetailsState>(CaseDetailsSelector);
	const dispatch = useDispatch();

	const getComments = useCallback((body?: CaseCommentsRequest) => {
		const requestBody = body ?? {
			resourceIds: details ? [
				details.id,
				...(details.caseNumber ? [details.caseNumber] : [])
			] : []
		};

		if (!requestBody.resourceIds.length) return;

		dispatch(getCaseComments(requestBody, {
			onSuccess: (response: AxiosResponse<CaseCommentsApiResponse>) => {
				setComments(response.data.comments);
			}
		}));
	}, [details, dispatch]);

	return { comments, getComments };
};
