import React from 'react';
import { useGetCaseComments } from '@data/caseComments/hooks/useGetCaseComments';
import { CommentsContextType } from '@components/CaseDetails/CommentsSection/CommentsContext/CommentsContextType.types';
import {
	CaseCommentDetails,
	SortableCaseCommentFields
} from '@data/caseComments/types/CaseComments.types';
import { SortOrderType } from '@components/ManageUsersList/SortOrderType';
import { sortObjectsByField } from '@utilities/sortObjectsByField/sortObjectsByField';
import { decorateCommentDisplayDetails } from '@components/CaseDetails/CommentsSection/utilities/commentUtils';
import { DEFAULT_SELECTION_ALL } from '@utilities/constants';
import {
	filterCommentsByType
} from '@components/CaseDetails/CommentsSection/utilities/filterCommentsByType/filterCommentsByType';

const CommentsContext = React.createContext<null | CommentsContextType>(null);

export const useCommentsContext = (): CommentsContextType => {
	const ctx = React.useContext(CommentsContext);
	if (!ctx) {
		throw new Error('useCommentsContext must be used within a CommentsProvider');
	}
	return ctx;
};

export const CommentsProvider: React.FC = ({ children }) => {
	const { comments, getComments } = useGetCaseComments();
	const [selectedComment, setSelectedComment] = React.useState<CaseCommentDetails | undefined>();
	const [typeFilter, setTypeFilter] = React.useState<string>(DEFAULT_SELECTION_ALL);
	const [sortOrder, setSortOrder] = React.useState<SortOrderType>(SortOrderType.DESC);
	const [orderBy, setOrderBy] = React.useState<SortableCaseCommentFields>('createdDateTime');

	const handleSortComments = React.useCallback((property: SortableCaseCommentFields) => {
		const isDesc = orderBy === property && sortOrder === SortOrderType.DESC;
		setSortOrder(isDesc ? SortOrderType.ASC : SortOrderType.DESC);
		setOrderBy(property);
	}, [orderBy, sortOrder]);

	const sortedAndFilteredCommentsWithDisplayDetails = React.useMemo(() =>
		filterCommentsByType(decorateCommentDisplayDetails(comments).sort(sortObjectsByField(orderBy, sortOrder === SortOrderType.DESC)), typeFilter)
	, [comments, orderBy, sortOrder, typeFilter]);

	React.useEffect(() => {
		getComments();
	}, [getComments]);

	return (
		<CommentsContext.Provider
			value={{
				comments: sortedAndFilteredCommentsWithDisplayDetails,
				getComments,
				setSelectedComment,
				selectedComment,
				sortOrder,
				orderBy,
				handleSortComments,
				setTypeFilter,
				typeFilter,
			}}
		>
			{children}
		</CommentsContext.Provider>
	);
};
