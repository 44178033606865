import { BlockReleasesListResponse } from '@data/blockReleases/types/BlockReleasesListResponse';
import { sortObjectsByField } from '@utilities/sortObjectsByField/sortObjectsByField';

export const sortBlockReleases = (blockReleases: BlockReleasesListResponse[]): BlockReleasesListResponse[] => {
	if (!blockReleases.length) {
		return [];
	}

	return blockReleases.sort((a, b) => {
		return (
			sortObjectsByField('startDateTime')(a, b) ||
			sortObjectsByField('blockStartTime')(a, b) ||
			sortObjectsByField('slotName')(a, b)
		);
	});
};
