import React, { ChangeEvent } from 'react';
import { Box, Flex, Text, TextLink } from '@ascension/web';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../interfaces/rootState';
import { LogOutIcon } from '@components/SVGs/LogOutIcon/LogOutIcon';
import { getNavigateToIdProvider } from '../../../utilities/commonFunction';
import {
	UserProfileDetailsSelector,
	UserProfileHasARoleSelector,
	UserProfilePrimaryHospitalUnits
} from '../../../store/selectors/UserProfileSelector';
import { OfficeRoles } from '../../../interfaces/UserProfile/UserRoleEnum';
import { UserDetailsApiInterface } from '../../../interfaces/UserPrefInterface';
import { Unit } from '../../../data/unit/Unit';
import usePageEffects from '../../../utilities/hooks/usePageEffects/usePageEffects';
import { useCommonStyles } from '../../../utilities/hooks/useCommonStyles/useCommonStyles';
import { Button, TextField } from '@ascension/ui-library';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useFieldFormContext } from '../../../utilities/hooks/useFieldFormContext/useFieldFormContext';
import { PatientDetailsValidationSchema } from '../../RequestForm/PatientDetails/PatientDetailsValidationSchema';
import { makeStyles } from '@material-ui/core/styles';
import { updateUserProfileEmail } from '../../../store/actions/UserProfileActionCreators';
import { useToast } from '../../../utilities/hooks/useToast/useToast';
import { ToastType } from '../../../interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '../../../utilities/toastConstants';
import { INVALID_EMAIL } from '../../../utilities/Validation/ValidationMessages';

const useStyles = makeStyles(theme => ({
	emailInput: {
		'width': theme.spacing(38),
		'& > .MuiFormLabel-root.MuiInputLabel-root': {
			fontSize: '12px',
			color: '#999999',
			letterSpacing: '1px',
			textTransform: 'uppercase',
		},
		'& > .MuiFormHelperText-root.Mui-error': {
			margin: 0,
		},
	},
	cancelBtn: {
		width: theme.spacing(10),
		marginTop: theme.spacing(4),
	},
	saveBtn: {
		'width': theme.spacing(10),
		'marginLeft': theme.spacing(2),
		'marginTop': theme.spacing(4),
		'backgroundColor': theme.palette.gold.light,
		'&:hover': {
			backgroundColor: theme.palette.gold.dark,
		},
	},
}));

interface MyInformationInnerProps {
	isReview?: boolean;
}

const MyInformationValidationSchema = {
	email: {
		...PatientDetailsValidationSchema.patientEmail,
		required: INVALID_EMAIL,
	},
};

function MyInformationInner ({ isReview }: MyInformationInnerProps)  {
	const classes = useStyles();
	const {
		firstName,
		lastName,
		email: initialEmail,
		phone,
		middleName,
		ext,
		primaryPractice
	} = useSelector(((state: RootState) => state.userProfile.userProfileDetails) as ((r: RootState) => UserDetailsApiInterface));
	const url = getNavigateToIdProvider();
	const isOffice = useSelector(UserProfileHasARoleSelector(OfficeRoles));
	const units = useSelector(UserProfilePrimaryHospitalUnits) as Unit[];

	const {
		currentValue: email,
		setCurrentValue: setEmail,
		error: emailError,
		triggerSelfValidation: triggerEmailSelfValidation,
	} = useFieldFormContext<string>('email', {
		validationRules: MyInformationValidationSchema.email,
		triggerValidationOnChange: false
	});

	const { handleSubmit } = useFormContext();
	const dispatch = useDispatch();
	const displayToast = useToast();

	const handleChangeEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setEmail(event.target.value);
	};

	const handleCancel = () => {
		setEmail(initialEmail);
		void triggerEmailSelfValidation();
	};

	const handleSave = React.useCallback(() => {
		const onSuccess = () => displayToast(ToastType.SUCCESS, ToastConstants.SAVE_SUCCESS);
		const onFailure = () => displayToast(ToastType.ERROR, ToastConstants.SAVE_ERROR);

		const payload = {
			email: email,
		};
		dispatch(updateUserProfileEmail(payload, onSuccess, onFailure));
	}, [dispatch, displayToast, email]);

	const handleResetClick = () => {
		sessionStorage.clear();
		window.location.assign(url);
	};
	const { upperCase } = useCommonStyles();

	usePageEffects('My Information');

	const changePassword = (
		<Flex flexDirection="column" justifyContent="space-between" width={1} alignItems="start" data-field="changePassword" key="changePassword">
			<Flex flexDirection="row" justifyContent="space-between" pb={4}>
				<TextLink onClick={handleResetClick} id="changePassword" fontSize="12px" display="inline">CHANGE PASSWORD</TextLink>
				<LogOutIcon/>
			</Flex>
			<Text letterSpacing={1} color="#999999" fontSize="14px">
				This will take you to change your Ascension ID password.
			</Text>
		</Flex>
	);

	return (
		<Flex flexDirection="row" justifyContent="center" alignItems="start">
			<Box
				className="card"
				width={1}
				ml="24px"
				borderRadius="4px"
			>
				<Flex flexDirection="column" px={40} py={40}>
					<form onSubmit={handleSubmit(handleSave)}>
						<Flex justifyContent="space-between" width={1} alignItems="start" pb={15}>
							<Flex flexDirection="row">
								<Box>
									<Text fontSize={7} letterSpacing={1} fontWeight="bold">
										My Information
									</Text>
								</Box>
							</Flex>
						</Flex>
						<Flex flexDirection="column" justifyContent="space-between" width={1} alignItems="start" pb={8} data-field="fullName">
							<Text letterSpacing={2} color="#999999" className={upperCase} fontSize="12px" pb={4} fontWeight="bold">
								Full Name
							</Text>
							<Text>
								{firstName} {middleName} {lastName}
							</Text>
						</Flex>
						<Flex flexDirection="column" justifyContent="space-between" width={1} pb={8} alignItems="start" data-field="phoneNumber">
							<Text letterSpacing={2} color="#999999" className={upperCase} fontSize="12px" pb={4} fontWeight="bold">
								Office Phone Number
							</Text>
							<Text>
								{phone} <span data-field ="ext">{ext != null ? `Ext.${ext}`: null}</span>
							</Text>
						</Flex>
						<Flex flexDirection="column" justifyContent="space-between" width={1} pb={8} alignItems="start" data-field="email">
							<TextField
								translate="yes"
								type="text"
								variant="static"
								label="Preferred Email Address"
								data-field="preferredEmailInput"
								required
								className={classes.emailInput}
								value={email}
								onChange={handleChangeEmail}
								onBlur={triggerEmailSelfValidation}
								error={!!emailError}
								helperText={emailError}
							/>
						</Flex>
						{isOffice &&
							<Flex flexDirection="column" justifyContent="space-between" width={1} alignItems="start" pb={15} data-field="practiceNameInfo" key="practiceNameInfo">
								<Text letterSpacing={2} color="#999999" className={upperCase} fontSize="12px" pb={4} fontWeight="bold">
									Practice Name
								</Text>
								<Text>
									{primaryPractice && primaryPractice.name}
								</Text>
							</Flex>
						}
						{!isOffice &&
							<Flex flexDirection="column" justifyContent="space-between" width={1} alignItems="start" pb={15} data-field="unitInfo">
								<Text letterSpacing={2} color="#999999" className={upperCase} fontSize="12px" pb={4} fontWeight="bold">
								Unit Names
								</Text>
								{units.map((unit: Unit, index: number) => (
									<Text key={unit.id} data-field="unitList">
										{unit.name}{units.length - 1 !==  index ? `, ` : null}
									</Text>
								))}
							</Flex>
						}
						{!isReview && changePassword}
						<Box display="flex" justifyContent="flex-end">
							<Button
								id="cancelBtn"
								translate="yes"
								variant="outlined"
								data-field="cancelBtn"
								onClick={handleCancel}
								disabled={email === initialEmail}
								className={classes.cancelBtn}
								color="primary"
							>
								Cancel
							</Button>
							<Button
								id="saveBtn"
								type="submit"
								translate="yes"
								variant="contained"
								data-field="saveBtn"
								disabled={email === initialEmail}
								className={classes.saveBtn}
							>
								Save
							</Button>
						</Box>
					</form>
				</Flex>
			</Box>
		</Flex>
	);
}

export default function MyInformation({ isReview }: {isReview?: boolean}) {
	const { email } = useSelector(UserProfileDetailsSelector);
	const formMethods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		criteriaMode: 'all',
		defaultValues: { email: email },
	});

	return (
		<FormProvider {...formMethods}>
			<MyInformationInner isReview={isReview}/>
		</FormProvider>
	);
}
