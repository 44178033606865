import React from 'react';
import { makeStyles } from '@material-ui/core';
import MainPageLayoutContainer from '@components/MainPageLayout/presentationalComponents/MainPageLayoutContainer';
import MainPageLayoutBody from '@components/MainPageLayout/presentationalComponents/MainPageLayoutBody';
import { LEGACY_MAX_WIDTH } from '@theme/themeConstants';
import { BlockReleaseReviewProvider } from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewContext/BlockReleaseReviewContext';
import {
	BlockReleaseReviewRequests
} from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewPage/BlockReleaseReviewSection/BlockReleaseReviewRequests/BlockReleaseReviewRequests';
import {
	BlockReleaseReviewReleased
} from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewPage/BlockReleaseReviewSection/BlockReleaseReviewReleased/BlockReleaseReviewReleased';
import { ModalContextProvider } from '@components/Modal/ModalContextProvider';

const useStyles = makeStyles((theme) => ({
	root: {
		'minHeight': 'calc(100vh - 136px)',
		'backgroundColor': theme.palette.common.white,
		'& > div:first-child': {
			'& [data-field="component-container"]': {
				display: 'flex',
				paddingBottom: theme.spacing(7.5),
				maxWidth: LEGACY_MAX_WIDTH,
				flexDirection: 'column',
			},
		},
	},
	body: {
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
		marginTop: theme.spacing(3),
		width: '100%',
	},
	rootBlockReleaseReviewPage: {
		backgroundColor: theme.palette.common.white,
	},
	releasedSection: {
		'& > :first-child': {
			color: theme.palette.success.main,
			marginTop: theme.spacing(2.25),
		},
	}
}));

export const BlockReleaseReviewPage = () => {
	const classes = useStyles();

	return (
		<BlockReleaseReviewProvider>
			<ModalContextProvider>
				<MainPageLayoutContainer pageName="BlockReleaseReviewPage" className={classes.rootBlockReleaseReviewPage} bodyClass={classes.root}>
					<MainPageLayoutBody className={classes.body}>
						<BlockReleaseReviewRequests />
						<BlockReleaseReviewReleased />
					</MainPageLayoutBody>
				</MainPageLayoutContainer>
			</ModalContextProvider>
		</BlockReleaseReviewProvider>
	);
};
