import React from 'react';
import { CommentModal } from '@components/CaseDetails/CommentsSection/CommentModal/CommentModal';
import { EDIT_COMMENT_MODAL } from '@components/Modal/modalConstants';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { useCommentsContext } from '@components/CaseDetails/CommentsSection/CommentsContext/CommentsContext';
import { useDispatch, useSelector } from 'react-redux';
import { editCaseComment } from '@data/caseComments/actionCreators/CaseCommentsActionCreators';
import { RootState } from '@interfaces/rootState';
import { CaseDetailsState } from '@interfaces/CaseDetails/CaseDetailsState';
import { CaseDetailsSelector } from '@store/selectors/CaseDetailsSelector';
import { CaseCommentForm } from '@components/CaseDetails/CommentsSection/CommentModal/CaseCommentForm';

export const EditCommentModal = () => {
	const dispatch = useDispatch();
	const { closeModal } = useModalContext();
	const { selectedComment, setSelectedComment, getComments } = useCommentsContext();
	const { details } = useSelector<RootState, CaseDetailsState>(CaseDetailsSelector);

	const handleCancel = React.useCallback(() => {
		setSelectedComment(undefined);
		closeModal(EDIT_COMMENT_MODAL);
	}, [closeModal, setSelectedComment]);

	const handleSaveEditComment = React.useCallback((formResults: CaseCommentForm) => {
		dispatch(editCaseComment({
			comment: formResults.comment,
			commentId: selectedComment!.id,
			types: formResults.commentTypes,
		}, {
			onSuccess: () => {
				getComments({ resourceIds: [details?.id || ''] });
				closeModal(EDIT_COMMENT_MODAL);
				setSelectedComment(undefined);
			},
		}));
	}, [selectedComment, dispatch, closeModal, setSelectedComment, getComments, details?.id]);

	if (!selectedComment) return null;

	return (
		<CommentModal
			id={EDIT_COMMENT_MODAL}
			name="editCommentModal"
			title="Edit Comment"
			onCancel={handleCancel}
			onSaveComment={handleSaveEditComment}
			defaultValues={{
				comment: selectedComment.comment,
				commentTypes: selectedComment.types,
			}}
		/>
	);
};
