import { format as formatDateFns } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

// Mapping old format strings to new format strings
const formatStringMap: Record<string, string> = {
	YYYY: 'yyyy', // Year
	YY: 'yy', // Short year
	MM: 'MM', // Month (zero-padded)
	M: 'M', // Month (non-zero-padded)
	DD: 'dd', // Day (zero-padded)
	D: 'd', // Day (non-zero-padded)
	HH: 'HH', // Hour (24-hour format)
	hh: 'hh', // Hour (12-hour format)
	mm: 'mm', // Minutes
	ss: 'ss', // Seconds
	A: 'aaa', // AM/PM
};

// Custom implementation of DateFnsUtils to support legacy date format strings.
// This class maps older format tokens (e.g., 'YYYY', 'DD') to the newer date-fns v2 format tokens (e.g., 'yyyy', 'dd').
// Used with MuiPickersUtilsProvider to ensure compatibility with Material-UI pickers.
class CustomDateFnsUtils extends DateFnsUtils {
	format = (date: Date, formatString: string): string => {
		let newFormatString = formatString;

		Object.keys(formatStringMap).forEach((formatToken) => {
			newFormatString = newFormatString.replace(formatToken, formatStringMap[formatToken]);
		});

		return formatDateFns(date, newFormatString);
	};

	getDayText(date: Date): string {
		return String(date.getDate());
	};

	getCalendarHeaderText(date: Date): string {
		return formatDateFns(date, 'MMM yyyy');
	};
}

export default CustomDateFnsUtils;
