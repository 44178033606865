import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { CommentsSectionHeader } from '@components/CaseDetails/CommentsSection/CommentsSectionHeader/CommentsSectionHeader';
import { CommentsTable } from '@components/CaseDetails/CommentsSection/CommentsTable/CommentsTable';
import { CommentsProvider } from '@components/CaseDetails/CommentsSection/CommentsContext/CommentsContext';
import { AddCommentModal } from '@components/CaseDetails/CommentsSection/CommentModal/AddCommentModal/AddCommentModal';
import {
	DeleteCommentModal
} from '@components/CaseDetails/CommentsSection/CommentModal/DeleteCommentModal/DeleteCommentModal';
import {
	EditCommentModal
} from '@components/CaseDetails/CommentsSection/CommentModal/EditCommentModal/EditCommentModal';

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: theme.spacing(3),
	},
}));

export const CommentsSection: React.FC = () => {
	const classes = useStyles();

	return (
		<CommentsProvider>
			<Box className={classes.root}>
				<AddCommentModal />
				<EditCommentModal />
				<DeleteCommentModal	/>
				<CommentsSectionHeader />
				<CommentsTable />
			</Box>
		</CommentsProvider>
	);
};
