import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_CASE_COMMENTS } from '@utilities/apiConstants';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import { CREATE_CASE_COMMENT, DELETE_CASE_COMMENT, EDIT_CASE_COMMENT, GET_CASE_COMMENTS } from '@store/actions/types';
import {
	AddCaseCommentRequest,
	CaseCommentsApiResponse,
	CaseCommentsRequest,
	DeleteCaseCommentRequest,
	EditCaseCommentRequest,
} from '@data/caseComments/types/CaseComments.types';
import ApiRestMethod from '@utilities/apiEndpoints/ApiRestMethod';
import { CancelPolicy } from '@interfaces/CancelPolicy';

export const addCaseComment = (
	body: AddCaseCommentRequest,
	overrides: Partial<ApiActionPayload> = {}
) => {
	return apiAction({
		url: ENDPOINT_CASE_COMMENTS,
		label: CREATE_CASE_COMMENT,
		method: ApiRestMethod.POST,
		apiService: ApiService.CS,
		includeAccessToken: true,
		data: {
			event: 'CREATE_COMMENT',
			body,
		},
		...overrides,
	});
};

export const editCaseComment = (
	body: EditCaseCommentRequest,
	overrides: Partial<ApiActionPayload> = {}
) => {
	return apiAction({
		url: ENDPOINT_CASE_COMMENTS,
		label: EDIT_CASE_COMMENT,
		method: ApiRestMethod.POST,
		apiService: ApiService.CS,
		includeAccessToken: true,
		data: {
			event: 'EDIT_COMMENT',
			body,
		},
		...overrides,
	});
};

export const deleteCaseComment = (
	body: DeleteCaseCommentRequest,
	overrides: Partial<ApiActionPayload> = {}
) => {
	return apiAction({
		url: ENDPOINT_CASE_COMMENTS,
		label: DELETE_CASE_COMMENT,
		method: ApiRestMethod.POST,
		apiService: ApiService.CS,
		includeAccessToken: true,
		data: {
			event: 'DELETE_COMMENT',
			body,
		},
		...overrides,
	});
}

export const getCaseComments = (
	body: CaseCommentsRequest,
	requestOverride?: Partial<ApiActionPayload<CaseCommentsApiResponse>>
) => {
	return apiAction({
		url: ENDPOINT_CASE_COMMENTS,
		method: ApiRestMethod.POST,
		apiService: ApiService.CS,
		includeAccessToken: true,
		label: GET_CASE_COMMENTS,
		shouldDisableLoadIndicator: true,
		cancelPolicy: CancelPolicy.NO_DUPLICATE_PATH,
		data: {
			event: 'LIST_COMMENT',
			body,
		},
		...requestOverride,
	});
};
