import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Backdrop, Modal, Fade, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';
import classNames from 'classnames';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { BaseIconButton } from '@components/BaseIconButton/BaseIconButton';

interface ModalViewerProps {
	className?: string;
	id: string;
	onClose?: NoParamNoReturnCallback;
	shouldCloseOnBackdropClick?: boolean;
	shouldCloseOnEscapeKeyDown?: boolean;
	dataField?: string;
}

const useStyles = makeStyles((theme) => {
	return {
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: theme.spacing(25),
			marginBottom: theme.spacing(25),
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(2.5, 4),
			boxShadow: theme.shadows[5],
			borderRadius: '2px',
			overflow: 'auto',
		},
		closeIcon: {
			display: 'flex',
			marginLeft: 'auto',
			padding: 0,
		},
		backdrop: {
			backgroundColor: alpha(theme.palette.common.black, 0.35),
		},
	};
});

const ModalViewer: React.FC<ModalViewerProps> = ({
	children,
	id,
	dataField,
	onClose,
	shouldCloseOnBackdropClick,
	shouldCloseOnEscapeKeyDown,
	className,
}) => {
	const classes = useStyles();
	const { closeModal, isModalOpen } = useModalContext();

	const open = React.useMemo(() => {
		return isModalOpen(id);
	}, [id, isModalOpen]);

	const handleClose = (event?: object, reason?: string) => {
		if (!shouldCloseOnBackdropClick && reason === 'backdropClick') {
			return false;
		}

		if (!shouldCloseOnEscapeKeyDown && reason === 'escapeKeyDown') {
			return false;
		}

		if (typeof onClose === 'function') {
			onClose();
		}

		closeModal(id);
	};

	return (
		<Modal
			open={open}
			onClose={(event, reason) => handleClose(event, reason)}
			className={classes.modal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 250,
				classes: {
					root: classes.backdrop,
				},
			}}
		>
			<Fade in={open}>
				<Box className={classNames(classes.paper, className)}>
					<BaseIconButton
						shouldTrackAnalytics={!!dataField}
						dataField={`${dataField}-close`}
						aria-label="close"
						className={classes.closeIcon}
						onClick={handleClose}
					>
						<CloseIcon />
					</BaseIconButton>
					{children}
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalViewer;
