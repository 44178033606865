import React from 'react';
import { BlockReleaseLegend } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestPage/BlockReleaseLegend/BlockReleaseLegend';
import MainPageLayoutContainer from '@components/MainPageLayout/presentationalComponents/MainPageLayoutContainer';
import MainPageLayoutHeader from '@components/MainPageLayout/presentationalComponents/MainPageLayoutHeader';
import MainPageLayoutSubsection from '@components/MainPageLayout/presentationalComponents/MainPageLayoutSubsection';
import { makeStyles } from '@material-ui/core/styles';
import { Box, createStyles, Divider } from '@material-ui/core';
import MainPageLayoutBody from '@components/MainPageLayout/presentationalComponents/MainPageLayoutBody';
import { X_LARGE_MAX_WIDTH } from '@theme/themeConstants';
import { ReleasesSection } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestPage/ReleasesSection/ReleasesSection';
import { BlockDetailsSection } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestPage/BlockDetailsSection/BlockDetailsSection';
import { ScheduleSection } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestPage/ScheduleSection/ScheduleSection';
import { BlockReleaseRequestProvider } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestContext/BlockReleaseRequestContext';
import { AuthenticatedBanner } from '@components/AuthenticatedBanner/AuthenticatedBanner';
import {
	SchedulePageProvider,
} from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import { ModalContextProvider } from '@components/Modal/ModalContextProvider';

const useStyles = makeStyles((theme) =>
	createStyles({
		banner: {
			margin: theme.spacing(0),
		},
		bodyClass: {
			'backgroundColor': theme.palette.common.white,
			'& > div:first-child': {
				'maxWidth': '1022px',
				[theme.breakpoints.up('xl')]: {
					maxWidth: X_LARGE_MAX_WIDTH,
				},
				'& [data-field="component-container"]': {
					display: 'flex',
					overflow: 'hidden'
				},
			},
		},
		header: {
			justifyContent: 'flex-end',
		},
		headerSubSection: {
			flexDirection: 'column',
			padding: theme.spacing(0, 0, 4, 0),
		},
		pageBody: {
			display: 'grid',
			gridTemplateColumns: 'minmax(200px, 292px) minmax(300px, 1fr) 248px',
			gridTemplateAreas: `"releases blockDetails schedule"`,
			gap: theme.spacing(4),
		}
	}),
);

export const BlockReleaseRequestPage = () => {
	const classes = useStyles();

	return (
		<SchedulePageProvider>
			<BlockReleaseRequestProvider>
				<ModalContextProvider>
					<MainPageLayoutContainer
						pageName="BlockReleaseRequestPage"
						bodyClass={classes.bodyClass}
					>
						<MainPageLayoutHeader className={classes.header}>
							<MainPageLayoutSubsection className={classes.headerSubSection}>
								<Box className={classes.banner}>
									<AuthenticatedBanner />
								</Box>
								<BlockReleaseLegend />
								<Divider orientation="horizontal" />
							</MainPageLayoutSubsection>
						</MainPageLayoutHeader>
						<MainPageLayoutBody className={classes.pageBody}>
							<ReleasesSection />
							<BlockDetailsSection />
							<ScheduleSection />
						</MainPageLayoutBody>
					</MainPageLayoutContainer>
				</ModalContextProvider>
			</BlockReleaseRequestProvider>
		</SchedulePageProvider>
	);
};
