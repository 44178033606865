// String value copied from older implementation before enum created
// in order to avoid unexpected regression
enum DetailSection {
	APPOINTMENT = 'AppointmentRequest',
	PATIENT = 'PatientInformation',
	PROCEDURE = 'SurgeryInformation',
	PRIMARY_INSURANCE = 'PrimaryInsuranceInformation',
	SECONDARY_INSURANCE = 'SecondaryInsuranceInformation',
	PAT = 'PatInformation',
	ATTACHMENTS = 'AttachmentsInformation',
	AUTHORIZATION = 'Authorization',
	COMMENTS = 'Comments',
}

export default DetailSection;
