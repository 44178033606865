import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { BLOCK_RELEASE_REQUEST_CONFIRM_MODAL } from '@components/Modal/modalConstants';
import { BaseButton } from '@components/BaseButton/BaseButton';
import { useCreatePendingReleaseEvent } from '@data/blockReleases/hooks/useCreatePendingReleaseEvent';
import { PendingReleaseEventType } from '@data/blockReleases/types/PendingReleaseEvent.types';
import { getDateFromApiDateTime } from '@calendar';
import { formatInTimeZone } from 'date-fns-tz';
import {
	BlockReleaseConfirmModalProps
} from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestConfirmModal/BlockReleaseRequestConfirmModal';
import { useFormContext } from 'react-hook-form';
import {
	PartialBlockReleaseRequestForm
} from '@components/BlockReleaseRequestPage/BlockDetailsSection/PartialReleaseSection/PartialReleaseRequest.types';

type BlockReleaseConfirmModalButtonsProps = Omit<BlockReleaseConfirmModalProps, 'modalValues'>;

const useStyles = makeStyles((theme) => ({
	buttonContainer: {
		display: 'flex',
		marginTop: theme.spacing(3),
		justifyContent: 'space-between',
		height: '48px',
	},
	inlineError: {
		marginRight: theme.spacing(3),
		fontWeight: 600,
		color: '#E00019',
	},
}));

export const BlockReleaseRequestConfirmModalButtons: React.FC<BlockReleaseConfirmModalButtonsProps> = ({ blockReleaseInfo, facilityInfo }) => {
	const classes = useStyles();
	const { createPendingReleaseEvent, inlineError } = useCreatePendingReleaseEvent();
	const { closeModal } = useModalContext();

	const slotId = blockReleaseInfo.blocks?.[0]?.id;
	const blockStart = blockReleaseInfo.blocks?.[0]?.start;
	const startDate = blockStart && formatInTimeZone(getDateFromApiDateTime(blockStart), facilityInfo.hospitalTimeZone, 'yyyy-MM-dd');
	const shouldRenderButtons = slotId && startDate;
	const { getValues } = useFormContext<PartialBlockReleaseRequestForm>();

	if (!shouldRenderButtons) return null;

	const handleCloseModal = () =>
		closeModal(BLOCK_RELEASE_REQUEST_CONFIRM_MODAL.id + blockReleaseInfo.id);

	const handleReleaseButtonClick = () => {
		const { startTime, endTime } = getValues();

		createPendingReleaseEvent(slotId, {
			event: PendingReleaseEventType.SSM_RELEASE,
			body: {
				date: startDate,
				startTime, // must be >= block start time
				endTime // must be <= block end time
			},
		});
	};

	return (
		<Box className={classes.buttonContainer}>
			<BaseButton
				dataField="blockRelease-blockReleaseRequestModal-cancel"
				color="primary"
				variant="text"
				onClick={handleCloseModal}
			>
				Cancel
			</BaseButton>
			<Box display="flex" alignItems="center">
				{inlineError && <Typography className={classes.inlineError}>{inlineError}</Typography>}
				<BaseButton
					dataField="blockRelease-blockReleaseRequestModal-release"
					variant="contained"
					color="primary"
					onClick={handleReleaseButtonClick}
					disabled={!!inlineError}
				>
					Yes, release
				</BaseButton>
			</Box>
		</Box>
	);
};
