import React from 'react';
import Markdown from 'react-markdown';
import { makeStyles, Typography } from '@material-ui/core';

import { Banner } from '@components/Banner/Banner';
import { SecurityAlertBadge } from '@components/SecurityAlertBadge/SecurityAlertBadge';
import { useSsmUiFlags } from '@utilities/hooks/useSsmUiFlags/useSsmUiFlags';

interface UnAuthenticatedBannerType {
	className?: string;
}

const useStyles = makeStyles((theme) => ({
	securityAlertBadge: {
		minWidth: '160px',
		height: '142px',
	},
	markDown: {
		'& h3': {
			fontWeight: 600,
			color: theme.palette.error.main,
			margin: 0,
			fontSize: '1.25rem',
		},
		'& hr': {
			width: '100%',
			border: 'none',
			height: 1,
			backgroundColor: 'lightgrey',
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		'& p': {
			margin: 0,
			fontWeight: 600,
			fontSize: '.875rem',
		}
	},
}));

export const UnAuthenticatedBanner: React.FC<UnAuthenticatedBannerType> = ({ className }) => {
	const classes = useStyles();
	const { ssmuiShowUnauthenticatedBanner } = useSsmUiFlags();

	if (!ssmuiShowUnauthenticatedBanner?.enabled) {
		return null;
	}

	return (
		<Banner
			img={() => <SecurityAlertBadge className={classes.securityAlertBadge} />}
			className={className}
		>
			<Typography component="div">
				<Markdown className={classes.markDown} linkTarget="_blank">
					{ssmuiShowUnauthenticatedBanner.content}
				</Markdown>
			</Typography>
		</Banner>
	);
};
