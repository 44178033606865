import React from 'react';
import ModalViewer from '@components/Modal/ModalViewer';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { BaseButton } from '@components/BaseButton/BaseButton';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { BlockReleasesListResponse } from '@data/blockReleases/types/BlockReleasesListResponse';
import DisplayTimeFrame from '@components/DisplayTimeFrame/DisplayTimeFrame';
import { Unit } from '@data/unit/Unit';
import { formatInTimeZone } from 'date-fns-tz';
import { getDateFromApiDateTime } from '@calendar';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { useBlockReleaseContext } from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewContext/BlockReleaseReviewContext';
import { useSubmitBlockReleaseApproval } from '@data/blockReleases/hooks/useSubmitBlockReleaseApproval';
import { PendingReleaseEventType } from '@data/blockReleases/types/PendingReleaseEvent.types';

export interface BlockReleaseConfirmModalProps {
	id: string;
	blockReleaseDetails: BlockReleasesListResponse;
	surgeonName: string;
}

const useStyles = makeStyles((theme) => ({
	flexBoxGap: {
		gap: theme.spacing(3),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	cancelButton: {
		'border': 'none',
		'&.MuiButton-root.MuiButton-outlinedPrimary:hover': {
			border: 'none',
		},
	},
	modalHeaderText: {
		fontSize: 20,
		fontWeight: 600,
		color: 'rgba(0, 0, 0, 0.87)'
	},
	modalText: {
		lineHeight: '24px',
		fontWeight: 600,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)',
		fontSize: 18
	},
	genericTextValue: {
		lineHeight: '24px',
		fontWeight: 600,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)',
		fontSize: 18
	},
	baseButtonDisabled: {
		'&.MuiButton-root.Mui-disabled' : {
			color: theme.palette.common.white
		},
	},
	errorMessage: {
		marginLeft: theme.spacing(8),
		fontWeight: 600
	}
}));

export const BlockReleaseReviewConfirmModal: React.FC<BlockReleaseConfirmModalProps> = ({ id, blockReleaseDetails, surgeonName }) => {
	const classes = useStyles();
	const { closeModal } = useModalContext();
	const { getBlockReleases } = useBlockReleaseContext();
	const blockReleaseDetailId = blockReleaseDetails.id;
	const { hospitalName, room, unit: unitName, startDateTime, endDateTime, slotId } = blockReleaseDetails;
	const start = getDateFromApiDateTime(startDateTime);
	const end = getDateFromApiDateTime(endDateTime);
	const { hospitalTimeZone } = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unitName, 'name');
	const date = end && hospitalTimeZone
		&& formatInTimeZone(getDateFromApiDateTime(end), hospitalTimeZone, 'eeee, MMMM d, yyyy');

	const requestData = React.useMemo(() => {
		return {
			event: PendingReleaseEventType.SSM_RELEASE_APPROVE,
			body: {
				date: formatInTimeZone(getDateFromApiDateTime(endDateTime), (hospitalTimeZone || ''), 'yyyy-MM-dd'),
				releaseId: blockReleaseDetailId,
			}
		}
	}, [blockReleaseDetailId, endDateTime, hospitalTimeZone]);

	const { inlineError, createBlockRelease, setInlineError } = useSubmitBlockReleaseApproval({ id });

	const handleCancelClick = React.useCallback(() => {
		closeModal(id);
		if (inlineError.code === 'CS03') {
			getBlockReleases();
			setInlineError({
				message: '',
				code: ''
			});
		}
	}, [id, closeModal, getBlockReleases, inlineError.code, setInlineError]);

	const handleConfirmClick = React.useCallback(() => {
		if (slotId) {
			createBlockRelease(slotId, requestData);
		}
	}, [createBlockRelease, slotId, requestData]);

	return (
		<ModalViewer id={id} dataField="blockReleaseReview-releaseCard-confirmModal" onClose={handleCancelClick}>
			<Typography className={classes.modalHeaderText}>Time has been released</Typography>
			<Box display="flex" flexDirection="column" className={classes.flexBoxGap}>
				<Typography className={classes.modalText}>
						You are confirming that the block below has been released in the EHR.
				</Typography>
				<Box display="flex" flexDirection="column">
					<Typography className={classes.modalText}>
						{surgeonName}
					</Typography>
					<Typography className={classes.modalText}>
						{date}
					</Typography>
					<DisplayTimeFrame
						className={classes.genericTextValue}
						timeFrame={{ start: start, end: end }}
						timeZone={hospitalTimeZone || ''}
					/>
					<Typography className={classes.modalText}>
						{hospitalName} {unitName}
					</Typography>
					<Typography className={classes.modalText}>
						Room {room}
					</Typography>
				</Box>
				<Typography className={classes.modalText}>
						This cannot be undone.
				</Typography>
			</Box>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<BaseButton
					dataField="blockReleaseReview-confirmModal-cancelButton"
					color="primary"
					variant="outlined"
					className={classes.cancelButton}
					onClick={handleCancelClick}
				>
					CANCEL
				</BaseButton>
				{inlineError &&
					<Typography variant="body1" color="error" className={classes.errorMessage}>
						{inlineError.message}
					</Typography>
				}
				<BaseButton
					dataField="blockReleaseReview-confirmModal-confirmButton"
					color="primary"
					variant="contained"
					onClick={handleConfirmClick}
					className={classes.baseButtonDisabled}
					disabled={inlineError.code === 'CS03'}
				>
						Yes, I Released in the EHR
				</BaseButton>
			</Box>
		</ModalViewer>
	);
};
