import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { dateParse, dateToInputString, parsedTimeTo24Hr } from '../dateUtilities';
import { InsuranceApi, PreAuthOptionsEnum } from '@interfaces/SurgeryRequest/InsuranceApi';
import { booleanConvert } from '../booleanConvert';
import { DiagnosisApi } from '@interfaces/SurgeryRequest/DiagnosesApi';
import { SurgeryProcedureInterface } from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { ProcedureApi } from '@interfaces/Procedure/ProcedureApi';
import { YesNoAnswer } from '@interfaces/SurgeryRequest/YesNoAnswer';
import { AppointmentType } from '@data/request/AppointmentType';
import { isDocumentRequest, isWebRequest } from '@utilities/typeAssertionUtilities';
import {
	transformEquipmentDataToDropdownOptions
} from '@components/ProcedureForm/inputs/EquipmentMultiSelect/EquipmentMultiSelectTypeConverters';

const defaultToEmptyString = (fieldVal: string) => booleanConvert(!!fieldVal, fieldVal, '');
const valueToDropdown = (value: string) => ({ value: defaultToEmptyString(value), label: defaultToEmptyString(value) });

const convertBooleanToPreAuthEnum = (value?: boolean | PreAuthOptionsEnum): PreAuthOptionsEnum => {
	if (value === undefined) {
		return PreAuthOptionsEnum.UNDEFINED;
	} else if (typeof value === 'boolean') {
		if (value) {
			return PreAuthOptionsEnum.YES;
		} else {
			return PreAuthOptionsEnum.NO;
		}
	} else {
		return value;
	}
};

export const surgeryRequestApiToFormState = (surgeryRequest?: SurgeryRequestGet | Record<never, never>): RequestFormValidatedState | Record<never, never> => {
	if (!surgeryRequest || !Object.keys(surgeryRequest).length) {
		return {};
	}

	const surgeryReq: SurgeryRequestGet = surgeryRequest as SurgeryRequestGet;

	const procedureDate: Date = new Date(surgeryReq.appointmentDetail.procedureDate);
	const parsedProcedureDate = dateParse(surgeryReq.appointmentDetail.procedureDate);
	const procedureTime24Hr = parsedTimeTo24Hr(parsedProcedureDate[1], parsedProcedureDate[2]);

	const appointmentLocation = surgeryReq.unit;

	// add CommonRequestForm fields to formState
	let formState: Partial<RequestFormValidatedState> = {
		appointmentType: surgeryReq.appointmentType || AppointmentType.LEGACY_WEB,
		appointmentLocation,
		procedureDate,
		procedureTime: [procedureTime24Hr, parsedProcedureDate[1]],
		duration: surgeryReq.appointmentDetail.duration,
		primarySurgeon: surgeryReq.primarySurgeon,
		appointmentToFollow: booleanConvert(surgeryReq.appointmentDetail.toFollow, surgeryReq.appointmentDetail.toFollow, false),
		appointmentBlockTime: booleanConvert(surgeryReq.appointmentDetail.blockTime, surgeryReq.appointmentDetail.blockTime, false),
		findATimeUsed: surgeryReq.findATimeUsed,
	};

	if (surgeryReq.room) {
		formState.room = surgeryReq.room;
	}

	if (isWebRequest(surgeryReq)) {
		// parse web request data from API to form state
		const patientDOB = surgeryReq.patientInfo.birthDate ?
			dateToInputString(surgeryReq.patientInfo.birthDate + ' 00:00:00') : '';
		const noDashSSNRegEx = /^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/;
		let patientSSNParts: string[] = [];
		if (noDashSSNRegEx.test(surgeryReq.patientInfo.ssn)) {
			patientSSNParts = [surgeryReq.patientInfo.ssn.slice(0, 3), surgeryReq.patientInfo.ssn.slice(3, 5), surgeryReq.patientInfo.ssn.slice(5, 9)];
		} else if (surgeryReq.patientInfo.ssn) {
			patientSSNParts = surgeryReq.patientInfo.ssn.split('-');
		}

		const primaryInsurance = surgeryReq.insurances.find((insurance: InsuranceApi) => insurance.primary) || surgeryReq.insurances[0];
		const primaryDiagnosisObj: DiagnosisApi = surgeryReq.surgeryInformation.diagnosis.find((d: DiagnosisApi) => d.primary) || surgeryReq.surgeryInformation.diagnosis[0];
		const primaryDiagnosis = defaultToEmptyString(primaryDiagnosisObj.code);

		const surgeryProcedures = surgeryReq.procedures?.map((p: ProcedureApi): SurgeryProcedureInterface => {
			return {
				surgeon: {
					value: p.surgeonId,
					label: '',
				},
				procedureName: defaultToEmptyString(p.name),
				modifierSide: valueToDropdown(p.modifierSide),
				modifierApproach: valueToDropdown(p.modifierApproach),
				isPrimaryProcedure: p.primary ? 1 : 0,
				anesthesiaType: p?.anesthesiaType?.map( value => valueToDropdown(value)),
				implantsNeeded: p.implantsNeeded ? 1 : 0,
				vendorName: defaultToEmptyString(p.vendor),
				vendorContacted: p.vendorContacted ? 1 : 0,
				otherEquipment: defaultToEmptyString(p.otherEquipments),
				additionalComments: defaultToEmptyString(p.comments),
				cptCodes: { entriesList: p.cptCodes || [], currentEntry: '' },
				robotic: p.robotic,
				equipment: p.equipment ? transformEquipmentDataToDropdownOptions(p.equipment) : [],
				firstAssistant: p.firstAssistant,
			};
		}) || [];

		const patientInterpreterNeeded = surgeryReq.patientInfo.interpreter;
		const patTestingNeeded = booleanConvert<string>(surgeryReq.patDetail.preAdmissionTestingNeeded, YesNoAnswer.YES, YesNoAnswer.NO);

		const appointmentRequestAuthStatus = {
			value: surgeryReq.appointmentDetail.authStatus,
			label: surgeryReq.appointmentDetail.authStatus,
		};

		const patBedNeeded = booleanConvert(!!surgeryReq.patDetail.bedNeeded,
			{
				value: surgeryReq.patDetail.bedNeeded,
				label: surgeryReq.patDetail.bedNeeded,
			},
			{
				value: '',
				label: '',
			});

		// add to formState
		formState = {
			...formState,
			insuredLastNamePrimary: defaultToEmptyString(primaryInsurance.family),
			insuredFirstNamePrimary: defaultToEmptyString(primaryInsurance.name),
			healthPlanNamePrimary: defaultToEmptyString(primaryInsurance.planName),
			policyNumberPrimary: defaultToEmptyString(primaryInsurance.policyNumber),
			groupNumberPrimary: defaultToEmptyString(primaryInsurance.groupNumber),
			preCertNumberPrimary: defaultToEmptyString(primaryInsurance.preCert),
			preAuthPrimary: convertBooleanToPreAuthEnum(primaryInsurance.preAuth),

			patientLastName: defaultToEmptyString(surgeryReq.patientInfo.family),
			patientFirstName: defaultToEmptyString(surgeryReq.patientInfo.name),
			patientEmail: defaultToEmptyString(surgeryReq.patientInfo.email),
			patientGender: booleanConvert(!!surgeryReq.patientInfo.gender, {
				value: surgeryReq.patientInfo.gender,
				label: surgeryReq.patientInfo.gender,
			}, { value: '', label: '' }),
			patientInterpretLanguage: defaultToEmptyString(surgeryReq.patientInfo.interpreterLanguage),
			patientMiddleName: defaultToEmptyString(surgeryReq.patientInfo.middleName),
			patientPhoneNumber: defaultToEmptyString(surgeryReq.patientInfo.phone),
			patientSSN: defaultToEmptyString(surgeryReq.patientInfo.ssn),
			patientSSN1: defaultToEmptyString(patientSSNParts.length === 3 ? patientSSNParts[0] : ''),
			patientSSN2: defaultToEmptyString(patientSSNParts.length === 3 ? patientSSNParts[1] : ''),
			patientSSN3: defaultToEmptyString(patientSSNParts.length === 3 ? patientSSNParts[2] : patientSSNParts[0]),
			patientIodineAllergy: surgeryReq.patientInfo.iodineAllergy,
			patientLatexAllergy: surgeryReq.patientInfo.latexAllergy,
			procedureName: surgeryReq.procedures?.find((p: ProcedureApi) => p.primary)?.name,
			otherSurgicalComments: defaultToEmptyString(surgeryReq.surgeryInformation.comments),
			otherPatientDetails: defaultToEmptyString(surgeryReq.patientInfo.otherPatientDetails),
			patientAddressLine1: surgeryReq.address?.addressLine1 && defaultToEmptyString(surgeryReq.address?.addressLine1),
			patientAddressLine2: surgeryReq.address?.addressLine2,
			patientCity: surgeryReq.address?.city && defaultToEmptyString(surgeryReq.address?.city),
			patientState: surgeryReq.address?.state && defaultToEmptyString(surgeryReq.address?.state),
			patientZipCode: surgeryReq.address?.zipCode && defaultToEmptyString(surgeryReq.address?.zipCode),

			appointmentRequestAuthStatus,
			patientInterpreterNeeded,
			patTestingNeeded,
			patBedNeeded,
			surgeryProcedures,
			primaryDiagnosis,
			patientDOB,
		};
		if (surgeryReq.patDetail.date) {
			const parsedDate = dateParse(surgeryReq.patDetail.date);
			const timeIn24Hour = parsedTimeTo24Hr(parsedDate[1], parsedDate[2]);

			formState.patDate = new Date(surgeryReq.patDetail.date);
			formState.patTime = [timeIn24Hour, parsedDate[1]];
		}

		if (surgeryReq.patDetail.location) {
			formState.patLocation = {
				value: surgeryReq.patDetail.location,
				label: surgeryReq.patDetail.location,
			};
		}

		if (surgeryReq.insurances.length === 2) {
			const secondaryInsurance: InsuranceApi | undefined = surgeryReq.insurances
				.find((insurance: InsuranceApi) => insurance.policyNumber !== primaryInsurance.policyNumber);
			if (secondaryInsurance) {
				formState.insuredFirstNameSecondary = defaultToEmptyString(secondaryInsurance.name);
				formState.insuredLastNameSecondary = defaultToEmptyString(secondaryInsurance.family);
				formState.healthPlanNameSecondary = defaultToEmptyString(secondaryInsurance.planName);
				formState.policyNumberSecondary = defaultToEmptyString(secondaryInsurance.policyNumber);
				formState.groupNumberSecondary = defaultToEmptyString(secondaryInsurance.groupNumber);
				formState.preCertNumberSecondary = defaultToEmptyString(secondaryInsurance.preCert);
				formState.preAuthSecondary = convertBooleanToPreAuthEnum(secondaryInsurance.preAuth);
			}
		}

		if (surgeryReq.surgeryInformation.diagnosis.length > 1) {
			const secondaryDiagObj: DiagnosisApi | undefined = surgeryReq.surgeryInformation.diagnosis.find((d: DiagnosisApi) => d.code !== primaryDiagnosis);
			if (secondaryDiagObj) {
				formState.secondaryDiagnosis = defaultToEmptyString(secondaryDiagObj.code);
			}
		}
	} else if (isDocumentRequest(surgeryReq)) {
		// add document request data from API to form state
		formState = {
			...formState,
			primarySurgeon: surgeryReq.primarySurgeon,
			procedureName: defaultToEmptyString(surgeryReq.procedures[0]?.name),
			patientFirstName: defaultToEmptyString(surgeryReq.patientInfo.name),
			patientLastName: defaultToEmptyString(surgeryReq.patientInfo.family),
			patientMiddleName: defaultToEmptyString(surgeryReq.patientInfo.middleName),
			primaryDiagnosis: defaultToEmptyString(surgeryReq.surgeryInformation.diagnosis[0]?.code),
			cptCodes: { entriesList: surgeryReq.procedures[0]?.cptCodes || [], currentEntry: '' },
			preCertNumberPrimary: defaultToEmptyString(surgeryReq.insurances[0]?.preCert),
		};
	}

	return formState;
};
