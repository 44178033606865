export enum PendingReleaseEventType {
	SSM_RELEASE = 'SSM_RELEASE',
	SSM_RELEASE_APPROVE = 'SSM_RELEASE_APPROVE',
}

export interface PendingReleaseEventRequest {
	event: PendingReleaseEventType;
	body: {
		date: string; // yyyy-MM-DD format
		startTime?: string; // HH:mm (24 hour)
		endTime?: string; // HH:mm (24 hour)
	};
}

enum PendingReleaseEventStatus {
	PENDING = 'PENDING',
	SUCCESSFUL = 'SUCCESSFUL',
	FAILED = 'FAILED',
}

export interface PendingReleaseEventResponse {
	event: PendingReleaseEventType;
	transactionId: number;
	status: PendingReleaseEventStatus; // event status != entity status
	message: string;
	timestamp: string; // yyyy-MM-dd HH:mm  format in UTC
	code: string;
}
