import React from 'react';
import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DailySurgeonCalendarFilled from '@components/FindATime/DailySurgeonCalendarSection/DailySurgeonCalendarFilled';
import { useBlockReleaseRequestContext } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestContext/BlockReleaseRequestContext';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			gridArea: 'schedule',
		},
	}),
);

export const ScheduleSection = () => {
	const classes = useStyles();
	const { releaseRequestInfo } = useBlockReleaseRequestContext();

	const blockInfo = releaseRequestInfo?.blockInfo;

	if (!blockInfo) return null;

	return (
		<Box className={classes.root}>
			<DailySurgeonCalendarFilled key={blockInfo.id} blockInfo={blockInfo} />
		</Box>
	);
};
