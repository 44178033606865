import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { BlockReleaseConfirmModalProps } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestConfirmModal/BlockReleaseRequestConfirmModal';
import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { PartialBlockReleaseRequestForm } from '@components/BlockReleaseRequestPage/BlockDetailsSection/PartialReleaseSection/PartialReleaseRequest.types';
import { timeStringAs12Hr } from '@utilities/dateUtilities';
import { formatInTimeZone } from 'date-fns-tz';

type BlockReleaseConfirmModalInfoProps = BlockReleaseConfirmModalProps;

const useStyles = makeStyles((theme) => ({
	modalInfo: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(3),
	},
	bodyText: {
		fontSize: '1.125rem',
		color: 'rgba(0, 0, 0, 0.6)',
		fontWeight: 600,
	},
}));

export const BlockReleaseRequestConfirmModalInfo: React.FC<BlockReleaseConfirmModalInfoProps> = ({
	modalValues,
	blockReleaseInfo,
	facilityInfo,
}) => {
	const classes = useStyles();
	const { watch } = useFormContext<PartialBlockReleaseRequestForm>();
	const formattedDate = format(new Date(blockReleaseInfo.selectedDate), 'EEEE, MMMM dd, yyyy');
	const blockUnit = blockReleaseInfo?.blocks?.[0]?.unit;

	const { startTime, endTime } = watch();

	const timeInterval = {
		start:
			timeStringAs12Hr(startTime) ||
			formatInTimeZone(
				blockReleaseInfo.timeframes[0].start,
				facilityInfo.hospitalTimeZone,
				'h:mm a',
			),
		end:
			timeStringAs12Hr(endTime) ||
			formatInTimeZone(
				blockReleaseInfo.timeframes[0].end,
				facilityInfo.hospitalTimeZone,
				'h:mm a',
			),
	};

	const timeIntervalString = `${timeInterval.start} - ${timeInterval.end}`;

	return (
		<Box className={classes.modalInfo}>
			<Typography className={classes.bodyText}>{modalValues.message}</Typography>
			<Box>
				<Typography className={classes.bodyText}>{blockReleaseInfo.displayName}</Typography>
				<Typography className={classes.bodyText}>{formattedDate}</Typography>
				<Typography className={classes.bodyText}>{timeIntervalString}</Typography>
				<Typography className={classes.bodyText}>
					{facilityInfo?.name} {blockUnit ? `/ ${blockUnit}` : ''}
				</Typography>
			</Box>
			<Typography className={classes.bodyText}>{modalValues.warning}</Typography>
		</Box>
	);
};
