import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Duration } from '@components/RequestForm/inputs/Duration/Duration';
import { useFormContext } from 'react-hook-form';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { validators } from '@utilities/Validation/validators';
import { useFindATimeContext } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';
import useGetSelectedUnitInfo
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetSelectedUnitInfo/useGetSelectedUnitInfo';
import useGetPrimarySurgeonNpi
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetPrimarySurgeonNpi/useGetPrimarySurgeonNpi';
import {
	useGetRequestProcedureDuration
} from '@components/RequestForm/utilities/hooks/useGetRequestProcedureDuration/useGetRequestProcedureDuration';

const useProcedureDurationStyles = makeStyles((theme) => ({
	instructionText: {
		padding: theme.spacing(1, 0, 0, 0),
		fontWeight: 500,
	}
}));

export const ProcedureDuration = () => {
	const classes = useProcedureDurationStyles();

	const { formState, setValue, watch } = useFormContext<FindATimeForm>();
	const { setDurationFallback } = useFindATimeContext();
	const selectedUnit = useGetSelectedUnitInfo();
	const primarySurgeonNpi = useGetPrimarySurgeonNpi();
	const [procedureName] = watch(['procedureName']);

	const { hospital: hospitalId, id: unitId } = selectedUnit || {};

	const durationApi = useGetRequestProcedureDuration({
		hospitalId,
		unitId,
		procedure: procedureName,
		npi: primarySurgeonNpi,
	});

	const durationValue = React.useMemo(() => {
		// This is to set the durationValue to an empty string if the procedureName is also an empty string, fixes
		// the persistent duration problem when switching units
		if (formState.defaultValues?.duration && formState.defaultValues?.procedureName === procedureName) {
			return formState.defaultValues.duration;
		}
		return durationApi?.toString();
	}, [formState.defaultValues, procedureName, durationApi]);

	React.useEffect(() => {
		const durationStr = durationValue;
		setDurationFallback(durationStr);
		setValue('duration', durationStr, { shouldValidate: true });
	}, [durationValue, setDurationFallback, setValue]);

	const handleChange =  (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (validators.numericValidator(e.target.value) === null) {
			setValue('duration', e.target.value);
		}
	};

	return (
		<>
			<Duration
				label="Est. duration in minutes (setup, procedure & cleanup)"
				inputProps={{
					onChange: handleChange,
				}}
				dataField="findATimePage-searchSection-durationTextInput"
			/>
			<Typography variant="subtitle2" color="textSecondary" className={classes.instructionText}>
				Populated time is based on historical times of this surgeon and this procedure
			</Typography>
		</>
	);
};
