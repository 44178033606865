import React from 'react';
import EventLegend from '@components/UnitCalendarPage/EventLegend/EventLegend';
import {
	BlockReleaseEventConfig, BlockReleaseStyleConfig
} from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestPage/BlockReleaseLegend/BlockReleaseLegendConfig';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
	legend: {
		'boxShadow': 'none',
		'paddingLeft': 0,
		'alignSelf': 'flex-end',
		'& > div > div > div': {
			height: '16px',
			width: '16px',
		},
	},
}));

export const BlockReleaseLegend = () => {
	const classes = useStyles();

	return (
		<EventLegend
			className={classes.legend}
			config={BlockReleaseEventConfig}
			styleConfig={BlockReleaseStyleConfig}
		/>
	);
};
