import React from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { getBlockReleasesByUnit as getBlockReleasesAction } from '@data/blockReleases/actionCreators/BlockReleaseActionCreators';
import { BlockReleasesListResponse } from '@data/blockReleases/types/BlockReleasesListResponse';
import { useFacilitySelection } from '@components/SchedulePage/utilities/useFacilitySelection/useFacilitySelection';
import { doesUnitHaveOpenTime } from '@utilities/doesUnitHaveOpenTime';
import { sortBlockReleases } from '@components/BlockRelease/utilities/sortBlockReleases/sortBlockReleases';

export const useGetBlockReleasesByUnit = (shouldDisableApiCall = false) => {
	const dispatch = useDispatch();

	const [blockReleaseList, setBlockReleaseList] = React.useState<BlockReleasesListResponse[]>([]);
	const { facilitiesSorted } = useFacilitySelection();
	const unitNameArray = React.useMemo(() => {
		return facilitiesSorted?.flatMap(facility =>
			facility.units.filter(doesUnitHaveOpenTime).map(unit => unit.name)
		);
	}, [facilitiesSorted]);

	const getBlockReleases = React.useCallback(() => {
		const onSuccess = (response: AxiosResponse<BlockReleasesListResponse[]>) => {
			const sortedBlockReleases = sortBlockReleases(response.data);
			setBlockReleaseList(sortedBlockReleases);
		};

		dispatch(
			getBlockReleasesAction(unitNameArray, {
				onSuccess,
			})
		);
	}, [dispatch, unitNameArray, setBlockReleaseList]);

	React.useEffect(() => {
		if (!shouldDisableApiCall && unitNameArray?.length) {
			getBlockReleases();
		}
	}, [getBlockReleases, shouldDisableApiCall, unitNameArray]);

	return {
		blockReleaseList,
		setBlockReleaseList,
		getBlockReleases
	};
};
