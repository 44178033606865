import React from 'react';
import { StatefulSelect } from '@components/StatefulInputs/StatefulSelect/StatefulSelect';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { useGetSurgeonOptions } from '@utilities/hooks/useGetSurgeonOptions/useGetSurgeonOptions';
import { StatefulSelectProps } from '@components/StatefulInputs/StatefulSelect/StatefulSelect.types';
import { PRIMARY_SURGEON_NOT_SELECTED } from '@utilities/Validation/ValidationMessages';

interface SurgeonDropdownProps extends Partial<Omit<StatefulSelectProps<CommonRequestForm>, 'options'>> {
	label?: string;
	dataField: string;
	placeholder?: string;
}

export function SurgeonDropdown({ label = 'Surgeon', dataField, placeholder }: SurgeonDropdownProps) {
	const options = useGetSurgeonOptions();
	if (options.length === 0) return null;

	const shouldUseControllerOptions = !placeholder;

	const controllerOptions = {
		defaultValue: shouldUseControllerOptions ? options[0].value : undefined,
		rules: {
			required: PRIMARY_SURGEON_NOT_SELECTED
		},
	};

	return (
		<StatefulSelect<CommonRequestForm>
			dataField={dataField}
			options={options}
			name="primarySurgeon"
			label={label}
			styleAsRequired={true}
			placeholder={placeholder}
			controllerOptions={controllerOptions}
		/>
	);
}
