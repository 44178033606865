import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Surgeon } from '@data/surgeon/Surgeon';
import { ENDPOINT_SURGEONS } from '@utilities/apiConstants';

const useGetPrimarySurgeonNpi = () => {
	const { watch } = useFormContext<RequestFormValidatedState>();
	const [formSurgeonId, formSurgeryProcedures ] = watch(['primarySurgeon', 'surgeryProcedures']);
	const surgeons = useCollection<Surgeon>(ENDPOINT_SURGEONS);

	return React.useMemo(() => {
		let primarySurgeonId: string | undefined;

		if (formSurgeonId) {
			primarySurgeonId = formSurgeonId;
		}

		if (formSurgeryProcedures && formSurgeryProcedures.length) {
			primarySurgeonId = formSurgeryProcedures.find(p => p.isPrimaryProcedure)?.surgeon.value;
		}

		if (!primarySurgeonId) { return; }

		return String(surgeons.find(s => s.id === primarySurgeonId)?.npi);
	}, [formSurgeonId, formSurgeryProcedures, surgeons]);
};

export default useGetPrimarySurgeonNpi;
