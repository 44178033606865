import { TimeOptionsConfig } from '@components/StatefulInputs/StatefulTimeSelect/StatefulTimeSelect.types';
import { eachMinuteOfInterval, format } from 'date-fns';

export const createTimeOptions = (optionsConfig: TimeOptionsConfig) => {
	const { start, end, increment } = optionsConfig;
	const startTime = new Date(`1970-01-01T${start}:00`);
	const endTime = new Date(`1970-01-01T${end}:00`);
	const dateList = eachMinuteOfInterval({ start: startTime, end: endTime }, { step: increment });
	return dateList.map((date) => ({
		value: format(date, 'HH:mm'),
		label: format(date, 'h:mm a'),
	}));
};
