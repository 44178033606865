import React, { ReactElement } from 'react';
import { StatefulSelect } from '@components/StatefulInputs/StatefulSelect/StatefulSelect';
import { FieldValues } from 'react-hook-form';
import {
	StatefulTimeSelectProps,
	TimeOptionsConfig
} from '@components/StatefulInputs/StatefulTimeSelect/StatefulTimeSelect.types';
import { formatInTimeZone } from 'date-fns-tz';
import { getDateFromApiDateTime } from '@calendar/utilities';
import { createTimeOptions } from '@components/StatefulInputs/StatefulTimeSelect/createTimeOptions/createTimeOptions';

export const formatDateTo24HrTime = (timeZone: string, dateTime?: string) => {
	if (!dateTime) { return; }

	const dateTimeAsDate = getDateFromApiDateTime(dateTime);

	return formatInTimeZone(dateTimeAsDate, timeZone, 'HH:mm');
};

const defaultOptionsConfig: TimeOptionsConfig = {
	start: '07:00',
	end: '19:00',
	increment: 15,
};

export function StatefulTimeSelect<FormType extends FieldValues>({
	label = 'Time',
	dataField,
	name,
	optionsConfig = defaultOptionsConfig,
	controllerOptions,
}: StatefulTimeSelectProps<FormType>): ReactElement {
	const options = React.useMemo(() => createTimeOptions(optionsConfig), [optionsConfig]);

	return (
		<StatefulSelect
			options={options}
			name={name}
			label={label}
			dataField={dataField}
			controllerOptions={{
				...controllerOptions
			}}
		/>
	);
};
