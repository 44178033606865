import React from 'react';
import { ReleaseCard } from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestPage/ReleasesSection/ReleaseCard/ReleaseCard';
import { BlockReleasesListResponse } from '@data/blockReleases/types/BlockReleasesListResponse';
import { Surgeon } from '@data/surgeon/Surgeon';
import { getFormattedName } from '@utilities/commonFunction';
import { getDateFromApiDateTime } from '@calendar';

interface PendingReleaseCardsProps {
	releases: BlockReleasesListResponse[];
	surgeonByNpi: {
		[key: string]: Surgeon;
	};
	timeZoneByHospital: {
		[key: string]: string;
	};
}

export const PendingReleaseCards: React.FC<PendingReleaseCardsProps> = ({
	releases,
	surgeonByNpi,
	timeZoneByHospital,
}) => {
	return (
		<>
			{releases.map((pendingRelease) => {
				const surgeon = surgeonByNpi[pendingRelease?.npis[0]];
				const surgeonName = getFormattedName(surgeon);

				const timeInterval = {
					start: getDateFromApiDateTime(pendingRelease.startDateTime),
					end: getDateFromApiDateTime(pendingRelease.endDateTime),
				};

				return (
					<ReleaseCard
						key={pendingRelease.id}
						surgeonName={surgeonName}
						unit={pendingRelease?.unit}
						date={pendingRelease?.startDateTime}
						timeFrame={timeInterval}
						timeZone={timeZoneByHospital[pendingRelease?.hospitalName]}
					/>
				);
			})}
		</>
	);
};
