import React from 'react';
import { Box, Flex } from '@ascension/web';
import HeaderSection from '../HeaderSection/HeaderSection';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppPageProps } from './AppPageProps';
import {
	getUserProfileAndDocPermission,
} from '@store/actions/UserProfileActionCreators';
import { WindowScrollTopOnPath } from './WindowScrollTopOnPath';
import Toast from '../Toast/Toast';
import {
	UserProfileHasARoleSelector,
	UserProfileDetailsSelector,
	UserProfileSelector
} from '@store/selectors/UserProfileSelector';
import { RootState } from '@interfaces/rootState';
import { LocationDescriptor } from 'history';
import { useAuthBaseContext } from '../AuthBase/AuthBase';
import { StorageKey } from '@utilities/authConstants';
import { UserStatus } from '@interfaces/UserProfile/UserStatusEnum';
import { AuthSelector } from '@store/selectors/AuthSelector';
import { useAppPageControlledProperties } from './AppPageContext/AppPageContext';
import { BackToHomeLink } from './BackToHomeLink/BackToHomeLink';
import classNames from 'classnames';
import PageHeader from '@components/PageHeader/PageHeader';
import { HomeNavigationContextProvider } from '@components/HeaderSection/NavigationOptions/HomeNavigationContext/HomeNavigationContext';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';
import { AuthenticatedBanner } from '@components/AuthenticatedBanner/AuthenticatedBanner';

function AppPageInner<StateType = void>({
	component: Component,
	hideDropDown,
	hideRequestButton,
	disableAuthentication,
	showHomeLink,
	onlyAllowRoles,
	forbiddenRedirectTo,
	hasAlert,
	subHeaderTitle,
	hasBanner = true,
	...rest
}: AppPageProps<StateType>) {
	const { location } = useHistory<Record<string, boolean>>();
	const dispatch = useDispatch();
	const { accessToken } = useSelector(AuthSelector);
	const hasAccessToken = !!accessToken;
	const { navToRef } = useAuthBaseContext();
	const { bodyClass, shouldApplyToHeader } = useAppPageControlledProperties();

	const allowedRole = useSelector<RootState, boolean | undefined>(UserProfileHasARoleSelector(onlyAllowRoles));
	const { termsOfUseAccepted, userId, status } = useSelector(UserProfileDetailsSelector);
	const { error } = useSelector(UserProfileSelector);

	React.useEffect(() => {
		if (window.config.DISABLE_AUTH || hasAccessToken) {
			dispatch(getUserProfileAndDocPermission());
		}
	}, [dispatch, hasAccessToken]);

	const updateNavToRef = React.useCallback((storeInSession = false) => {
		const { pathname, search, hash } = location;
		navToRef.current = { pathname, search, hash };
		if (storeInSession) { window.sessionStorage.setItem(StorageKey.NAV_TO, btoa(JSON.stringify(navToRef.current))); }
	}, [location, navToRef]);

	React.useEffect(() => {
		if (!hasAccessToken) { return; }
		updateNavToRef(true);
	}, [hasAccessToken, updateNavToRef]);

	const customNavToHomeText = typeof showHomeLink === 'string' ? showHomeLink : undefined;
	const isForbidden: boolean = !!onlyAllowRoles && allowedRole === false;

	const termsOfUseAcceptedLocal = !location.state?.fromTermsOfUsePage;

	let redirectTo: LocationDescriptor | null = null;
	if (!window.config.DISABLE_AUTH && !disableAuthentication && !hasAccessToken) {
		updateNavToRef();

		redirectTo = {
			pathname: ROUTE_CONSTANTS.LOGIN,
			hash: location.hash,
		};
	} else if (userId && !termsOfUseAccepted && termsOfUseAcceptedLocal && location.pathname !== ROUTE_CONSTANTS.TERMS_OF_USE) {
		redirectTo = {
			pathname: ROUTE_CONSTANTS.TERMS_OF_USE,
			state: {
				original: {
					pathname: location.pathname,
					search: location.search,
					hash: location.hash,
				},
			},
		};
	} else if (userId && termsOfUseAccepted && status === UserStatus.PENDING_ACTIVE && location.pathname !== ROUTE_CONSTANTS.SETUP_PROFILE) {
		redirectTo = {
			pathname: ROUTE_CONSTANTS.SETUP_PROFILE
		};
	} else if (status === UserStatus.LOCK && location.pathname !== ROUTE_CONSTANTS.SETUP_PROFILE) {
		redirectTo = {
			pathname: ROUTE_CONSTANTS.SETUP_PROFILE
		};
	} else if (isForbidden) {
		redirectTo = forbiddenRedirectTo || ROUTE_CONSTANTS.HOME;
	}

	if (redirectTo) {
		return <Redirect to={redirectTo}/>;
	}

	if (!disableAuthentication && !window.config.DISABLE_AUTH && userId == null && error === null) {
		return null;
	}

	return (
		<Route {...rest} render={() => (
			<Box display="flex" flexDirection="column" minHeight="100vh">
				<Box
					width={1}
					className={classNames('brand-0', 'background', { [bodyClass]: shouldApplyToHeader })}
					position="sticky"
					top="0"
					zIndex="110"
				>
					<Flex justifyContent="center" flexDirection="column">
						<Box width={1}>
							<HeaderSection hideRequestButton={hideRequestButton} hideDropDown={hideDropDown} data-field="header"/>
						</Box>
						{subHeaderTitle && <PageHeader title={subHeaderTitle} />}
					</Flex>
				</Box>

				{hasAlert && (
					<Flex justifyContent="center">
						<Box position="fixed" top="80px" zIndex="120" className="alert-box-shadow">
							<Toast />
						</Box>
					</Flex>
				)}

				<Flex
					justifyContent="center"
					className={bodyClass}
					flex="1"
				>
					<Box
						ml="40px"
						mr="40px"
						width="100%"
						display="flex"
						flexDirection="column"
					>
						<WindowScrollTopOnPath/>
						{showHomeLink && <BackToHomeLink navToHomeText={customNavToHomeText}/>}
						<Box
							flex="1"
							pb="40px"
							data-field="component-container"
						>
							{hasBanner && (
								<AuthenticatedBanner />
							)}
							<Component/>
						</Box>
					</Box>
				</Flex>
			</Box>
		)}/>
	);
}

export function AppPage<StateType = void>(props: AppPageProps<StateType>) {
	return (
		<HomeNavigationContextProvider>
			<AppPageInner {...props} />
		</HomeNavigationContextProvider>
	);
}
