import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { track } from '@amplitude/analytics-browser';
import { BaseEventTrackingWrapper } from '@components/BaseEventTrackingWrapper/BaseEventTrackingWrapper';
import { TrackableEventType } from '@components/BaseEventTrackingWrapper/BaseTrackableEvents.types';
import TextLink from '@components/TextLink/TextLink';
import ColumnHeaderProps from '@components/SchedulePage/types/ColumnHeaderProps';
import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

const useStyles = makeStyles((theme) =>
	createStyles({
		releaseLink: {
			'borderBottom': `1px solid ${theme.palette.common.white}`,
			'color': theme.palette.common.white,
			'cursor': 'pointer',
			'fontSize': '0.875rem',
			'fontWeight': 500,
			'textTransform': 'initial',
			'& .MuiButton-label': {
				letterSpacing: '.01rem',
			},
			'&:hover, &:focus': {
				borderBottomColor: theme.palette.common.white,
				backgroundColor: 'transparent',
			},
		},
	}),
);

type BlockReleaseLinkProps = ColumnHeaderProps;

const releaseLinkEventsToTrack = [TrackableEventType.OnClick, TrackableEventType.OnKeyDown];

const dataField = 'scheduling-blockColumn-blockReleaseLink';

export const BlockReleaseLink: React.FC<BlockReleaseLinkProps> = ({ blockInfo }) => {
	const classes = useStyles();
	const history = useHistory();

	const handleTrackingEvent = React.useCallback((event: React.SyntheticEvent<HTMLElement>) => {
		const eventType = event.type;
		const eventName = `link-${eventType}`;

		track(eventName, { fieldName: dataField, eventType: eventType });
	}, []);

	const handleClick = React.useCallback(() => {
		const queryParams = new URLSearchParams({ surgeon: blockInfo.surgeon.npi.toString() }).toString();
		history.push(`${ROUTE_CONSTANTS.BLOCK_RELEASE_REQUEST}?${queryParams}`);
	}, [blockInfo.surgeon, history]);

	return (
		<BaseEventTrackingWrapper
			handleEvent={handleTrackingEvent}
			dataField={dataField}
			eventsToTrack={releaseLinkEventsToTrack}
		>
			<TextLink className={classes.releaseLink} onClick={handleClick}>
				Release unused block time
			</TextLink>
		</BaseEventTrackingWrapper>
	);
};
