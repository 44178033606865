import React, { useCallback } from 'react';
import { Box, Button, Label } from '@ascension/web';
import { TextArea } from '@ascension/web/components/base/TextArea';
import { ProcedureFromTemplateInfo, SurgeryProcedureInterface } from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { SurgeryInfoValidationSchema } from '@components/RequestForm/SurgeryInformation/SurgeryInfoValidationSchema';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { useSelector } from 'react-redux';
import RequestFormSelector from '@store/selectors/RequestFormSelector';
import RequestSurgeryProcedureForm from '@components/RequestForm/SurgeryInformation/RequestSurgeryProcedureForm/RequestSurgeryProcedureForm';
import ProcedureTile from '@components/RequestForm/SurgeryInformation/ProcedureTile/ProcedureTile';
import MyTemplatesModal from '@components/RequestForm/MyTemplatesModal/MyTemplatesModal';
import DiagnosisInput from '@components/RequestForm/inputs/DiagnosisInput';
import { RequestFormStatus } from '@interfaces/RequestForm/RequestFormStatus';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { MY_TEMPLATES_MODAL, SURGERY_PROCEDURE_MODAL } from '@components/Modal/modalConstants';
import ModalViewer from '@components/Modal/ModalViewer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	modalWrapper: {
		maxHeight: '90vh',
	}
});

const SurgeryInformation = () => {
	const classes = useStyles();

	const {
		currentValue: surgeryProceduresCurrentValue = [],
		setCurrentValue: surgeryProceduresSetCurrentValue,
		error: surgeryProceduresError,
		triggerSelfValidation: surgeryProceduresSelfValidation
	} = useFieldFormContext<SurgeryProcedureInterface[]>('surgeryProcedures', {
		validationRules: SurgeryInfoValidationSchema.surgicalProcedures,
		triggerValidationOnChange: true
	});

	const {
		currentValue: otherSurgicalCommentsCurrentValue,
		setCurrentValue: otherSurgicalCommentsSetCurrentValue,
	} = useFieldFormContext<string>('otherSurgicalComments');

	const { closeModal, openModal } = useModalContext();
	const [ surgProcValuesFromTemplate, setSurgProcValuesFromTemplate ]  = React.useState<Partial<ProcedureFromTemplateInfo> | undefined>(undefined);

	const handleOpenProcedureModal = () => {
		openModal(SURGERY_PROCEDURE_MODAL);
	};
	const handleSurgeryProcedureModalClose = () => {
		setSurgProcValuesFromTemplate(undefined);
		closeModal(SURGERY_PROCEDURE_MODAL);
	};

	const handleCloseMyTemplatesModal = () => {
		closeModal(MY_TEMPLATES_MODAL);
	};

	const handleOpenMyTemplatesModal = () => {
		openModal(MY_TEMPLATES_MODAL);
	};

	const { formStatus } = useSelector(RequestFormSelector);

	const handleResetProcedures = useCallback(() => {
		surgeryProceduresSetCurrentValue([]);
	}, [surgeryProceduresSetCurrentValue]);

	const myTemplatesModalWrapper = (
		<ModalViewer
			className={classes.modalWrapper}
			data-field="templateModal"
			id={MY_TEMPLATES_MODAL}
		>
			<Box minHeight={300} width={800}>
				<MyTemplatesModal
					closeSelf = {handleCloseMyTemplatesModal}
					openSurgeryProcedureModal={handleOpenProcedureModal}
					setSurgeryProcedureFromTemplate={setSurgProcValuesFromTemplate}
				/>
			</Box>
		</ModalViewer>
	);

	const surgeryProcedureModalWrapper = (
		<ModalViewer
			className={classes.modalWrapper}
			data-field="Surgery_Procedure_Modal_Wrapper_Outer"
			id={SURGERY_PROCEDURE_MODAL}
			onClose={handleSurgeryProcedureModalClose}
		>
			<Box width={800}>
				<RequestSurgeryProcedureForm
					close={handleSurgeryProcedureModalClose}
					setSurgeryProcedure={surgeryProceduresSetCurrentValue}
					surgeryProcedure={surgProcValuesFromTemplate}
					validate={surgeryProceduresSelfValidation}
					data-field = "procedureModal"
					isProcedureDataFromTemplate={!!surgProcValuesFromTemplate}
				/>
			</Box>
		</ModalViewer>
	);

	React.useEffect(() => {
		if (formStatus === RequestFormStatus.SUBMITTED) {
			handleResetProcedures();
		}
	}, [formStatus, handleResetProcedures]);

	return (
		<>
			{surgeryProcedureModalWrapper}
			<DiagnosisInput type="primary" />
			<DiagnosisInput type="secondary" />
			<Box mt={13}>
				<Label required display="block">SURGICAL PROCEDURES</Label>
			</Box>
			{surgeryProceduresCurrentValue.map((item: SurgeryProcedureInterface, index: number) => (
				<Box data-field={'surgeryInformationCard' + index} key={index}>
					<ProcedureTile
						surgeryProcedureInfo={item}
						index={index}
						setSurgeryProcedureInfo={surgeryProceduresSetCurrentValue}
					/>
				</Box>
			))}
			<Box>
				<Button
					onClick={handleOpenMyTemplatesModal}
					id="addTemplate"
					className="margin-right-16"
				>
					Add From Template
				</Button>
				{myTemplatesModalWrapper}
				<Button
					secondary
					onClick={handleOpenProcedureModal}
					id="addManually"
				>
					Add Manually
				</Button>
				<ErrorMessage errorField={{ message: surgeryProceduresError }} />
			</Box>
			<Box mt={13}>
				<TextArea
					label="OTHER SURGICAL COMMENTS"
					value={otherSurgicalCommentsCurrentValue}
					id="otherSurgicalComments"
					name="otherSurgicalComments"
					onChange={otherSurgicalCommentsSetCurrentValue}
				/>
			</Box>
		</>
	);
};

export default SurgeryInformation;
