import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import {
	useCaseDetailsPageContext
} from '@components/CaseDetails/CaseDetailsContext/CaseDetailsPageContext/CaseDetailsPageContext';
import {
	CommentTypeFilter
} from '@components/CaseDetails/CommentsSection/CommentsSectionHeader/CommentTypeFilter/CommentTypeFilter';
import {
	ShowHistoryCheckbox
} from '@components/CaseDetails/CommentsSection/CommentsSectionHeader/ShowHistoryCheckbox/ShowHistoryCheckbox';
import {
	AddCommentButton
} from '@components/CaseDetails/CommentsSection/CommentsSectionHeader/AddCommentButton/AddCommentButton';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing(0, 4, 2, 4),
	},
	title: {
		color: theme.palette.secondary.dark,
	}
}));

interface CommentsHeaderProps {
	hideTitle?: boolean;
}

export const CommentsSectionHeader: React.FC<CommentsHeaderProps> = ({ hideTitle = false }) => {
	const classes = useStyles();
	const { showCondensedView } = useCaseDetailsPageContext();

	return (
		<Box className={classes.root}>
			{!hideTitle && !showCondensedView && <Typography variant="h5" className={classes.title}>Comments</Typography>}
			<CommentTypeFilter />
			<ShowHistoryCheckbox />
			<AddCommentButton />
		</Box>
	);
};
