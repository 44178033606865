import React, { useContext } from 'react';
import { BlockReleaseReviewContextType } from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewContext/BlockReleaseReviewContextType';
import { useGetBlockReleasesByUnit } from '@data/blockReleases/hooks/useGetBlockReleasesByUnit';

const BlockReleaseReviewContext = React.createContext<null | BlockReleaseReviewContextType>(null);

export const useBlockReleaseContext = () => {
	const ctx = useContext(BlockReleaseReviewContext);
	if (!ctx) {
		throw new Error(
			'useBlockReleaseContext must be used within a child component of BlockReleaseReviewProvider',
		);
	}
	return ctx;
};

export const BlockReleaseReviewProvider: React.FC = ({ children }) => {
	const { blockReleaseList, setBlockReleaseList, getBlockReleases } = useGetBlockReleasesByUnit();

	return (
		<BlockReleaseReviewContext.Provider
			value={{
				blockReleaseList,
				setBlockReleaseList,
				getBlockReleases
			}}
		>
			{children}
		</BlockReleaseReviewContext.Provider>
	);
};
