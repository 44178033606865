import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { StandardInputWrapper } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, FormControlLabel, FormGroup } from '@material-ui/core';
import { useController, useFormContext } from 'react-hook-form';
import { mapCaseCommentTypeEnumToDisplay } from '@components/CaseDetails/CommentsSection/utilities/mapCaseCommentTypeEnumToDisplay';
import { CommentTypes } from '@data/caseComments/types/CaseComments.types';
import { CaseCommentForm } from '@components/CaseDetails/CommentsSection/CommentModal/CaseCommentForm';
import { CheckBox } from '@components/CheckBox/CheckBox';
import { CaseCommentSchema } from '@utilities/Validation/validationSchema';

const useStyles = makeStyles((theme) =>
	createStyles({
		formLabel: {
			'marginBottom': theme.spacing(1),
			'fontWeight': 600,
			'&.Mui-focused': {
				color: 'rgba(0, 0, 0, 0.54)',
			},
		},
		formControlLabelWrapper: {
			flex: 'auto',
		},
		formControlLabel: {
			marginRight: 'unset',
			marginLeft: 'unset',
		},
		checkbox: {
			'&.Mui-checked + .MuiFormControlLabel-label': {
				color: theme.palette.primary.main,
				fontWeight: 600,
			},
		},
		checkboxError: {
			'&.MuiCheckbox-root': {
				color: theme.palette.error.main,
			},
		},
		wrapperClassName: {
			'& .MuiFormHelperText-root.Mui-error': {
				position: 'absolute',
				top: '25px',
				margin: 0,
			},
		},
		checkboxGroup: {
			marginTop: theme.spacing(2.5),
		},
	}),
);

export function CommentTypeSection() {
	const classes = useStyles();

	const { field } = useController<CaseCommentForm>({ name: 'commentTypes', rules: CaseCommentSchema.commentTypes });

	const { watch, formState: { errors } } = useFormContext<CaseCommentForm>();
	const selectedCommentTypes = watch('commentTypes');
	const hasError = !!errors.commentTypes?.message;

	const handleCheckboxClick = React.useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const value = e.target.value;
		const selectedTypes = checked
			? [...selectedCommentTypes, value]
			: selectedCommentTypes.filter((type) => type.toString() !== value);
		field.onChange(selectedTypes);
	}, [selectedCommentTypes, field]);

	return (
		<StandardInputWrapper
			name="commentTypes"
			label="Please choose the Comment Type"
			styleAsRequired
			labelProps={{ className: classes.formLabel, htmlFor: 'commentTypes' }}
			dataField="caseDetailsPage-commentModal-commentTypeCheckboxes"
			shouldTrackEvents={false}
			wrapperClassName={classes.wrapperClassName}
		>
			<FormGroup className={classNames({ [classes.checkboxGroup]: hasError })}>
				{CommentTypes.map((type) => (
					<Box key={type} className={classes.formControlLabelWrapper}>
						<FormControlLabel
							label={mapCaseCommentTypeEnumToDisplay[type]}
							className={classes.formControlLabel}
							control={
								<CheckBox
									dataField={`caseCommentModal-commentType-${type.toLowerCase()}`}
									color="primary"
									value={type}
									className={classNames({
										[classes.checkboxError]: hasError,
										[classes.checkbox]: !hasError,
									})}
									checked={selectedCommentTypes.includes(type)}
									onChange={handleCheckboxClick}
								/>
							}
						/>
					</Box>
				))}
			</FormGroup>
		</StandardInputWrapper>
	);
}
