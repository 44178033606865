import React from 'react';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';
import {
	useBlockReleaseRequestContext
} from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestContext/BlockReleaseRequestContext';
import {
	ReleaseCard
} from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestPage/ReleasesSection/ReleaseCard/ReleaseCard';
import {
	PendingReleases
} from '@components/BlockRelease/BlockReleaseRequest/BlockReleaseRequestPage/ReleasesSection/PendingReleases/PendingReleases';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			gridArea: 'releases',
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
			overflowY: 'auto',
			paddingRight: theme.spacing(2)
		},
	}),
);

export const ReleasesSection = () => {
	const classes = useStyles();
	const { releaseRequestInfo } = useBlockReleaseRequestContext();

	const blockInfo = releaseRequestInfo?.blockInfo;
	const facility = releaseRequestInfo?.facility;

	return (
		<BoxFancyScroll className={classes.root}>
			{blockInfo && facility && (
				<ReleaseCard
					surgeonName={blockInfo?.displayName}
					unit={blockInfo?.blocks?.[0].unit}
					date={blockInfo?.blocks?.[0].start}
					timeFrame={blockInfo?.timeframes[0]}
					timeZone={facility?.hospitalTimeZone}
					selected={true}
				/>
			)}
			<PendingReleases />
		</BoxFancyScroll>
	);
};
