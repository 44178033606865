import React from 'react';
import { Divider, makeStyles, Box, Typography } from '@material-ui/core';
import {
	BlockReleaseReviewCardProps,
	Surgeon,
} from '@components/BlockRelease/BlockReleaseReview/BlockReleaseReviewPage/BlockReleaseReviewCard/BlockReleaseReviewCardProps';
import { BlockReleaseState } from '@data/blockReleases/types/BlockReleaseState';
import { BaseButton } from '@components/BaseButton/BaseButton';
import { getFormattedName } from '@utilities/commonFunction';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import DisplayTimeFrame from '@components/DisplayTimeFrame/DisplayTimeFrame';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { formatInTimeZone } from 'date-fns-tz';
import { getDateFromApiDateTime } from '@calendar';
import { HalfMoonIcon } from '@components/SVGs/HalfMoonIcon/HalfMoonIcon';
import checkPartialRelease from '@utilities/checkPartialRelease';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(2),
		padding: theme.spacing(3),
		marginTop: theme.spacing(2.25),
		marginBottom: theme.spacing(2.25),
		borderRadius: '4px',
		boxShadow: theme.shadows[5],
		height: '100%',
	},
	header: {
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	body: {
		marginTop: theme.spacing(1.5),
	},
	footer: {
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	divider: {
		backgroundColor: theme.palette.primary.main,
	},
	flexBoxGap: {
		gap: theme.spacing(3),
		flexDirection: 'column',
		flexGrow: 1,
	},
	cardHeaderText: {
		fontSize: 22,
		fontWeight: 600,
		marginRight: 24,
	},
	requestedByText: {
		fontSize: 14,
		fontWeight: 500,
		color: 'rgba(0, 0, 0, 0.6)',
		letterSpacing: '0.5px',
		maxWidth: 465,
		whiteSpace: 'normal',
	},
	genericTextLabel: {
		fontSize: 16,
		fontWeight: 500,
		color: 'rgba(0, 0, 0, 0.6)',
		letterSpacing: '0.5px',
	},
	genericTextValue: {
		fontSize: 16,
		fontWeight: 600,
		marginTop: '5px',
	},
	releaseStateText: {
		fontSize: 18,
		fontWeight: 600,
	},
	releasedByText: {
		marginLeft: 24,
		marginTop: 3,
		fontSize: 14,
		fontWeight: 500,
		color: 'rgba(0, 0, 0, 0.6)',
		letterSpacing: '0.5px',
	},
	checkCircleIcon: {
		color: '#0C5C34',
	},
	iconText: {
		fontSize: 16,
		fontWeight: 600,
		marginLeft: 3,
	},
	releasedInEhr: {
		marginLeft: 8,
		color: '#0C5C34',
	},
	releaseStateTextColor: {
		color: theme.palette.primary.main,
	},
}));

export const BlockReleaseReviewCard: React.FC<BlockReleaseReviewCardProps> = ({
	blockReleaseDetails,
	prefSurgeons,
	onActionClick,
}) => {
	const {
		id,
		requestedBy,
		hospitalName,
		unit: unitName,
		room,
		state,
		startDateTime,
		endDateTime,
		requestedTs,
		releasedBy,
		releasedTs,
		npis,
		blockStartTime,
		blockEndTime,
	} = blockReleaseDetails;
	const classes = useStyles();
	const { hospitalTimeZone = '' } = useCollectionEntity<Unit>(
		ENDPOINT_USER_UNITS,
		unitName,
		'name',
	);
	const date =
		endDateTime &&
		formatInTimeZone(
			getDateFromApiDateTime(endDateTime),
			hospitalTimeZone,
			'eeee MMMM d, yyyy',
		);
	const formatRequestTs =
		requestedTs && format(getDateFromApiDateTime(requestedTs), 'MM/dd/yyyy h:mm aa');
	const formatReleasedTs =
		releasedTs && format(getDateFromApiDateTime(releasedTs), 'MM/dd/yyyy h:mm aa');
	const start = getDateFromApiDateTime(startDateTime);
	const end = getDateFromApiDateTime(endDateTime);
	const surgeonName = React.useMemo(
		() =>
			prefSurgeons.length > 0
				? prefSurgeons
					.filter((surgeon: Surgeon) => npis?.some((npi) => npi == surgeon?.npi))
					.map((surgeonAfterFilter: Surgeon) => getFormattedName(surgeonAfterFilter))
					.join(', ')
				: '',
		[prefSurgeons, npis],
	);

	const isPartialRelease = checkPartialRelease(
		startDateTime,
		endDateTime,
		hospitalTimeZone,
		blockStartTime,
		blockEndTime,
	);

	return (
		<Box className={classes.root} id={id}>
			{/*header section*/}
			<Box display="flex" className={classes.header}>
				<Box display="flex" alignItems="end">
					<Typography className={classes.cardHeaderText}>{surgeonName}</Typography>
					<Typography className={classes.requestedByText}>
						Request by {requestedBy} on {formatRequestTs}
					</Typography>
				</Box>
				{isPartialRelease ? (
					<Box display="flex" alignItems="center">
						<HalfMoonIcon />
						<Typography className={classes.iconText}>Partial Block Release</Typography>
					</Box>
				) : (
					<Box display="flex">
						<FiberManualRecordIcon fontSize="medium" />
						<Typography className={classes.iconText}>Full Block Release</Typography>
					</Box>
				)}
			</Box>
			{/*body section*/}
			<Box display="flex" className={classes.body}>
				<Box display="flex" flexGrow={3} alignItems="start">
					<Box display="flex" className={classes.flexBoxGap}>
						<Box display="flex" flexDirection="column">
							<Typography className={classes.genericTextLabel}>Location</Typography>
							<Typography className={classes.genericTextValue}>
								{hospitalName}
							</Typography>
						</Box>
						<Box display="flex" flexDirection="column">
							<Typography className={classes.genericTextLabel}>Date</Typography>
							<Typography className={classes.genericTextValue}>{date}</Typography>
						</Box>
					</Box>

					<Box display="flex" className={classes.flexBoxGap}>
						<Box display="flex" flexDirection="column">
							<Typography className={classes.genericTextLabel}>Unit</Typography>
							<Typography className={classes.genericTextValue}>{unitName}</Typography>
						</Box>
						<Box display="flex" flexDirection="column">
							<Typography className={classes.genericTextLabel}>
								Block time to release
							</Typography>
							<DisplayTimeFrame
								className={classes.genericTextValue}
								timeFrame={{ start, end }}
								timeZone={hospitalTimeZone}
							/>
						</Box>
					</Box>
					<Box display="flex" flexDirection="column" flexGrow={1}>
						<Typography className={classes.genericTextLabel}>Room</Typography>
						<Typography className={classes.genericTextValue}>{room}</Typography>
					</Box>
				</Box>
			</Box>
			<Divider className={classes.divider} />
			{/*footer section*/}
			<Box display="flex" className={classes.footer}>
				{state === BlockReleaseState.SSM_RELEASE ? (
					<Box display="flex">
						<CheckCircleIcon className={classes.checkCircleIcon} />
						<Typography
							className={classNames(classes.releaseStateText, classes.releasedInEhr)}
						>
							Released in the EHR
						</Typography>
						<Typography className={classes.releasedByText}>
							Released by {releasedBy} on {formatReleasedTs}
						</Typography>
					</Box>
				) : (
					<Typography
						variant="body1"
						className={classNames(
							classes.releaseStateText,
							classes.releaseStateTextColor,
						)}
					>
						Let us know you have released block in the EHR.
					</Typography>
				)}
				{state === BlockReleaseState.PENDING_RELEASE && (
					<BaseButton
						dataField="orReleasePage-blockReleaseReviewCard-releaseButton"
						onClick={() =>
							onActionClick && onActionClick(blockReleaseDetails, surgeonName)
						}
						color="primary"
						variant="contained"
					>
						BLOCK TIME HAS BEEN RELEASED IN THE EHR
					</BaseButton>
				)}
			</Box>
		</Box>
	);
};
