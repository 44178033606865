import React, { MouseEventHandler } from 'react';
import { ProcedureNameValidation } from '@components/RequestForm/RequestFormValidation';
import { ArrowDropDown } from '@material-ui/icons';
import { ProcedureNameInput } from '@components/RequestForm/inputs/ProcedureName/ProcedureNameInput/ProcedureNameInput';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
	ProcedureNameMainInputProps
} from '@components/RequestForm/inputs/ProcedureName/ProcedureNameMainInput/ProcedureNameMainInputProps';
import { useFormContext } from 'react-hook-form';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';

const useStyles = makeStyles(() =>
	createStyles({
		procedureSelect: {
			'& .MuiOutlinedInput-input': {
				'textAlign': 'left',
				'&': {
					'cursor': 'pointer',
					'&.Mui-disabled': {
						cursor: 'default',
					},
				},
				'overflow': 'hidden',
				'textOverflow': 'ellipsis',
				'whiteSpace': 'nowrap',
			},
		},
		endAdornment: {
			cursor: 'pointer',
		},
	})
);

export const ProcedureNameMainInput: React.FC<ProcedureNameMainInputProps> = ({ label, dataField }) => {
	const classes = useStyles();

	const { handleSetAnchorEl, anchorEl } = useAnchorContext();
	const {
		selectedProcedure,
		triggerProcedureNameValidation,
		procedures,
	} = useProcedureNameContext();

	const { watch } = useFormContext<FindATimeForm>();
	const [appointmentLocation] = watch(['appointmentLocation']);

	const shouldValidateProcedureSelect = anchorEl === null;

	const handleOnBlur = React.useCallback(() => {
		if (shouldValidateProcedureSelect) {
			triggerProcedureNameValidation();
		}
	}, [shouldValidateProcedureSelect, triggerProcedureNameValidation]);

	// Disable the input if there are no procedures - typically because a location is not selected (FaT Page) or location not configured properly (Request Form in lower environments)
	const disabled = !appointmentLocation || procedures.length === 0;

	const handleClick: MouseEventHandler<HTMLElement> = React.useCallback((e) => {
		// Necessary to prevent opening the popover when the input is disabled
		if (disabled) { return; }
		handleSetAnchorEl(e);
	}, [disabled, handleSetAnchorEl]);

	return (
		<ProcedureNameInput
			label={label}
			controllerOptions={{
				rules: ProcedureNameValidation,
			}}
			name="procedureName"
			shouldShowError={!disabled}
			inputProps={{
				type: 'button',
				value: selectedProcedure?.procedureName,
				onClick: handleClick,
				onBlur: handleOnBlur,
				className: classes.procedureSelect,
				endAdornment: <ArrowDropDown className={classes.endAdornment} fontSize="small" />,
				disabled: disabled,
			}}
			dataField={dataField}
		/>
	);
};
